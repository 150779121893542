import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import {
  api,
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
} from "../../api";

export const useUsers = (userId: string, token?: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.getUser(userId)),
    queryKey: [userId],
  });
};
export const useGetUser = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.getUser(userId)),
    queryKey: ["id"],
  });
};
export const useBusinessUserDetails = (businessId?: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.usersBusinessDetails(businessId)),
    queryKey: [businessId],
  });
};

export const useBusinessKycStatus = (businessId?: string) => {
  return useQueryMutation({
    mutationFn: (data) =>
      makePatchRequest(data, api.admin.businessKycStatus(businessId)),
  });
};

export const useInvitees = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.invitees(userId)),
    queryKey: ["invitee"],
  });
};
export const useRejectionReasons = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.rejectionReasons),
    queryKey: ["rejection"],
  });
};

export const useRejection = () => {
  return useQueryMutation({
    mutationFn: (data: any) => makePostRequest(data, api.users.rejection),
  });
};

export const useRequestOtp = (userId: string) => {
  return useQueryMutation({
    mutationFn: () => makeGetRequest(api.users.otpRequest(userId)),
  });
};

export const useVerifyUserMutation = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string; password: string }) =>
      makePostRequest(data, api.users.verify),
  });
};

export const useSignUpVerifyMutation = () => {
  return useQueryMutation({
    mutationFn: (data: { verificationCode: string }) =>
      makePostRequest(data, api.users.signUpVerify),
  });
};

export const useUpdateBank = () => {
  return useQueryMutation({
    mutationFn: (data: { accountNumber: string; bankName: string }) =>
      makePatchRequest(data, api.users.updateBank),
  });
};

export const useRequestAccess = () => {
  return useQueryMutation({
    mutationFn: (data: {
      email: string;
      firstName: string;
      lastName: string;
      referralCode: string;
    }) => makePostRequest(data, api.users.requestAccess),
  });
};

export const useInvitationVerify = () => {
  return useQueryMutation({
    mutationFn: (data: { token: string; code: string }) =>
      makePostRequest(data, api.users.invitationVerify),
  });
};

export const useIndividualKyc = () => {
  return useQueryMutation({
    mutationFn: (data: {
      bvn: string;
      dob: string;
      firstname: string;
      lastname: string;
    }) => makePostRequest(data, api.users.individualKyc),
  });
};

export const useUser = () => {
  return useQueryMutation({
    mutationFn: (data: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      phone: string;
      refereeCode: string;
      nuban: string;
      bank: string;
      accountType: string;
    }) => makePostRequest(data, api.users.users),
  });
};

export const useBusinessKyc = () => {
  return useQueryMutation({
    mutationFn: (data: { cacRegNo: string; businessType: string }) =>
      makePostRequest(data, api.users.businessKyc),
  });
};

export const useUserLogin = () => {
  return useQueryMutation({
    mutationFn: (data: { verificationCode: string; email: string }) =>
      makePostRequest(data, api.users.userLogin),
  });
};

export const useCheckEmail = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.checkEmail),
  });
};

export const useRecoverPassword = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.recoverPassword),
  });
};

export const useResetPassword = () => {
  return useQueryMutation({
    mutationFn: (data: { newPassword: string; verificationCode: string }) =>
      makePostRequest(data, api.users.resetPassword),
  });
};
export const useUsersInvitation = () => {
  return useQueryMutation({
    mutationFn: (data: {
      email: string;
      lastName: string;
      firstName: string;
    }) => makePostRequest(data, api.users.usersInvitations),
  });
};

export const useGenerateBeneficiaryForm = () => {
  return useQueryMutation({
    mutationFn: () =>
      makePostRequest(undefined, api.users.generateBeneficiaryForm),
  });
};
