import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useAccActivity } from "../../sevices/hooks/wallets";

import Pagination from "./Pagination";
import GlobalFilter from "./GlobalFilter";
// import { useTabs } from "../widgets/TabWidget";
// import { TabSelector } from "../widgets/TabSelector";
// import { Transaction, dColumns } from "./column";
import { divide } from "lodash";
import { Column } from "react-table";
// import { Tooltip } from "../base-components/Tooltip";
import { AiOutlineMinus } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiSend,
} from "react-icons/fi";
import { useTabs } from "./widgets/TabWidget";
import { TabSelector } from "./widgets/TabSelector";
import { Iaccounts } from "./settings-component/SettingsAccount";
import { IUserData } from "../../types/Users";
const moment = require("moment");

export type MyColumn = Column<IUserData>;

const ApprovalTable = ({
  tableData,
  tabs,
  dColumns,
  showFilter,
  showTab,
  placeholder,
}: {
  tableData: IUserData[];
  tabs: string[];
  dColumns: MyColumn[];
  showFilter?: boolean;
  showTab?: boolean;
  placeholder?: string;
}) => {
  const [selectedTab, setSelectedTab] = useTabs(tabs);
  const [chosenTab, setChosenTab] = useState("All");
  const [Tdata, setTdata] = useState(tableData);
  const columns = useMemo(() => dColumns, [])
  const data = useMemo(() => Tdata, [Tdata]);

  console.log(tableData, "tableData");

  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    prepareRow,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    previousPage,
    pageOptions,
    canNextPage,
    canPreviousPage,
    footerGroups,
  } = table;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    if (chosenTab === "All") {
      setTdata(tableData);
    } else {
      setTdata(
        tableData.filter(
          (d: { status: string }) =>
            d?.status?.toLowerCase() === chosenTab.toLowerCase()
        )
      );
    }
  }, [chosenTab]);

  return (
    <div>
      {showTab && (
        <div className=" flex gap-1 pt-5 pb-1 border-b ">
          {tabs.map((tab, index) => (
            <TabSelector
              key={index}
              className={`cursor-pointer relative bg-transparent w-fit text-center p-2  text-[16px] font-medium leading-[24px] ${
                selectedTab === tab
                  ? "before:block before:w-full  before:bg-[#0eb683] before:absolute before:bottom-[-6px] before:left-0 before:h-[1.5px] before:transition-all font-semibold text-[#0eb683]"
                  : "text-[#5C6F7F]"
              }`}
              isActive={selectedTab === tab}
              onClick={() => {
                setSelectedTab(tab);

                setChosenTab(tab);
              }}
            >
              {tab}
            </TabSelector>
          ))}
        </div>
      )}
      <div className="  flex flex-col bg-white mb-8 overflow-y-auto">
        <div className="overflow-x-auto ">
          <div className="inline-block md:min-w-full xxs:w-full  ">
            {showFilter && (
              <GlobalFilter
                setFilter={setGlobalFilter}
                filter={globalFilter}
                placeholder={placeholder}
              />
            )}

            <div className="overflow-x-auto xxs:hidden md:block">
              <table
                {...getTableProps()}
                className="appearance-none bg-white min-w-full  mb-6 "
                id="my-table"
              >
                <thead className="bg-[#fff] appearance-none">
                  {headerGroups.map(
                    (headerGroup: {
                      getHeaderGroupProps: () => {
                        [x: string]: any;
                        key: any;
                      };
                      headers: any[];
                    }) => {
                      const { key, ...restHeaderProps } =
                        headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderProps}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="font-normal text-sm text-primary py-4 text-left whitespace-nowrap px-4 border-r border-slate-50"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={column.id}
                            >
                              <div className="flex items-center">
                                <span className="text-[#5C6F7F] font-bold text-[12px] leading-[18px]">
                                  {column.render("Header")}
                                </span>

                                {column.canSort === true && (
                                  <span className="ml-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.00016 2.83L8.17016 6L9.58016 4.59L5.00016 0L0.410156 4.59L1.83016 6L5.00016 2.83ZM5.00016 15.17L1.83016 12L0.420156 13.41L5.00016 18L9.59016 13.41L8.17016 12L5.00016 15.17Z"
                                        fill="#323232"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      );
                    }
                  )}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="mt-3 pt-3 w-full space-y-8 border-r border-slate-50"
                >
                  {page.map(
                    (row: {
                      getRowProps: () => JSX.IntrinsicAttributes &
                        React.ClassAttributes<HTMLTableRowElement> &
                        React.HTMLAttributes<HTMLTableRowElement>;
                      cells: any[];
                    }) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="appearance-none my-4 border border-slate-50 even:bg-white odd:bg-slate-100"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="font-normal text-sm text-[#202223] py-4 px-4 border-r border-slate-50 text-left"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>

              <Pagination
                gotoPage={gotoPage}
                length={data.length}
                pageSize={pageSize}
                pageCount={pageCount}
                setPageSize={setPageSize}
              />
            </div>
            <div className="xxs:block md:hidden w-full">
              {/* <Container data={Tdata} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalTable;
