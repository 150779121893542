import create from "zustand";
interface IFees {
  fees: any[];
  selectedFeesId: string;
  feeId: string;

  setFees: (data: any) => void;
  setSelectedFeesId: (id: string) => void;
  setFeeId: (id: string) => void;
}

export const useFeesState = create<IFees>((set) => ({
  fees: [],
  selectedFeesId: "",
  feeId: "",

  setFees: (data: any) =>
    set((state: any) => ({
      fees: data,
    })),
  setSelectedFeesId: (id: string) =>
    set((state: any) => ({
      selectedFeesId: id,
    })),
  setFeeId: (id: string) =>
    set((state: any) => ({
      feeId: id,
    })),
}));
