import React, { useState, useEffect, useMemo } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import pdf from "../../../assets/pdficon.png";
// import AppCheckBox from "../../components/widgets/AppCheckBox";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheck2, BsX } from "react-icons/bs";
import {
  useBusinessKycStatus,
  useBusinessUserDetails,
  useGetUser,
  useRejection,
  useUsers,
} from "../../../sevices/hooks/users";
import { FiChevronLeft } from "react-icons/fi";
import ToggleSwitch from "../../../components/dashboard-components/ToggleSwitch";
import {
  useAdminUser,
  useAdminUsersId,
  useBusinessContactKycStatus,
  useBusinessCorporateKycStatus,
  useUpdateBRStatus,
} from "../../../sevices/hooks/admin";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import TextArea from "../../../components/base-components/TextArea";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IBusinessContact } from "../../../types/Users";
import { AiOutlineClose } from "react-icons/ai";
import ImageModal from "../../../components/ImageModal";
import { IoMdDownload } from "react-icons/io";

interface RejectionReasonOption {
  id: number;
  value: string;
  slug: string;
  label: string;
}


const rejectionReasons: RejectionReasonOption[] = [
  {
    id: 1,
    value: "incomplete_details",
    slug: "incomplete-details",
    label: "Business Details Incomplete (missing details)",
  },
  {
    id: 2,
    value: "inconsistent_details",
    slug: "inconsistent-details",
    label:
      "Business Details Inconsistent (inconsistent with information on the documents submitted or verification source)",
  },
  {
    id: 3,
    value: "unverifiable_details",
    slug: "unverifiable-details",
    label: "Business Details are Unverifiable",
  },
  {
    id: 4,
    value: "unacceptable_registration",
    slug: "unacceptable-registration",
    label: "Unacceptable documents of Registration (unclear or unverifiable)",
  },
  {
    id: 5,
    value: "unacceptable_owner_details",
    slug: "unacceptable-owner-details",
    label:
      "Unacceptable Business Owner Details (incomplete, inconsistent or unverifiable)",
  },
  {
    id: 6,
    value: "unacceptable_identification",
    slug: "unacceptable-identification",
    label: "Unacceptable Means of Identification (blurred image and/or data)",
  },
  {
    id: 7,
    value: "incomplete_bank_details",
    slug: "incomplete-bank-details",
    label: "Incomplete Bank Details",
  },
  {
    id: 8,
    value: "expired_identification",
    slug: "expired-identification",
    label: "Expired means of identification",
  },
];

const OnboardingDetails = () => {
  const { id } = useParams();
  const rejection = useRejection();
  const {
    data: businessDetails,
    isLoading,
    isError,
  } = useBusinessUserDetails(id);
  const [businessContactId, setBusinessContactId] = useState("");
  const update = useUpdateBRStatus(businessDetails?.data?.userId);
  const busKYC = useBusinessKycStatus(id);
  const busConKYC = useBusinessContactKycStatus(businessContactId);
  const busCorpKYC = useBusinessCorporateKycStatus(businessContactId);

  const animatedComponents = makeAnimated();

  const [check, setCheck] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState("");

  const navigate = useNavigate();
  const toggleAccordion = (accordionId: React.SetStateAction<string>) => {
    setActiveAccordion(accordionId === activeAccordion ? "" : accordionId);
  };
  const [openImage, setOpenImage] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [userKycStatus, setUserKycStatus] = useState(false);
  const [bankStatus, setBankStatus] = useState(false);
  const [businessKycStatus, setBusinessKycStatus] = useState(false);
  const [businessContactKycStatus, setBusinessContactKycStatus] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [businessCorpKycStatus, setBusinessCorpKycStatus] = useState(false);
  const [showRejectionReasons, setShowRejectionReasons] = useState(false);
  const [toastErrMsg, setToastErrMsg] = useState("");
  const [toastSuccessMsg, setToastSuccessMsg] = useState("");
  const [comments, setComment] = useState("");

  // const [showErrrToast, setShowErrToast] =useState(false)
  const initialUserStatus = businessDetails?.data?.user?.kycStatus;
  const initialBankStatus = businessDetails?.data?.user?.bankStatus;
  const initialBusinessKycStatus = businessDetails?.data?.kycstatus;
  const initialBusinessContactKycStatus =
    businessDetails?.data?.businessContact?.kycstatus;
  const initialBusinessCorporateKycStatus =
    businessDetails?.data?.BusinessCorporate?.kycstatus;

  useEffect(() => {
    if (businessDetails?.data) {
      setUserKycStatus(initialUserStatus?.toLowerCase() === "verified");
      setBankStatus(initialBankStatus === true);
      setBusinessKycStatus(
        initialBusinessKycStatus?.toLowerCase() === "verified"
      );
      setBusinessContactKycStatus(
        initialBusinessContactKycStatus?.toLowerCase() === "verified"
      );
      setBusinessCorpKycStatus(
        initialBusinessCorporateKycStatus?.toLowerCase() === "verified"
      );
    }
  }, [businessDetails]);

  useEffect(() => {
    setShowRejectionReasons(
      (!userKycStatus &&
        !bankStatus &&
        !businessKycStatus &&
        !businessContactKycStatus) ||
        !businessCorpKycStatus
    );
  }, [
    userKycStatus,
    bankStatus,
    businessKycStatus,
    businessContactKycStatus,
    businessCorpKycStatus,
  ]);

  const handleToggleChange1 = () => {
    setLoading1(true);

    const newStatus = !userKycStatus;
    update
      .mutateAsync({
        kycStatus: newStatus ? "verified" : "unverified",
      })
      .then((res) => {
        setUserKycStatus(newStatus);
        setLoading1(false);
        showSuccessToast("Status updates successfully");
      })
      .catch((e) => {
        showErrToast();
        // setToastErrMsg(e?.response?.data);
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const handleToggleChange2 = () => {
    setLoading2(true);

    const newStatus = !bankStatus;
    update
      .mutateAsync({
        bankStatus: newStatus ? "true" : "false",
      })
      .then((res) => {
        setBankStatus(newStatus);
        setLoading2(false);
        showSuccessToast("Status updates successfully");
      })
      .catch((e) => {
        showErrToast();
      })
      .finally(() => {
        setLoading2(false);
      });
  };
  const handleToggleChange3 = () => {
    setLoading3(true);

    const newStatus = !businessKycStatus;
    busKYC
      .mutateAsync({
        kycstatus: newStatus ? "verified" : "unverified",
      })
      .then((res) => {
        setBusinessKycStatus(newStatus);
        setLoading3(false);
        showSuccessToast("Status updates successfully");
      })
      .catch((e) => {
        showErrToast();
      })
      .finally(() => {
        // showErrToast();
        setLoading3(false);
      });
  };

  const handleToggleChange4 = (id: any) => {
    const t = 2000;
    setLoading4(true);
    setBusinessContactId(id);
    setTimeout(() => {
      // if (businessContactId) {
      const newStatus = !businessContactKycStatus;
      busConKYC
        .mutateAsync({
          kycstatus: newStatus ? "verified" : "unverified",
        })
        .then((res) => {
          setBusinessContactKycStatus(newStatus);
          setLoading4(false);
          showSuccessToast("Status updates successfully");
        })
        .catch((e) => {
          showErrToast();
        })
        .finally(() => {
          setBusinessContactId("");
          setLoading4(false);
        });
      // }
    }, t);
  };


  const handleToggleChange5 = (id: any) => {
    const t = 2000;
    setLoading5(true);
    setBusinessContactId(id);
    console.log(id, "ppppp");
    console.log(businessContactId, "businessContactId");
    setTimeout(() => {
      const newStatus = !businessCorpKycStatus;
      busCorpKYC
        .mutateAsync({
          kycstatus: newStatus ? "verified" : "unverified",
        })
        .then((res) => {
          setBusinessContactKycStatus(newStatus);
          setLoading5(false);
          showSuccessToast("Status updates successfully");
        })
        .catch((e) => {
          showErrToast();
        })
        .finally(() => {
          // showErrToast();
          setLoading5(false);
        });
    }, t);
  };

  const showSuccessToast = (msg: string) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const showErrToast = () => {
    toast.error("Unable to perform this operation at this time", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleOpenImage = (imageUrl: string) => {
    setImageLink(imageUrl);
    setOpenImage(true);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
    setImageLink("");
  };
  const handleSelect = (selectedValues: any[]) => {
    setSelectedOptions(selectedValues);
  };

  const handleRejection = () => {
    setLoading(true);
    rejection
      .mutateAsync({
        //@ts-ignore
        response: selectedOptions.map((x) => x?.label),
        comments: comments,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        showSuccessToast("Rejection Reason Sent!");
      })
      .catch(() => {
        setLoading(false);
        showErrToast();
      });
  };


  return (
    <div>
      <ToastContainer style={{ width: "500px" }} />{" "}
      <div className="bg-[#fff] w-full h-full  flex items-center justify-center ">
        <div className=" bg-white flex flex-col gap-3 max-w-[95%]  md:w-full  ">
          <div className="py-5 mt-5 ">
            <div className="flex items-center gap-4 lg:pl-9">
              <div
                onClick={() => navigate(-1)}
                className="bg-[#E5F2EA] cursor-pointer rounded-full p-2"
              >
                <FiChevronLeft />
              </div>
              <p className="text-[14px] leading-[14px] text-[#071827]">
                Customer Onboarding
              </p>
            </div>
            <div className=" pl-4 lg:pl-9 my-5  flex items-center justify-between">
              <h1 className="text-[30px] leading-[42px] font-medium text-[#071827]">
                {businessDetails?.data?.businessName}
              </h1>
              <div className="flex items-center gap-4">
                <p className="text-[#5C6F7F] text-[16px] leading-[25px] font-normal">
                  Local Bank Account Status:
                </p>
                <Notify status={"verified"} d={"Anchor"} />
              </div>
            </div>
            <div className="logo px-4 lg:px-9 ">
              <div className=" hidden md:block bg-[#F9F9F9] p-4">
                <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                  Application Information
                </h1>
              </div>
              <div className="mt-5 space-y-4">
                <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                  Business Account Details
                </h1>
                <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Contact details must match the means of identification
                </p>
              </div>
            </div>
            <div className="  lg:px-9 mt-5">
              <div className="px-4 border rounded-md hidden md:flex flex-col gap-4  bg-[#fff] p-4">
                <div className=" hidden md:flex items-center justify-between bg-[#F9F9F9] p-4">
                  <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                    Business Representative Details
                  </h1>

                  <ToggleSwitch
                    value={userKycStatus}
                    label={userKycStatus ? "Approved" : "Rejected"}
                    isLoading={loading1}
                    onChange={handleToggleChange1}
                  />
                </div>
                <div className="w-[100%] space-y-4 mt-6">
                  {/* <div className="flex items-center justify-between"> */}
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                        First Name:
                      </h1>
                    </div>
                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.firstName}
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Last Name:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.lastName}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Gender
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.gender}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Date of Birth:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                        {businessDetails?.data?.user?.dob}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Phone Number:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.businessPhoneNumber}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        BVN:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.bvn}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Email Address:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.email}
                      </h1>
                    </div>
                  </div>
                </div>

                <div className=" hidden md:flex items-center justify-between bg-[#fff] py-4">
                  <div className="space-y-4">
                    <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                      Means of Identification:
                    </h1>
                    <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                      Means of Identification supplied must match the contact
                      person details
                    </p>
                  </div>

                  {/* <ToggleSwitch
                    value={switchToggle}
                    onChange={() => setSwitchToggle(!switchToggle)}
                  /> */}
                  {/* <Notify status={"completed"} /> */}
                </div>
                <div className="flex items-center gap-4 ">
                  <div className="w-[40%] ">
                    <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                      ID Type:
                    </h1>
                  </div>

                  <div className="w-full ">
                    <h1
                      className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                      style={{ textAlign: "left" }}
                    >
                      {businessDetails?.data?.user?.idType
                        ?.toString()
                        .replace(/_/g, " ") ?? "NA"}
                    </h1>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-4 my-3">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap ">
                        Means of Identification:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {/* {idType} */}
                      </h1>
                    </div>
                  </div>
                  <img src={pdf} alt="file upload" className="w-28 h-20" />
                  <div className="flex items-center gap-2 my-3  ">
                    <p className="text-[12px] leading-normal text-[#071827] ">
                      Passport.pdf
                    </p>
                    <p className="text-[12px] leading-normal hover:underline cursor-pointer text-[#0EB683]">
                      Click to expand
                    </p>
                  </div>
                </div>
                <div>
                  <div className=" flex items-center justify-between bg-[#EAEAEA4D] p-4">
                    <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                      Third -party KYC Verification for Business Representative
                    </h1>
                    <Notify status={"verified"} d={"Smile Identity"} />
                    {/* <ToggleSwitch
                      value={switchToggle}
                      onChange={() => setSwitchToggle(!switchToggle)}
                    /> */}
                  </div>
                  <div>
                    <div className=" hidden md:flex items-center justify-between bg-[#fff] py-4 mb-5">
                      <div className="space-y-4">
                        <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                          Means of Identification as business employee
                        </h1>
                        <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Means of Identification supplied must match the
                          contact person details
                        </p>
                      </div>

                      {/* <ToggleSwitch
                        value={switchToggle}
                        onChange={() => setSwitchToggle(!switchToggle)}
                      /> */}
                      {/* <Notify status={"completed"} /> */}
                    </div>
                    <div className="flex items-center gap-4 ">
                      <div className="w-[40%] ">
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                          ID Type:
                        </h1>
                      </div>

                      <div className="w-full ">
                        <h1
                          className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                          style={{ textAlign: "left" }}
                        >
                          {/* {idType} */}
                        </h1>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex items-center gap-4 my-3">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap ">
                            Means of Identification as business employee:
                          </h1>
                        </div>

                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                            style={{ textAlign: "left" }}
                          >
                            {/* {idType} */}
                          </h1>
                        </div>
                      </div>
                      <img src={pdf} alt="file upload" className="w-28 h-20" />
                      <div className="flex items-center gap-2 my-3">
                        <p className="text-[12px] leading-normal text-[#071827] ">
                          Passport.pdf
                        </p>
                        <p className="text-[12px] leading-normal hover:underline cursor-pointer text-[#0EB683]">
                          Click to expand
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mt-6">
                <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] p-4">
                  <div className="space-y-4">
                    <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                      Bank Details
                    </h1>
                    <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                      Bank details supplied by user must match the user details
                      and means of identification
                    </p>
                  </div>
                  <ToggleSwitch
                    value={bankStatus}
                    label={bankStatus ? "Approved" : "Rejected"}
                    isLoading={loading2}
                    onChange={handleToggleChange2}
                  />
                  {/* <Notify status={"completed"} /> */}
                </div>
                <div className="w-[100%] space-y-4 pl-4 pb-6">
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                        Bank:
                      </h1>
                    </div>
                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.userBankName}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                        Account Number:
                      </h1>
                    </div>
                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.user?.userAccountNumber}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                        Account Name:
                      </h1>
                    </div>
                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      ></h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mt-6 pl-4">
                <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] ">
                  <div className="space-y-4">
                    <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                      Business Details
                    </h1>
                    <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                      Details supplied by business must match the registration
                      documents
                    </p>
                  </div>
                  <ToggleSwitch
                    value={businessKycStatus}
                    label={businessKycStatus ? "Approved" : "Rejected"}
                    isLoading={loading3}
                    onChange={handleToggleChange3}
                  />
                  {/* <Notify status={"completed"} /> */}
                </div>
                <div className="w-[100%] space-y-4 ">
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                        Business Name:
                      </h1>
                    </div>
                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessName}
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Business Type:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {/* {businessDetails?.data?.user?.lastName} */}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Registration Number:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessNumber}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Country of Registration:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.countryOfRegistration}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Business Address:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessAddress}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Business Phone Number:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessPhone}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Business Email:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessEmail}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Website:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.website}
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        Business Nature:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {businessDetails?.data?.businessNature}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <div className=" my-8 hidden md:flex items-center justify-between bg-[#fff] ">
                    <div className="space-y-4">
                      <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                        Registration Documents
                      </h1>
                      <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                        Registration Documents supplied by business must match
                        the business details
                      </p>
                    </div>
                    {/* <ToggleSwitch
                    value={switchToggle}
                    onChange={() => setSwitchToggle(!switchToggle)}
                  /> */}
                    {/* <Notify status={"completed"} /> */}
                  </div>
                  <div className="flex items-center gap-4 ">
                    <div className="w-[40%] ">
                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                        ID Type:
                      </h1>
                    </div>

                    <div className="w-full ">
                      <h1
                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                        style={{ textAlign: "left" }}
                      >
                        {/* {idType} */}
                      </h1>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-4 my-3">
                      <div className="w-[40%] ">
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap ">
                          Registration Documents:
                        </h1>
                      </div>

                      <div className="w-full ">
                        <h1
                          className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                          style={{ textAlign: "left" }}
                        >
                          {/* {idType} */}
                        </h1>
                      </div>
                    </div>
                    <img src={pdf} alt="file upload" className="w-28 h-20" />
                    <div className="flex items-center gap-2 my-3">
                      <p className="text-[12px] leading-normal text-[#071827] ">
                        CAC.pdf
                      </p>
                      <p
                        onClick={() =>
                          handleOpenImage(
                            businessDetails?.data?.registrationDoc
                          )
                        }
                        className="text-[12px] leading-normal hover:underline cursor-pointer text-[#0EB683]"
                      >
                        Click to expand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex items-center justify-between bg-[#EAEAEA4D] p-4 mt-5">
                <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                  Third -party KYC Verification for Business Details
                </h1>
                <Notify status={"verified"} d={"Smile Identity"} />
                {/* <ToggleSwitch
                  value={switchToggle}
                  onChange={() => setSwitchToggle(!switchToggle)}
                /> */}
              </div>
              <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] p-4">
                <div className="space-y-4">
                  <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                    Ultimate Business Owner
                  </h1>
                  <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                    Business owner details must match the means of
                    identification
                  </p>
                </div>
                {/* <ToggleSwitch
                  value={switchToggle}
                  onChange={() => setSwitchToggle(!switchToggle)}
                /> */}
                {/* <Notify status={"completed"} /> */}
              </div>
              <div className=" hidden md:flex flex-col gap-4  bg-[#fff] px-4">
                <>
                  <div className=" rounded-lg overflow-hidden">
                    <div
                      className={`flex items-center accordion-title text-[#0EB683]  px-4 py-2  cursor-pointer hover:text-[#366b45] ${
                        activeAccordion === "accordion1" && "bg-gray-100"
                      }`}
                      onClick={() => toggleAccordion("accordion1")}
                    >
                      <span className=" inline-flex items-center">
                        {activeAccordion === "accordion1" ? (
                          <MdOutlineKeyboardArrowUp size={24} />
                        ) : (
                          <MdOutlineKeyboardArrowDown size={24} />
                        )}
                      </span>
                      <span className="font-medium ">
                        {" "}
                        Business Contact Details
                      </span>
                    </div>
                    {activeAccordion === "accordion1" && (
                      <>
                        {businessDetails?.data?.businessContact?.map(
                          (
                            businessIndividual: IBusinessContact,
                            index: number
                          ) => {
                            const {
                              addressLine1,
                              shareholding,
                              addressLine2,
                              country,
                              idType,
                              postCode,
                              phoneNumber,
                              state,
                              email,
                              idDoc,
                              id,
                            } = businessIndividual;
                            return (
                              <div className="accordion-content px-4 py-2 flex flex-col gap-4 border rounded-md ">
                                <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] ">
                                  <div>
                                    <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                                      Business Owner {index + 1} Details
                                    </h1>
                                    {/* <p className="text-[14px] leading-[19px] lg:text-[16px]       lg:leading-[21px] font-normal text-[#5C6F7F]">
                    Details supplied by business must match the registration
                    documents
                    </p> */}
                                  </div>
                                  <ToggleSwitch
                                    value={businessContactKycStatus}
                                    label={
                                      businessContactKycStatus
                                        ? "Approved"
                                        : "Rejected"
                                    }
                                    isLoading={loading4}
                                    onChange={() => handleToggleChange4(id)}
                                  />
                                  {/* <Notify status={"completed"} /> */}
                                </div>
                                <div className="w-[100%] space-y-4">
                                  {/* <div className="flex items-center justify-between"> */}
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                                        Type:
                                      </h1>
                                    </div>
                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      ></h1>
                                    </div>
                                  </div>

                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Full Name:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {
                                          businessDetails?.data?.businessContact
                                            ?.fullName
                                        }
                                      </h1>
                                    </div>
                                  </div>
                                  {/* <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Last Name:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessDetails?.data?.user?.lastName}
                                      </h1>
                                    </div>
                                  </div> */}
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Email Address:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {email}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Phone Number:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {phoneNumber}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Address Line 1:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {addressLine1}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Address Line 2:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {addressLine2}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        State:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {state}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Country :
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {country}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Post Code:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {postCode}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        % Shareholding:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {shareholding}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] ">
                                    <div>
                                      <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                                        Means of Identification
                                      </h1>
                                      <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                                        Means of Identification supplied by user
                                        must match the business owner details
                                      </p>
                                    </div>
                                    {/* <ToggleSwitch
        value={switchToggle}
        onChange={() => setSwitchToggle(!switchToggle)}
      /> */}
                                    {/* <Notify status={"completed"} /> */}
                                  </div>

                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        ID Type:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {idType?.toString().replace(/_/g, " ")}
                                      </h1>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="flex items-center my-3  gap-4">
                                      {" "}
                                      <div className="w-[40%] ">
                                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap ">
                                          Means of Identification:
                                        </h1>
                                      </div>
                                      <div className="w-full ">
                                        <h1
                                          className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                          style={{ textAlign: "left" }}
                                        >
                                          {idType
                                            .toString()
                                            ?.replace(/_/g, " ")}
                                        </h1>
                                      </div>
                                    </div>
                                    <img
                                      src={pdf}
                                      alt="file upload"
                                      className="w-28 h-20"
                                    />
                                    <div className="flex items-center gap-2 my-3">
                                      <p className="text-[12px] leading-normal text-[#071827] ">
                                        {idType.toLowerCase()}.pdf
                                      </p>
                                      <p
                                        onClick={() => handleOpenImage(idDoc)}
                                        className="text-[12px] leading-normal hover:underline cursor-pointer text-[#0EB683]"
                                      >
                                        Click to expand
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </div>

                  <div className=" rounded-lg mt-4 overflow-hidden">
                    <div
                      className={`flex items-center accordion-title text-[#0EB683]  px-4 py-2  cursor-pointer hover:text-[#366b45] ${
                        activeAccordion === "accordion1" && "bg-gray-100"
                      }`}
                      onClick={() => toggleAccordion("accordion2")}
                    >
                      <span className="">
                        {activeAccordion === "accordion2" ? (
                          <MdOutlineKeyboardArrowUp size={24} />
                        ) : (
                          <MdOutlineKeyboardArrowDown size={24} />
                        )}
                      </span>
                      <span className="font-medium">
                        {" "}
                        Business Corporate Details
                      </span>
                    </div>
                    {activeAccordion === "accordion2" && (
                      <>
                        {businessDetails?.data?.BusinessCorporate?.map(
                          (corporate: any, index: number) => {
                            console.log({ corporate });
                            const {
                              businessAddress,
                              shareholding,
                              addressLine2,
                              countryOfRegistration,
                              idType,
                              postCode,
                              businessPhoneNumber,
                              state,
                              businessEmail,
                              registrationDocument,
                              id,
                            } = corporate;
                            return (
                              <div className="accordion-content px-4 py-2 flex flex-col gap-4 border rounded-md ">
                                <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] ">
                                  <div>
                                    <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                                      Corporate Business Owner {index + 1}{" "}
                                      Details
                                    </h1>
                                    {/* <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                            Details supplied by business must match the registration
                            documents
                          </p> */}
                                  </div>
                                  <ToggleSwitch
                                    value={businessCorpKycStatus}
                                    label={
                                      businessCorpKycStatus
                                        ? "Approved"
                                        : "Rejected"
                                    }
                                    isLoading={loading5}
                                    onChange={() => handleToggleChange5(id)}
                                  />
                                </div>

                                <div className="w-[100%] space-y-4">
                                  {/* <div className="flex items-center justify-between"> */}
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                                        Type:
                                      </h1>
                                    </div>
                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      ></h1>
                                    </div>
                                  </div>

                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        First Name:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessDetails?.data?.user?.firstName}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Last Name:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessDetails?.data?.user?.lastName}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Email Address:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessEmail}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Phone Number:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessPhoneNumber}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Address Line 1:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {businessAddress}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Address Line 2:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {addressLine2}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        State:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {state}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Country :
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {countryOfRegistration}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        Post Code:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {postCode}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        % Shareholding:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {shareholding}
                                      </h1>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] ">
                                    <div>
                                      <h1 className=" lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                                        Registration Documents
                                      </h1>
                                      <p className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                                        Means of Identification supplied by user
                                        must match the business owner details
                                      </p>
                                    </div>
                                    {/* <ToggleSwitch
                              value={switchToggle}
                              onChange={() => setSwitchToggle(!switchToggle)}
                            /> */}
                                    {/* <Notify status={"completed"} /> */}
                                  </div>
                                  <div className="flex items-center gap-4 ">
                                    <div className="w-[40%] ">
                                      <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap">
                                        ID Type:
                                      </h1>
                                    </div>

                                    <div className="w-full ">
                                      <h1
                                        className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                        style={{ textAlign: "left" }}
                                      >
                                        {idType}
                                      </h1>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="flex items-center my-3 gap-4">
                                      {" "}
                                      <div className="w-[40%] ">
                                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] whitespace-nowrap ">
                                          Means of Identification:
                                        </h1>
                                      </div>
                                      <div className="w-full ">
                                        <h1
                                          className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-normal text-[#071827]"
                                          style={{ textAlign: "left" }}
                                        >
                                          {idType}
                                        </h1>
                                      </div>
                                    </div>
                                    <img
                                      src={pdf}
                                      alt="file upload"
                                      className="w-28 h-20"
                                    />
                                    <div className="flex items-center gap-2 my-3">
                                      <p className="text-[12px] leading-normal text-[#071827] ">
                                        Passport.pdf
                                      </p>
                                      <p
                                        onClick={() =>
                                          handleOpenImage(registrationDocument)
                                        }
                                        className="text-[12px] leading-normal hover:underline cursor-pointer text-[#0EB683]"
                                      >
                                        Click to expand
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
              {showRejectionReasons && (
                <div className="mt-8">
                  <div className=" hidden md:block bg-[#EAEAEA4D] p-4">
                    <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                      Compliance - For Rejections only
                    </h1>
                  </div>
                  <div>
                    <div className="w-[55%] mt-9">
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder="Rejection Reasons"
                        options={rejectionReasons}
                        className=""
                        value={selectedOptions}
                        isSearchable={true}
                        // @ts-ignore
                        onChange={handleSelect}
                      />
                    </div>
                    <div className="w-[55%] mt-4">
                      <TextArea
                        label="Enter Comments"
                        name="comments"
                        rows={2}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="flex  md:items-center xxs:gap-1  md:gap-3 mt-5 md:ml-1 pl-10">
              <AppCheckBox
                getChecked={(value: boolean) => {
                  setCheck(value);
                }}
              />
              <small className=" xxs:text-left  md:text-sm ">
                I agree to the website{" "}
                <button
                  onClick={() =>
                    navigate("/onboarding/Terms-Of-Use", { replace: true })
                  }
                  className="text-[#0eb683] transition-all active:scale-90"
                >
                  terms of use
                </button>{" "}
                and{" "}
                <button
                  onClick={() =>
                    navigate("/onboarding/terms-Of-Service", { replace: true })
                  }
                  className="text-[#0eb683] transition-all active:scale-90"
                >
                  terms of service
                </button>{" "}
              </small>
            </div>
             <div className="flex justify-between px-10 mt-6">
               <button
                 onClick={() => setIsSummary(false)}
                 className="bg-[rgb(255, 255, 255)] hover:[#def8f0] text-[#0eb683] shadow-sm font-normal py-3 px-8 rounded-lg text-[16px] border border-[#0eb683] leading-[24px]"
               >
                 Back
               </button>
               <button className="bg-[#0eb683] hover:bg-[##11ffbb text-white font-normal shadow-sm py-3 px-12 rounded-lg text-[16px] border leading-[24px]">
                 Submit
               </button>
             </div> */}

            <div className="flex justify-between px-10 mt-6">
              <button
                //  onClick={() => setIsSummary(false)}
                className="bg-[rgb(255, 255, 255)] hover:[#def8f0] text-[#0eb683] shadow-sm font-normal py-3 px-8 rounded-lg text-[16px] border border-[#0eb683] leading-[24px]"
              >
                Back
              </button>
              <button
                onClick={handleRejection}
                className="bg-[#0eb683] hover:bg-[##11ffbb text-white font-normal shadow-sm py-3 px-12 rounded-lg text-[16px] border leading-[24px]"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      fill="white"
                    />
                    <path
                      d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  "Inform User"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {openImage && (
        <div
          className={`fixed top-0 left-0 w-full h-screen bg-[rgba(0,0,0,0.3)] flex flex-col justify-center items-center transform transition-all duration-300
    scale-100 opacity-100 z-50
  `}
        >
          <div className="w-full h-full flex items-center justify-center">
            <div className="bg-white shadow-lg p-6 max-w-[100%] max-h-[100%] overflow-auto">
              <div className="flex gap-8 justify-end items-center text-sm mb-4">
                <a
                  href={imageLink}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoMdDownload size={23} />
                </a>
                <button onClick={handleCloseImage}>
                  <AiOutlineClose size={23} />
                </button>
              </div>

              <ImageModal
                isOpen={openImage}
                imageUrl={imageLink}
                onClose={handleCloseImage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingDetails;

const Notify = ({ status, d }: { status: string; d: string }) => {
  return (
    <div
      className={`text-[12px] leading-[18px] font-normal px-2 py-1 rounded-[24px] flex items-center gap-2 ${
        status === "rejected"
          ? "bg-[#FEE2E2] text-[#E01A00]"
          : "bg-[#E2FEF0] text-[#0eb683]  "
      }`}
    >
      <span>
        {status === "rejected" ? <BsX size={18} /> : <BsCheck2 size={18} />}
      </span>
      {status === "rejected" ? `rejected by ${d}` : `verifed by ${d}`}
    </div>
  );
};
