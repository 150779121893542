import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/misc/logo-on-light.svg";
import logoWhite from "../../assets/BackOffice Icons/Trusted Transfers BlackAsset 1 1.svg";
import styled from "styled-components";
import { DashBoardData } from "./DashBoardData";
import Submenu from "./Submenu";

const Aside = () => {
  return (
    <aside
      className={`md:flex fixed md:relative top-0 left-0 z-10 bg-[#0c0d1f] md:bg-[#F7FBFB] w-60 md:border-r text-gray-200 md:text-[#071827]  h-screen flex-col  p-7 md:p-0 transition-all duration-500`}
    >
      <div className="logo md:p-6 ">
        <a href="/dashboard">
          <img src={Logo} alt="" className=" w-28 hidden md:block " />
        </a>
        <a href="/dashboard">
          <img src={logoWhite} alt="" className="w-28 block md:hidden" />
        </a>
      </div>
      {DashBoardData.map((item, index) => {
        //@ts-ignore
        return <Submenu item={item} key={index} />;
      })}
    </aside>
  );
};

export default Aside;
