import React, { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { IoCheckbox } from "react-icons/io5";
import CustomSelect from "../../../CustomSelect";
import SuccessModal from "../../widgets/SuccessModal";

export type IOptionType = {
  id: number;
  label: string;
  slug: string;
} | null;

export const roleOptions = [
  {
    id: 1,
    label: "International Passport",
    slug: "international-passport",
  },
  {
    id: 2,
    label: "National Identity Slip",
    slug: "national-identity-slip",
  },
  {
    id: 3,
    label: "National Identification Card",
    slug: "national-identification-card",
  },
  {
    id: 4,
    label: "Driver's License",
    slug: "drivers-license",
  },
  {
    id: 5,
    label: "Voter's Card",
    slug: "voters-card",
  },
];

const AccessPermission = () => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [role, setRole] = useState<IOptionType>(null);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    if (checked) {
      setSelectedValues((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedValues((prevSelected) =>
        prevSelected.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  console.log(selectedValues);
  return (
    <div>
      <div>
        <h1 className="text-[20px] font-semibold text-[#071827]">
          {`Assign Access Permissions`}
        </h1>
        <p className="text-[#5C6F7F] text-[16px] leading-normal mt-3">{`Select features you wish to grant your user access to`}</p>
      </div>
      <div className="mt-10">
        <div className="relative w-[49%] ">
          <CustomSelect
            selectedOption={role}
            setSelectOption={setRole}
            placeholder={"Role"}
            options={roleOptions || []}
          />
          <span className="text-red-600 absolute left-2 top-2">*</span>
        </div>
        <div className="mt-6">
          <Checkbox
            label={"All Features"}
            value={"all_features"}
            name={"all_features"}
            onChange={handleCheckboxChange}
          />
        </div>
        <div className="flex space-x-6 my-3">
          <div className="w-1/2 space-y-3">
            <Checkbox
              label={"Dashboard"}
              value={"dashboard"}
              name={"dashboard"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Accounts"}
              value={"accounts"}
              name={"accounts"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Approvals"}
              value={"approvals"}
              name={"approvals"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Payouts"}
              value={"payouts"}
              name={"payouts"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Balances"}
              value={"balances"}
              name={"balances"}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="w-1/2 space-y-3">
            {" "}
            <Checkbox label={"Users"} value={"users"} name={"users"} />
            <Checkbox
              label={"Transactions"}
              value={"transactions"}
              name={"transactions"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Revenue"}
              value={"revenue"}
              name={"revenue"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Fees"}
              value={"fees"}
              name={"fees"}
              onChange={handleCheckboxChange}
            />
            <Checkbox
              label={"Rates"}
              value={"rates"}
              name={"rates"}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className="w-[49%] space-x-6">
          <Checkbox
            label={"Settings"}
            value={"settings"}
            name={"settings"}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AccessPermission;

interface CheckboxProps {
  label: string;
  value: string;
  name: string;
  defaultChecked?: boolean;
  bg?: string;
  onChange?: (value: string, checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value,
  name,
  defaultChecked = false,
  bg,
  onChange = () => {},
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    onChange(value, isChecked);
  };

  return (
    <div
      className={`border border-[#E2E9F0] rounded-[4px] px-3 pt-2 pb-1 bg-[${bg}]`}
    >
      <label className="inline-flex items-center cursor-pointer ">
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={handleChange}
          value={value}
          name={name}
        />
        <span className="relative flex items-center gap-2">
          <span
            className={`flex items-center justify-center h-5 w-5 rounded-[4px] border ${
              checked ? "border-white" : "bg-white border-[#E2E9F0]"
            }`}
          >
            {checked && (
              <div className="absolute">
                <IoCheckbox color="#0EB683" size={23} />
              </div>
            )}
          </span>
          <span className=" text-[#071827] text-[16px] leading-normal">
            {label}
          </span>
        </span>
      </label>
    </div>
  );
};
