import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { MdOutlineTune } from "react-icons/md";
import Tune from "../../assets/iconsComponents/Tune";

const SearchComponent = ({placeholder}:{placeholder: string}) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <header className="py-2 flex justify-between">
        <div className="search flex items-center  text-[#121413] py-1  gap-1 rounded-md">
          <BsSearch fill="#505153" size={19}/>
          <input
            type="text"
            placeholder={placeholder}
            className="flex-1 p-2 bg-transparent outline-none"
            onChange={() => {}}
          />
        </div>
        <div className="flex items-center gap-4">
          <div  onClick={()=>setOpen(!open)} className=" flex items-center cursor-pointer">
            <span className="text-base ">Show All</span>
            {open ? < BiChevronUp size={19} /> : <BiChevronDown size={19} />}
          </div>
          <div className="cursor-pointer">
            <MdOutlineTune size={23} />
          </div>

          <div className="cursor-pointer">
            <FiDownload size={23} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default SearchComponent;
