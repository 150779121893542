import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUsers } from "../sevices/hooks/users";
import LoadingScreen from "../pages/Notifications/LoadingScreen2";
import Aside from "../components/dashboard-components/Aside";
import Nav from "../components/dashboard-components/Nav";
import { useGbpRates, useRates, useRatesEur } from "../sevices/hooks/tags";
import { useRatesState, useUserState } from "../store";
import InviteUsers from "../components/base-components/InviteUsers";
import AutoLogout from "../pages/authentication/AutoLogout";
// import Aside from "../components/dashboard-components/DesktopAside";
// import MobileAside from "../components/dashboard-components/MobileAside";

const DashBoardLayout = () => {
  const setUSDRates = useRatesState((state) => state.setUSDRate);
  const setEURRates = useRatesState((state) => state.setEURRate);
  const setGBPRates = useRatesState((state) => state.setGBPRate);
  const setUserDetails = useUserState((state) => state.setUserDetails);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const currentRate = useRates();
  const eurRates = useRatesEur();
  const gbpRates = useGbpRates();
  // const location = useLocation()
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );

  // const activeLink = (path: string) => {
  //   if (location.pathname === '/dashboard' && path === "") {
  //     return "border-l-4 border-[#0eb683] bg-[#F7FBFB]"
  //   }

  //   return location.pathname === '/dashboard/' + path ? "border-l-4 border-[#0eb683] bg-[#F7FBFB]" : ""
  // }

  useEffect(() => {
    let token = localStorage.getItem("accessToken") || "";
    let user_id = localStorage.getItem("user_id") || "";
    setToken(token);
    setUserId(user_id);
  }, [token, userId]);

  useEffect(() => {
    // console.log(getUserDetails);

    // if (getUserDetails.status === 'error') {
    //   navigate('/login')
    // }

    // if (getUserDetails.isError) {
    //   navigate('/login')
    // }

    setUserDetails(getUserDetails.result?.data);
  }, [
    getUserDetails.isError,
    getUserDetails.result?.data,
    getUserDetails.status,
    navigate,
    setUserDetails,
  ]);

  // useEffect(() => {
  //   setUSDRates(currentRate?.data?.data.toFixed(2));

  //   setEURRates(eurRates?.data?.data.toFixed(2));
  //   setGBPRates(gbpRates?.data?.data.toFixed(2));
  // }, [
  //   currentRate?.data?.data,
  //   currentRate.status,
  //   eurRates?.data?.data,
  //   eurRates.status,
  //   gbpRates?.data?.data,
  //   gbpRates.status,
  //   setEURRates,
  //   setGBPRates,
  //   setUSDRates,
  // ]);

  return (
    // <LoadingScreen
    //   showLoading={
    //     getUserDetails.status === "loading" ||
    //     currentRate.status === "loading" ||
    //     (!getUserDetails.isSuccess && getUserDetails.isError)
    //   }
    // >

    
    // <AutoLogout>
      <div className="dashboard overflow-x-hidden h-screen">
        <div className="flex flex-1">
          <div>
            <Aside />
          </div>

          <div className="grid grid-rows-[auto_1fr] w-full h-screen overflow-auto">
            <Nav />
            <main className="overflow-x-hidden">
              <Outlet context={[getUserDetails.result?.data]} />
            </main>
          </div>
        </div>
        <InviteUsers data={getUserDetails.result?.data} />
      </div>
    // </AutoLogout>
    // </LoadingScreen>
  );
};

export default DashBoardLayout;
