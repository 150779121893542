
import DesktopAside from "../dashboard-components/DesktopAside";
import { useSidebarState } from "../../store";

const Aside = () => {
  const sideBarOpen = useSidebarState((state) => state.sideBarOpen);
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  return (
    <div className="sidebar overflow-y-scroll h-screen">
      <div
        className={`fixed overlay w-full h-screen bg-black opacity-80 top-0 left-0 z-10 md:hidden ${
          !sideBarOpen ? "hidden" : ""
        }`}
        onClick={() => toggleSideBarOpen()}
      ></div>
      <DesktopAside />
    </div>
  );
};

export default Aside;

//Try this remove Desktop aside from aside  render it in the dashboard screen
