import React, { useState } from 'react'
import LpModal from './LpModal';
import AddLpModal from './AddLpModal';
import { TabPanel, useTabs } from '../widgets/TabWidget';
import { MdOutlineAdd } from 'react-icons/md';
import { TabSelector } from '../widgets/TabSelector';
import AppTable from '../AppTable';
import { Column } from 'react-table';


const SettingsWallet = () => {
     const [modal, setModal] = useState(false);
     const [lpModal, setLpModal] = useState(false);
     const [subTab, setSubTab] = useTabs(["USDC", "USDT"]);
     const toggleModal = () => {
       setModal((modal) => !modal);
     };
  return (
    <div>
      {/* <div className="flex justify-between">
        <div>
          <h1 className=" text-xl font-semibold text-[#2B2B2B]">
            Liquidity Partner
          </h1>
          <p className="text-[#676767] text-sm ">
            Manage liquidity partners, click to expand and select partner of
            choice
          </p>
        </div>
      </div> */}
      <nav className=" border-b border-[#E8E9EB] px-5 md:px-0 mt-5  pb-1 flex gap-6 justify-between md:justify-start mr-8">
        <TabSelector
          className={` cursor-pointer relative bg-transparent text-[14px] leading-[24px] ${
            subTab === "USDC" ? "text-[#0eb683]" : "text-[#5C6F7F]"
          } font-bold text-md ${
            subTab === "USDC"
              ? "text-[#0eb683] before:block before:w-full before:h-[1px] before:bg-[#0eb683] before:absolute before:bottom-[-6px] before:transition-all"
              : ""
          } `}
          isActive={subTab === "USDC"}
          onClick={() => setSubTab("USDC")}
        >
          USDC
        </TabSelector>
        <TabSelector
          className={` cursor-pointer relative bg-transparent text-[14px] leading-[24px]  ${
            subTab === "USDT" ? "text-[#0eb683]" : "text-[#5C6F7F]"
          } font-bold text-md ${
            subTab === "USDT"
              ? "text-[#0eb683] before:block before:w-full before:h-[1px] before:bg-[#0eb683] before:absolute before:bottom-[-6px] before:transition-all"
              : ""
          } `}
          isActive={subTab === "USDT"}
          onClick={() => setSubTab("USDT")}
        >
          USDT
        </TabSelector>
      </nav>
      <div className="mx-10">
        <TabPanel hidden={subTab !== "USDC"} className="mt-5">
          <div>
            <AppTable
              tableData={[]}
              tabs={[]}
              //   @ts-ignore
              dColumns={dColumns}
              showFilter={true}
            />
          </div>
        </TabPanel>
        <TabPanel hidden={subTab !== "USDT"} className="mt-5">
          <div>
            <AppTable
              tableData={[]}
              tabs={[]}
              //   @ts-ignore
              dColumns={dColumns}
              showFilter={true}
            />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default SettingsWallet


export const dColumns: readonly Column<any>[] = [
  {
    Header: "SN",
    accessor: "serialNumber",
    //@ts-ignore
    Cell: ({ value, row }) => {
      const index = row?.index;
      return (index !== undefined ? index + 1 : "").toString();
    },
  },
  {
    Header: "TIMESTAMP (GMT+1)",
    accessor: "createdAt",
    //@ts-ignore
    Cell: (value) => {
      //   const myDate = new Date(value);
      //   const formatDate = moment(myDate);
      //   const Cdate = formatDate.format("lll");
      return value.toString();
    },
  },
  {
    Header: "USER ID",
    accessor: "transactionRef",
    Cell: ({ value }: { value: string }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <Tooltip message={value}> */}
        <a
          className="truncate hover:underline cursor-pointer text-[#0eb683]"
          style={{ marginRight: "0.5rem" }}
        >
          {value.slice(0, 20)}
        </a>
        {/* </Tooltip> */}
      </div>
    ),
  },
  {
    Header: "USER NAME",
    accessor: (row: { currency: string; type: string; }) =>
      ` ${
        typeof row?.currency === "string"
          ? row?.currency.toUpperCase()
          : row?.currency
      } ${row?.type.charAt(0).toUpperCase()}${row?.type.slice(1)} `,
    Cell: ({ value }: { value: string }) => (
      <div className="">
        <span className="flex items-center whitespace-nowrap">{value}</span>
      </div>
    ),
  },
  {
    Header: "WALLET ADDRESS",
    accessor: (row: { amount: any; currency: string; }) => {
      let currencySymbol = "";
      const formatter = new Intl.NumberFormat();
      const amount = formatter.format(Number(row?.amount));
      switch (row?.currency?.toUpperCase()) {
        case "NGN":
          currencySymbol = "₦ ";
          break;
        case "USD":
          currencySymbol = "$ ";
          break;
        default:
          break;
      }

      return (
        <div className="whitespace-nowrap">{`${currencySymbol}${amount}`}</div>
      );
    },
  },

  //   {
  //     Header: "NARRATION",
  //     accessor: "narration",
  //     Cell: ({ value }) => (
  //       <div className="flex items-left">
  //         {/* <Tooltip message={value}> */}
  //         <div className="truncate w-40 cursor-pointer">{value}</div>
  //         {/* </Tooltip> */}
  //       </div>
  //     ),
  //   },
  {
    Header: "ACTIONS",
    accessor: (row: any) => {
      console.log({ row });
      return (
        <div
        //   onClick={() => {
        //     handleDetails(row?.userId);
        //   }}
          className="whitespace-nowrap truncate hover:underline cursor-pointer text-[#0eb683]"
        >{`view more`}</div>
      );
    },
  },
];