// {
//   suspendUser && <SuspendUserModal isOpen={suspendUser} onClose={suspend} />;
// }

import React from "react";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import ReactLoading from "react-loading";

interface ModalProps {
  isOpen: boolean | string;
  onClose: () => void;
}

const SuccessModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const modalRef = React.useRef(null);

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };
  console.log("====================================");
  console.log(onClose);
  console.log("====================================");

  return (
    <div>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            // delay: 0.3,
            duration: 0.1,
          },
        }}
        className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-10"
      >
        <motion.div
          initial={{
            x: 600,
            scale: 0,
          }}
          animate={{
            x: 0,
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            x: 600,
            scale: 0,
            transition: {
              delay: 0.3,
            },
          }}
          ref={modalRef}
          onClick={handleOverLayClick}
          className="flex h-screen items-end md:items-center  md:justify-center "
        >
          <motion.div
            initial={{
              x: 600,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            }}
            exit={{
              x: 100,
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl "
          >
            <div className="flex flex-col gap-3  my-auto">
              <div className="flex items-center justify-between border-b border-slate-300 px-8 pb-8 relative ">
                <div>
                  <div className=" ">
                    <h1 className="text-xl text-[##2B2B2B] font-semibold   ">
                      {isOpen === true ? "Suspend User" : "Reactivate User"}
                    </h1>
                  </div>
                  <div className=" mt-1 md:mt-0 ">
                    <p className="text-[#5C6F7F] text-sm ">
                      {isOpen === true
                        ? "Are you sure you want to suspend this user?"
                        : "Are you sure you want to reactivate this user?"}
                    </p>
                  </div>
                </div>
                <button
                  onClick={onClose}
                  className={`absolute right-3 top-[-42px]  ${
                    isOpen === true
                      ? "text-[#ED0000] hover:bg-red-200"
                      : "hover:bg-green-200 text-[#0eb683]"
                  } rounded-full hover:rounded-full flex    bg-transparent   p-2 hover:rotate-90 hover:transform  transition duration-150 ease-in-out  `}
                >
                  <AiOutlineClose size={28} />
                </button>
              </div>

              {/* <div className="w-full h-[1px] bg-slate-400 z-50"></div> */}
              <div className="mt-6 px-8">
                <h1 className="text-xl text-[#2B2B2B] font-bold">
                  Abubakar Adebayo Uche
                </h1>
                <p className="text-[#5C6F7F] text-sm ">
                  {isOpen === true
                    ? "All access would be suspended with immediate effect"
                    : "All access would be granted with immediate effect"}
                </p>
              </div>

              <div className="flex items-center justify-between mt-6 px-8">
                <button
                  onClick={() => {}}
                  className="text-[#0eb683] active:scale-90 border border-[#0eb683] md:p-3 rounded-md transition-all w-36  xxs:p-[17px] flex items-center justify-center  "
                >
                  <span className="w-14">Cancel</span>{" "}
                </button>
                <button
                  // disabled={}
                  onClick={() => {}}
                  className={`${
                    isOpen === true
                      ? "bg-[#ED0000] border-[##ED0000]active:bg-[#f75b5b]"
                      : "bg-[#0eb683] border-[#0eb683] active:[]"
                  }transition-colors bg-center self-center justify-center w-56 border  active:bg-[#8fc0ad] text-white md:p-3 rounded-md  md:mt-0 active:bg-auto   active:transition-color `}
                >
                  {/* Yes, suspend user */}
                  {/* {loading ? ( */}
                  <div className=" ml-[90px]">
                    <ReactLoading
                      type="spinningBubbles"
                      color="#fff"
                      height="27px"
                      width="27px"
                    />
                  </div>
                  {/* ) : (
                          "Submit"
                        )} */}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SuccessModal;
