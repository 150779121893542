import React, { useState } from "react";
import { MdOutlineRefresh } from "react-icons/md";
import RateTable from "../../components/dashboard-components/rate-component/RateTable";
import MarkedUpRateTable from "../../components/dashboard-components/rate-component/MarkedUpRateTable";

const Rates = () => {
  const [currentTab, setCurrentTab] = useState("Live");
  // const [rateType, setRateType] = useState("Live")

  return (
    <div className="h-screen w-full bg-[#FEFEFF] p-5 pr-7 md:pl-8 ">
      <div className="  mt-8">
        <h1 className="font-bold text-3xl text-[#071827] ">Rates</h1>
        <div className="flex items-center justify-between">
          <div className="h-10 w-80  ">
            <h3 className="text-[#676767] font-medium text-lg">
              TTFCX {currentTab} Exchange Rates
            </h3>
          </div>

          <div className="toggle w-60 h-11  flex border border-[#0eb683] mb-6 md:mb-4 self-center justify-between rounded-md cursor-pointer p-1">
            <div
              className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-sm ${
                currentTab === "Live" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCurrentTab("Live");
                // setRateType("Marked-up")
              }}
            >
              Live
            </div>
            <div
              className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-sm ${
                currentTab === "Marked-up" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCurrentTab("Marked-up");
                // setRateType("Marked-up")
              }}
            >
              Marked-up
            </div>
          </div>
          <figure className="rounded-full h-10 w-10 bg-[#0eb683] text-[#fff] flex items-center justify-center cursor-pointer transition-all active:scale-90 active:rotate-360    active:transform  active:transition active:duration-150 active:ease-in-out">
            <MdOutlineRefresh size={28} />
          </figure>
        </div>
      </div>
      <div>
        {currentTab === "Live" ? (
          <div className="mt-10 w-full">
            <RateTable />
          </div>
        ) : (
          <div className="mt-10 w-full">
            <MarkedUpRateTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default Rates;
