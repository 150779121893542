import React from "react";

interface SwitchProps {
  value: boolean;
  label: string;
  isLoading: boolean;
  onChange: (e?: any) => void;
}

const ToggleSwitch = (props: SwitchProps) => {
  const getColorClass = () => {
    if (props.isLoading) {
      return "bg-gray-400"; // Gray color when loading
    } else if (props.value) {
      return "bg-[#0EB683]"; // Green color when approved
    } else {
      return "bg-[#BBC0C9]"; // Red color when rejected
    }
  };

  return (
    <div className="flex flex-col items-end ">
      <div
        onClick={props.onChange}
        className={`h-6 w-10 flex items-center ${getColorClass()} rounded-xl border p-1 cursor-pointer`}
      >
        <div
          className={`${
            props.value === true
              ? "bg-[#fff] transform translate-x-3 duration-500"
              : "bg-[#fff] transform -translate-x-[2px] duration-500"
          } h-5 w-5 border rounded-full shadow-md transition  transform duration-500 ${
            props.value ? "transform translate-x-3 duration-500" : ""
          } `}
        ></div>
      </div>
      <div
        className={`text-[18px] leading-[25px]  w-28 text-center ${
          props.value ? "text-[#0D9A6F]" : "text-[#BBC0C9]"
        }`}
      >
        {props.isLoading ? (
          <svg
            className="animate-spin h-6 w-16 text-center text-[#0D9A6F]"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="black"
            />
            <path
              d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
              fill="#0D9A6F"
            />
          </svg>
        ) : (
          props.label
        )}
      </div>
    </div>
  );
};

export default ToggleSwitch;
