import React, { useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import {
  MdOutlineDisabledVisible,
  MdOutlinePersonAddDisabled,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { TeamMember, teamMembers } from "../../../pages/dashboard/Settings";
import { AiOutlineEdit } from "react-icons/ai";
import AppTable from "../AppTable";
import { RiDownloadCloud2Fill } from "react-icons/ri";
import { Checkbox } from "./stepper/AccessPermission";

const RoleUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  // const [role, setRole] = useState<IOptionType>(null);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    if (checked) {
      setSelectedValues((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedValues((prevSelected) =>
        prevSelected.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const findAccountByUserId = (id?: string): TeamMember | null => {
    const account = teamMembers.find((account) => account.id === id);
    return account || null;
  };

  const account = findAccountByUserId(id);

  console.log(account);
  return (
    <div>
      {" "}
      <div className="bg-[#fff] h-100vh  p-5 md:pr-5 md:pl-8">
        <div className=" mt-5">
          <div className="flex items-center gap-4">
            <div
              //   draggable
              onClick={() =>
                navigate("/dashboard/settings?tab=Role+Management")
              }
              className="rounded-full h-8 w-8 text-[#071827] bg-[#E5F2EA] flex items-center justify-center hover:bg-slate-300 transition-colors duration-300 cursor-pointer"
            >
              <FiChevronLeft size={23} />
            </div>
            <h1 className="text-[#071827] text-[14px] leading-[21px] font-normal">
              Accounts
            </h1>
          </div>
          <div className="flex flex-row items-center justify-between gap-5 my-8">
            <p className="text-[#2b2b2b]  text-[32px] leading-[140%] mr-3 font-semibold">
              {account?.firstName}
              {""} {account?.lastName}
            </p>
            <div className=" flex items-center justify-center gap-3">
              <div className="flex gap-2">
                <button className="flex items-center justify-center p-3 rounded-md bg-[#fff] text-[#0eb683]  active:scale-90 cursor-pointer border border-[#0eb683] transition-all duration-300 ease-in hover:bg-[#f9f4f4]">
                  <AiOutlineEdit size={20} />
                  <span className="ml-1">Edit User</span>
                </button>
                <button className="flex items-center justify-center p-3 rounded-md bg-[#e60606] text-[#fff]  active:scale-90 cursor-pointer border border-[#e60606] transition-all duration-300 ease-in hover:bg-[#e60606ed]">
                  <MdOutlinePersonAddDisabled size={20} />
                  <span className="ml-1">Delete User</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-8">
          <div className="mb-5">
            <h1 className="text-[20] leading-normal text-[#071827] font-medium">
              {" "}
              Account Details
            </h1>
          </div>
          <div className="flex justify-between gap-5">
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Email Address
              </h3>
              <p className="text-[#0EB683] text-[16px] leading-normal font-medium underline">
                {account?.email}
              </p>
            </div>
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Phone Number
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {account?.phoneNumber}
              </p>
            </div>
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Role
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {account?.role}
              </p>
            </div>
            {/* <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Account Creation Date
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {account?.createdAt}
              </p>
            </div> */}
          </div>
        </div>
        <div>
          <div className="flex space-x-6 my-3 ">
            <div className="w-1/2 space-y-3">
              {/* <Checkbox
                label={"Dashboard"}
                value={"dashboard"}
                name={"dashboard"}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label={"Accounts"}
                value={"accounts"}
                name={"accounts"}
                onChange={handleCheckboxChange}
              /> */}
              <Checkbox
                label={"Approvals"}
                value={"approvals"}
                defaultChecked={true}
                bg="#F8F8F8"
                name={"approvals"}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label={"Payouts"}
                value={"payouts"}
                defaultChecked={true}
                bg="#F8F8F8"
                name={"payouts"}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label={"Balances"}
                value={"balances"}
                defaultChecked={true}
                bg="#F8F8F8"
                name={"balances"}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="w-1/2 space-y-3">
              {" "}
              <Checkbox
                label={"Users"}
                value={"users"}
                name={"users"}
                defaultChecked={true}
                bg="#F8F8F8"
              />
              <Checkbox
                label={"Transactions"}
                defaultChecked={true}
                bg="#F8F8F8"
                value={"transactions"}
                name={"transactions"}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label={"Revenue"}
                value={"revenue"}
                name={"revenue"}
                defaultChecked={true}
                bg="#F8F8F8"
                onChange={handleCheckboxChange}
              />
              <Checkbox
                label={"Fees"}
                value={"fees"}
                name={"fees"}
                defaultChecked={true}
                bg="#F8F8F8"
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between gap-5 my-8">
            <p className="text-[#071827]  text-[20px] leading-[30px] mr-3 font-medium">
              Recent Activity
            </p>

            <button className="flex items-center justify-center p-3 rounded-md bg-[#fff] text-[#0eb683]  active:scale-90 cursor-pointer border border-[#0eb683] transition-all duration-300 ease-in hover:bg-[#9ceec9]">
              <RiDownloadCloud2Fill size={20} />
              <span className="ml-1">Download Report</span>
            </button>
          </div>

          {/* <div>
            <AppTable
              tableData={[]}
              tabs={[]}
              //   @ts-ignore
              dColumns={dColumns}
              showFilter={false}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RoleUser;
