import { useState, useEffect, memo } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { usePaginationPages } from "./usePaginationPages";

const Pagination = ({
  gotoPage,
  length,
  pageSize,
  setPageSize,
  pageCount,
}: {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  length: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageCount: any;
}) => {
  const [perPage, setPerPage] = useState(pageSize);
  const { canGo, currentPage, pages, goTo, goNext, goPrev, skipFF, skipBB } =
    usePaginationPages({
      gotoPage,
      length,
      pageSize,
    });

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage, setPageSize]);

  return (
    <div className="m-4 flex items-center justify-between">
      <select
        className="px-1 py-[6px]  text-[#5C6F7F] font-normal text-[12px] leading-[18px] border rounded-md w-20 bg-white"
        value={pageSize}
        onChange={(e) => setPerPage(+e.target.value)}
      >
        {[10, 50, 100].map((pgSize) => (
          <option className="py-2" value={pgSize} key={pgSize}>
            {pgSize} / page
          </option>
        ))}
      </select>
      <div className="flex items-center">
        <button
          onClick={() => {
            gotoPage(0);
            skipBB();
          }}
          disabled={!canGo.previous}
          className="m-1 flex-1 py-1 px-1 border rounded-md cursor-pointer transition-all duration-300 ease-in hover:bg-[#9ceec9]"
        >
          <FiChevronsLeft size={20} className=" text-[#0eb683]" />
        </button>
        <button
          onClick={goPrev}
          disabled={!canGo.previous}
          className="m-1 flex-1 py-1 px-1 border rounded-md cursor-pointer transition-all duration-300 ease-in hover:bg-[#9ceec9]"
        >
          <FiChevronLeft size={19} className=" text-[#0eb683]" />
        </button>
        {pages.map((page, i) => (
          <button
            onClick={() => goTo(page)}
            key={i}
            style={{
              background: currentPage === page ? "#0eb683" : "none",
              color: currentPage === page ? "white" : "#5C6F7F",
            }}
            className="m-1 flex-1 py-1 px-1 border rounded-md cursor-pointer transition-all duration-300 ease-in hover:bg-[#9ceec9] text-sm"
          >
            <span className="px-2">{page}</span>
          </button>
        ))}
        <button
          onClick={goNext}
          disabled={!canGo.next}
          className="m-1 flex-1 py-1 px-1 border rounded-md cursor-pointer transition-all duration-300 ease-in hover:bg-[#9ceec9]"
        >
          <FiChevronRight size={19} className="  text-[#0eb683]" />
        </button>
        <button
          onClick={() => {
            gotoPage(pageCount - 1);

            skipFF(pageCount);
          }}
          disabled={!canGo.next}
          className="m-1 flex-1 py-1 px-1 border rounded-md cursor-pointer transition-all duration-300 ease-in hover:bg-[#9ceec9]"
        >
          <FiChevronsRight size={20} className="  text-[#0eb683]" />
        </button>
      </div>
    </div>
  );
};

export default memo(Pagination);
