    import React from 'react'
    import { FC } from "react"
    import SvgIconConstituentValues from './types'

    export interface SvgIcon extends FC<SvgIconConstituentValues>{}

    const AccountIcon = ({
        height,
        width ,
        stroke,
        ...props
    }:React.SVGProps<SVGAElement>) => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M16.6667 8.5954V5.73825C16.6667 4.68629 15.9206 3.8335 15.0001 3.8335H3.33341C2.41294 3.8335 1.66675 4.68629 1.66675 5.73825V15.2621C1.66675 16.3141 2.41294 17.1668 3.33341 17.1668H15.0001C15.9206 17.1668 16.6667 16.3141 16.6667 15.2621V12.4049V8.5954ZM18.3334 8.5954H13.3334C12.4129 8.5954 11.6667 9.44816 11.6667 10.5002C11.6667 11.5522 12.4129 12.4049 13.3334 12.4049H18.3334V8.5954Z"
            stroke="#5C6F7F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    }

    export default AccountIcon