import React, { Children } from "react";
import { IoMdClose } from "react-icons/io";
import { useLpStore } from "../../../store/useLiquidityPartner";
import AppSelectInput from "../../base-components/AppSelectInput";
interface ModalProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: (arg: boolean) => void;
}
const LpModal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  subtitle,
}) => {
  const overLayRef = React.useRef(null);
  const setLpActive = useLpStore((state) => state.setActiveLp);
  const activeLp = useLpStore((state) => state.activeLp);
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overLayRef.current) {
      onClose(false);
    }
  };
  const toggleLp = () => {
    if (activeLp === false) {
      setLpActive(true);
    } else {
      setLpActive(false);
    }

    onClose(false);
  };

  return (
    <>
      {isOpen ? (
        <div className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50">
          <div
            ref={overLayRef}
            className="modal_overlay absolute top-0 right-0 flex justify-end w-full h-full cursor-pointer bg-[#13111173]"
            onClick={handleOverLayClick}
          >
            <div className="modal_box relative bg-[#fff] h-full w-[430px] right-0 rounded-l-lg">
              <div
                onClick={() => onClose(false)}
                className="close absolute top-1 right-1 flex items-center justify-center rounded-full
                hover:bg-slate-200 p-3 hover:rounded-full hover:text-center "
              >
                <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                  <IoMdClose size={29} className="" />
                </span>
              </div>
              <div className="title pt-16  px-5">
                <h1 className="text-2xl font-semibold text-[#071827]">
                  {" "}
                  {title}
                </h1>
              </div>
              <div className="modal_content p-5">
                <p className="font-semibold text-xl  text-[#5C6F7F]">
                  {subtitle}
                </p>
                <p className="text-[#071827] text-sm mt-3">
                  Last transaction - <span>{}</span>{" "}
                </p>
              </div>
              <div className="Lp-container px-5 mt-10">
                <div className="flex items-center justify-between">
                  <p className="text-[#5C6F7F] font-medium text-sm">Bank :</p>
                  <p className=" text-[#071827]  text-sm">Access Bank Plc</p>
                </div>
                <div className="flex items-center justify-between mt-5 ">
                  <p className="text-[#5C6F7F] font-medium  text-sm">
                    Account Number :{" "}
                  </p>
                  <p className=" text-[#071827]  text-sm">0123456789</p>
                </div>
              </div>
              <div className="w-full px-5 mt-5 absolute bottom-14">
                <div className="w-full  ">
                  <button
                    onClick={toggleLp}
                    className="text-[#fff] border border-[#0eb683] bg-[#0eb683] w-full py-3 rounded-md active:bg-[#56f6d4] active:scale-90 transition-all"
                  >
                   {activeLp ? "Deactivate for fiat transaction" : " Select for fiat transactions"}
                  </button>
                  <button className="bg-[#fff] border border-[#0eb683]  text-[#0eb683] w-full py-3 rounded-md mt-5 hover:bg-[#e10] hover:border-[#e10] hover:text-[#fff] active:scale-90 transition-all">
                    Delete account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LpModal;
