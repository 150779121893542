import React, { useState } from "react";
import ToggleSwitch from "../ToggleSwitch";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useFnx } from "../../../store";
import { useUpdateFunctionality } from "../../../sevices/hooks/admin";

const SettingsData = ({
  description,
  subtext,
  showEdit,
  DeleteModal,
  setShowEdit,
  id,
  fnx,
  status,
}: {
  description: any;
  subtext: any;
  showEdit?: boolean;
  DeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  fnx: any;
  status: boolean;
}) => {
  const [switchToggle, setSwitchToggle] = useState(status);
  const { setFunctionalityId, setFunction, functionalityId } = useFnx();
  const [loading, setLoading] = useState(false);
  const updateFnx = useUpdateFunctionality(functionalityId);

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    setFunctionalityId(id);
    const status = !switchToggle;

    setTimeout(() => {
      updateFnx
        .mutateAsync({
          status: status,
        })
        .then((res) => {
          setSwitchToggle(status);
          setLoading(false);
          console.log(res);
        })
        .catch((e) => {
          setLoading(false);
        });
    }, 3000);
  };

  return (
    <div className="flex justify-between border-b ">
      <div className="">
        <div className="desc text-[#2B2B2B] text-base font-medium">
          {description}
        </div>
        <div className="subtext text-[#676767] text-xs  font-normal">
          {subtext}
        </div>
      </div>
      <div className="flex items-center gap-4 ">
        {loading ? (
          <svg
            className="animate-spin h-6 w-16 text-center text-[#0D9A6F]"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="black"
            />
            <path
              d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
              fill="#0D9A6F"
            />
          </svg>
        ) : (
          <ToggleSwitch
            value={switchToggle}
            onChange={(e) => handleSubmit(e)}
            label={""}
            isLoading={false}
          />
        )}
        {showEdit && (
          <div
            className="flex items-center gap-4"
            onClick={() => {
              setFunction(fnx);
              setFunctionalityId(id);
              setShowEdit(true);
            }}
          >
            <div className=" cursor-pointer text-[#0eb683]">
              <FiEdit size={20} />
            </div>
            <div
              onClick={() => {
                setFunctionalityId(id);
                DeleteModal(true);
              }}
              className=" cursor-pointer text-[#e60606]"
            >
              <RiDeleteBinLine size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsData;
