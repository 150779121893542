import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const FeesIcon = ({
    height = "28px",
    width ="28px",
    stroke ="#0EB683",
    ...props

}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M16.9184 9.87858C16.5538 9.61879 16.0867 9.5562 15.6691 9.71079L11.3259 11.3193C11.3206 11.2932 11.3146 11.2672 11.3079 11.2414C11.2102 10.8668 10.9588 10.5524 10.6184 10.379L7.14337 8.60957C7.10404 8.58958 7.06065 8.57911 7.01664 8.57911H3.89997V8.29053C3.89997 8.13119 3.77306 8.00195 3.61659 8.00195H0.5V8.57902H3.3333V14.3498H0.5V14.9268H3.61668C3.77315 14.9268 3.90005 14.7976 3.90005 14.6382V14.3497H4.99387L8.92246 15.489C8.94787 15.4963 8.97407 15.5 9.00009 15.5C9.037 15.5 9.07383 15.4927 9.10823 15.4782L16.6567 12.3008C17.169 12.0851 17.5001 11.5811 17.5001 11.0166C17.5001 10.5638 17.2826 10.1383 16.9185 9.87841L16.9184 9.87858ZM16.4402 11.7676L8.98288 14.9068L5.111 13.7839C5.08567 13.7766 5.05965 13.7729 5.03337 13.7729H3.90005V9.15635H6.94991L10.365 10.8953C10.5602 10.9946 10.7043 11.1748 10.7604 11.3897C10.8163 11.6044 10.7791 11.8336 10.6581 12.0186C10.4565 12.326 10.0668 12.4461 9.73195 12.3042L7.12583 11.1984L6.90781 11.7309L9.51401 12.8367C10.0984 13.085 10.778 12.8754 11.1296 12.3387C11.2105 12.2153 11.2698 12.0808 11.3068 11.9404L15.863 10.2533C16.1114 10.1618 16.3774 10.1974 16.5935 10.3514C16.8096 10.5057 16.9336 10.7482 16.9336 11.017C16.9336 11.3471 16.74 11.6416 16.4405 11.7677L16.4402 11.7676Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
      <path
        d="M2.76661 9.15625H2.19995V9.73332H2.76661V9.15625Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
      <path
        d="M12.1167 9.15607C14.4601 9.15607 16.3667 7.21456 16.3667 4.82803C16.3667 2.44151 14.4601 0.5 12.1167 0.5C9.77329 0.5 7.8667 2.44151 7.8667 4.82803C7.8667 7.21456 9.77321 9.15607 12.1167 9.15607ZM12.1167 1.07707C14.1478 1.07707 15.8 2.75967 15.8 4.82803C15.8 6.8964 14.1478 8.579 12.1167 8.579C10.0856 8.579 8.43336 6.8964 8.43336 4.82803C8.43336 2.75967 10.0856 1.07707 12.1167 1.07707Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
      <path
        d="M12.3999 6.2709H11.8333C11.5208 6.2709 11.2666 6.01208 11.2666 5.69384H10.7C10.7 6.33024 11.2083 6.84797 11.8333 6.84797V7.42503H12.3999V6.84797C13.0249 6.84797 13.5333 6.33024 13.5333 5.69384C13.5333 5.05744 13.0249 4.53971 12.3999 4.53971H11.8333C11.5208 4.53971 11.2666 4.28089 11.2666 3.96264C11.2666 3.6444 11.5208 3.38558 11.8333 3.38558H12.3999C12.7124 3.38558 12.9666 3.6444 12.9666 3.96264H13.5333C13.5333 3.32624 13.0249 2.80851 12.3999 2.80851V2.23145H11.8333V2.80851C11.2083 2.80851 10.7 3.32624 10.7 3.96264C10.7 4.59904 11.2083 5.11677 11.8333 5.11677H12.3999C12.7124 5.11677 12.9666 5.37559 12.9666 5.69384C12.9666 6.01208 12.7124 6.2709 12.3999 6.2709Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
      <path
        d="M15.2332 4.53955H14.6665V5.11662H15.2332V4.53955Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
      <path
        d="M9.56666 4.53955H9V5.11662H9.56666V4.53955Z"
        fill="#5C6F7F"
        stroke="#5C6F7F"
        stroke-width="0.5"
      />
    </svg>
  );
}

export default FeesIcon