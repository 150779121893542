import React, { InputHTMLAttributes } from "react";



interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type,
  required,
  disabled,
  value,
}) => {



  return (
    <div className="relative z-0 w-full ">
      <input
        // {...rest}
        id={name}
        type={type}
        disabled={disabled}
        placeholder=" "
        value={value}
        required={required}
        className="pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-[#F2FAF7] border appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] border-gray-200 rounded-[4px]"
      />
      <label
        htmlFor={name}
        className="absolute duration-300 top-3 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3"
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
    </div>
  );
};

export default Input;
