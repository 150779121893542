import React from 'react'
import {FC} from "react"
import SvgIconConstituentValues from './types'
import { AnimatePresence, motion } from "framer-motion"

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const SuccessMobile = ({
    height ="93px",
    width ="93px",
    // onAnimationStart,
    ...props
}:React.SVGProps<SVGAElement>) => {
      
    const svgVariants ={
      hidden: {rotate: -180 },
      visible: {
        rotate: 0,
        transition: {duration: 1}
      }
    }

    const pathVariants={
      hidden:{
        opacity: 0,
        pathLength: 0
      },
      visible:{
      opacity:1,
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut"
      }
      }
    }

  return (
    <AnimatePresence >
    <motion.svg
    variants={svgVariants}
    xmlns="http://www.w3.org/2000/svg" width="93" height="91" viewBox="0 0 93 91" fill="none">
<motion.path
variants={pathVariants}
d="M22.3563 44.5678L43.28 58.2678C45.0508 59.4273 47.4183 59.0065 48.6812 57.3079L87.1334 5.58691" stroke="#0EB683" strokeWidth="10" strokeLinecap="round"/>
<motion.path
variants={pathVariants}
fillRule="evenodd" clipRule="evenodd" d="M63.9659 10.2674C65.398 8.29235 64.7779 5.49402 62.5314 4.54281C57.1436 2.26159 51.2191 1 45 1C20.1472 1 0 21.1472 0 46C0 70.8528 20.1472 91 45 91C69.8528 91 90 70.8528 90 46C90 44.7512 89.9491 43.5143 89.8494 42.2913C89.5457 38.5687 85.0013 37.6928 82.8088 40.7165C82.158 41.614 81.8731 42.7197 81.9372 43.8266C81.9789 44.5457 82 45.2704 82 46C82 66.4345 65.4345 83 45 83C24.5655 83 8 66.4345 8 46C8 25.5655 24.5655 9 45 9C49.8605 9 54.5022 9.93722 58.7541 11.6408C60.6138 12.3859 62.7898 11.8893 63.9659 10.2674Z" fill="#00432F"/>
</motion.svg>
</AnimatePresence>
  )
}

export default SuccessMobile