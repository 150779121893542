import React, { useContext, useEffect, useRef, useState } from "react";
// import { StepLayoutProps } from "../../pages/Authentication/CreateSellersAcc";
import { VscChevronRight } from "react-icons/vsc";
// import {
//   SellersStepsContext,
//   SellersStepsContextValue,
// } from "../../context/SellersStepsContext";

type Step = {
  description: string;
  completed: boolean;
  highlighted: boolean;
  selected: boolean;
};

const RoleStepper = ({
  checkoutSteps,
  currentStep,
}: {
  checkoutSteps: string[];
  currentStep: number;
}) => {
  const [newStep, setNewStep] = useState<Step[]>([]);
  const stepRef = useRef<Step[]>([]);
  console.log(typeof checkoutSteps);
  console.log(checkoutSteps, "kkkkii");

  const updateStep = (stepNumber: number, checkoutSteps: any) => {
    const newSteps = [...checkoutSteps];
    let count = 0;

    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };
  useEffect(() => {
    const stepsState = checkoutSteps?.map((step: any, index: number) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );

    stepRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepRef.current);

    setNewStep(current);
  }, [checkoutSteps, currentStep]);
  return (
    <div className="py-8 md:flex  items-center font-semibold gap-5 xxs:hidden">
      {newStep?.map((step, index) => (
        <div
          key={index}
          className={
            index !== newStep.length - 1
              ? " flex items-center"
              : "flex items-center"
          }
        >
          <div className="relative flex  items-center gap-3">
            <div
              className={` transition duration-500 ease-in-out rounded-full  h-8 w-8  flex items-center justify-center py-3 ${
                step.selected
                  ? "text-[#fff] bg-[#0EB683]"
                  : "opacity-90  bg-[#E5F2EA] text-[#0EB683]"
              }`}
            >
              {step.completed ? index + 1 : index + 1}
            </div>
            <div
              className={`text-center ${
                step.completed
                  ? "text-[#0EB683] font-medium "
                  : "text-[#071827]"
              } text-[16px]  font-normal  ${
                step.highlighted ? "" : " opacity-70"
              }`}
            >
              {step.description}
            </div>
            <div
              className={`flex-auto  transition duration-500 ease-in-out ${
                step.completed ? "text-[#0EB683]" : "text-[#E5F2EA]"
              }`}
            >
              <span className="">
                <VscChevronRight size={25} />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RoleStepper;
