import React, { useState } from "react";
import Input from "../../../Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";


const validationSchema = Yup.object({
  firstname: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  lastname: Yup.string().required("lastname is required"),
});

const UserDetails = () => {
  return (
    <div>
      <div>
        <h1 className="text-[20px] font-semibold text-[#071827]">
          {`User Details`}
        </h1>
        <p className="text-[#5C6F7F] text-[16px] leading-normal mt-3">{`Provide the following information to set up your user’s profile`}</p>
      </div>
      <div className="mt-10">
        <MyForm />
      </div>
    </div>
  );
};

export default UserDetails;

const MyForm: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      lastname: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex space-x-6">
      <div className="w-1/2">
        <div className="relative">
          <Input
            label="First Name"
            name="firstname"
            type="text"
            required={true}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* Add any validation logic here */}
        </div>
        <div className="relative mt-6">
          <Input
            label="Email"
            name="email"
            type="text"
            required={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* Add any validation logic here */}
        </div>
      </div>
      <div className="w-1/2">
        <div className="relative">
          <Input
            label="Last Name"
            name="lastname"
            type="text"
            required={true}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* Add any validation logic here */}
        </div>
        <div className="relative mt-6">
          <PhoneInput
            disabled={false}
            country={"ng"}
            onChange={() => {}}
            placeholder={""}
            inputStyle={{
              width: "100%",
              height: "50px",
              //   paddingLeft: "10px",
              //   paddingRight: "2.5px",
              //   marginTop: "0",
              backgroundColor: "#F2FAF7",
              border: "1px solid #D1D5DB",
              //   outline: "none",
              //   borderRadius: "4px",
              //   borderColor: "#D1D5DB",
              //   ":placeholder": {
              //     color: "#A0AEC0",
              //   },
              //   ":focus": {
              //     outlineColor: "#0eb683",
              //     boxShadow: "none",
              //     border: "1px solid #0eb683",
              //   },
            }}
          />
        </div>
      </div>
    </form>
  );
};
