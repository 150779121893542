import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({ filter, setFilter, placeholder }: any) => {
  const [value, setValue] = useState(filter);
  const handleChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <div className="flex items-center justify-between gap-4 py-4  relative ">
      <input
        type="search"
        value={value || ""}
        placeholder={placeholder}
        className="h-12 w-full  focus:outline-none active:outline-none rounded-md px-2 pl-8 placeholder:text-sm bg-[#E5F2EA]"
        onChange={(e) => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
      />

      <div className="absolute text-[#0EB683] left-[14px] ">
        <IoSearch />
      </div>
    </div>
  );
};

export default GlobalFilter;
