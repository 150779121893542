
import useQueryAction from "../../../lib/useQueryAction";

import { api, makeGetRequest } from "../../api";




export const useRates = ()=>{
    return useQueryAction({
        queryFn: () => makeGetRequest(api.tags.rates),
        queryKey: ['rates']
    })
}

export const useRatesEur = () =>{
    return useQueryAction({
        queryFn: () => makeGetRequest(api.tags.ratesEur),
        queryKey: ['rates','EUR']
    })
}

export const useGbpRates = () =>{
    return useQueryAction({
        queryFn: () =>makeGetRequest(api.tags.gbpRates),
        queryKey: ['rates','GBP']
    })
}

// export const useGetCountry = ()=>{
//     return useQueryAction({
//         queryFn: ()=> makeGetRequest(api.tags.countries),
//         queryKey:['getCountry']
//     })
// }

export const useStates = ()=>{
    return useQueryAction({
        queryFn: ()=> makeGetRequest(api.tags.states),
        queryKey:['states']
    })
}