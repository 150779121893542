import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Tune from "../../assets/iconsComponents/Tune";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { useCheckEmail, useUsersInvitation } from "../../sevices/hooks/users";
import { useSidebarState } from "../../store";
import ReactLoading from "react-loading";

const InviteUsers = ({ data }: any) => {
  const invitationModalOpen = useSidebarState(
    (state) => state.invitationModalOpen
  );
  const setInvitationModalOpen = useSidebarState(
    (state) => state.setInvitationModalOpen
  );

  const usersInvitation = useUsersInvitation();
  // const usdRate = useRatesState((state) => state.usdRate);
  // const eurRate = useRatesState((state) => state.eurRate);
  // const gbpRate = useRatesState((state) => state.gbpRate);
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [invitationFailed, setInvitationFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailExist, setEmailExist] = useState(false);

  //ivitation validation
  const userDetails = data;
  const checkEmail = useCheckEmail();

  const [disabled, setDisabled] = useState(true);

  const [fields, setFields] = useState({
    email: "",
    lastName: "",
    firstName: "",
  });

  // const validateEmail = () => {
  //   checkEmail
  //     .mutateAsync({ email: fields.email })
  //     .then((res) => {
  //       if (res.data === true) {
  //         setEmailExist(res.data);
  //       }
  //     })
  //     .catch((e) => {});
  // };

  const inviteUser = (e: any) => {
    setLoading(true);
    e.preventDefault();    
      usersInvitation
        .mutateAsync(fields)
        .then((res: any) => {
          setLoading(false);
          if (res.status === 200) {
            setInvitationSuccess(true);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          if (e) {
            setErrorMessage(e?.response?.data);
            setLoading(false);
            setInvitationFailed(true);
          }
        });
    
  };

  const onFieldChange = ({ name, value }: { value: any; name: string }) => {
    setFields({ ...fields, [name]: value });
  };

  const validateField = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (
      regEx.test(fields.email) &&
      fields.firstName !== "" &&
      fields.lastName !== ""
    ) {
      setDisabled(false);
    } else if (
      (!regEx.test(fields.email) && fields.email === "") ||
      (fields.email === "" && fields.firstName === "" && fields.lastName)
    ) {
      setDisabled(true);
    }
  };

  return (
    <>
      <AnimatePresence>
        {invitationModalOpen && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal_backdrop backdrop-blur-sm bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          >
            <motion.div
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex flex-col h-screen items-center justify-center md:items-end"
            >
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="card bg-white md:h-full shrink-0 w-full max-w-sm   md:rounded-l-3xl p-8 flex flex-col gap-3 md:max-w-md "
              >
                <button
                  onClick={() => setInvitationModalOpen(false)}
                  className=" hover:bg-[#F2FAF7] rounded-full p-2  self-end text-[#1E3448] transition-all active:scale-90"
                >
                  <AiOutlineClose size={28} />
                </button>
                <h2 className="text-xl font-semibold">Invite new users</h2>
                <p className=" text-[#5C6F7F] text-sm font-medium whitespace-normal ">
                  Enter your referee’s contact details and an invitation would
                  be sent to them. Any{" "}
                  <span className="font-extrabold text-md underline">BUY</span>{" "}
                  transaction your referee performs earns you up to
                  <span className="text-[#0eb683] font-bold">
                    {" "}
                    NGN1.00/$,£,€ commission
                  </span>
                </p>
                <p className="text-center text-black font-medium">
                  Your Referral Code:
                </p>
                <p className="ref text-black font-bold text-center text-3xl ">
                  {userDetails?.invitationCode}
                </p>
                <div className=" flex flex-col items-center w-full gap-3 mt-5">
                  <input
                    type="text"
                    name=""
                    // value={firstname}
                    className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3 rounded-md outline-[#0eb683]"
                    placeholder="First Name"
                    onChange={(e) => {
                      onFieldChange({
                        name: "firstName",
                        value: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    name=""
                    // value={lastname}
                    className="border bg-[#F2FAF7] border-[#E2E9F0] w-full p-3 rounded-md outline-[#0eb683]"
                    placeholder="Last Name"
                    onChange={(e) => {
                      onFieldChange({
                        name: "lastName",
                        value: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="email"
                    name="email"
                    value={fields.email}
                    className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3  outline-[#0eb683] rounded-md"
                    placeholder="Email"
                    onPaste={validateField}
                    onChange={(e) => {
                      onFieldChange({ name: "email", value: e.target.value });
                      validateField();
                    }}
                  />
               
                  <button
                    disabled={disabled}
                    onClick={inviteUser}
                    className="text-white w-full bg-[#0eb683] rounded-md px-10 py-3 mt-20 transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  >
                    {loading ? (
                      <div className="   xxs:ml-24 md:ml-36">
                        <ReactLoading
                          type="spinningBubbles"
                          color="#fff"
                          height="27px"
                          width="27px"
                        />
                      </div>
                    ) : (
                      " Invite new user"
                    )}
                  </button>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {invitationSuccess && (
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal backdrop-blur-sm 
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5  cursor-pointer"
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Invitation sent
                  </h1>

                  <p className="  text-sm text-[#5C6F7F] self-center text-center">
                    Your invitation was succesfully sent
                  </p>

                  <button
                    onClick={() => {
                      setInvitationSuccess(false);
                      setInvitationModalOpen(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {invitationFailed && (
          <div>
            <motion.div
              // ref={modalRef}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal backdrop-blur-sm 
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                >
                  <div className="icon self-center">
                    <Tune width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Invitation failed
                  </h1>

                  <p className="  text-sm text-[#ED0000] self-center text-center">
                    {errorMessage ||
                      "We couldn't invite your user please retry"}
                  </p>

                  <button
                    onClick={() => {
                      setInvitationFailed(false);
                      setInvitationModalOpen(true);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Retry
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default InviteUsers;
