import React from "react";

const ComplianceIcon = ({
  height = "24px",
  width = "24px",
  stroke = "#5C6F7F",
  ...props
}: React.SVGProps<SVGAElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.5 10.8332V3.51835L4.11977 3.39337C5.53092 3.28449 6.91574 2.95161 8.22222 2.40725L10 1.6665L11.7777 2.40725C13.0842 2.95161 14.4691 3.28449 15.8802 3.39337L17.5 3.51835V10.8332C17.5 14.9753 14.1422 18.3332 10 18.3332C5.85787 18.3332 2.5 14.9753 2.5 10.8332Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66675 10L8.75008 12.0833L13.3334 7.5"
        stroke="#5C6F7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ComplianceIcon;
