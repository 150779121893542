import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useEffect, useState } from "react";
import OTPInput from "../../components/dashboard-components/widgets/OTPInput";
import { useUserLogin, useVerifyUserMutation } from "../../sevices/hooks/users";
import ReactLoading from "react-loading";
const validation = (values: { email?: string; password?: string } = {}) => {
  let errors = {
    email: "",
    password: "",
  };

  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = "Email is invalid.";
  }

  // if (!values.password) {
  //   errors.password = "Pasword is requied";
  // } else if (values.password.length < 5) {
  //   errors.password = " Password must be more than five characters.";
  // }
  return errors;
};

const Login = () => {
  const [State, setState] = useState(false);
  const [detailsValid, setDetailsValid] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [email, setEmail] = useState("");
  const [values, setvalues] = useState({
    email: "",
    password: "",
  });
  let [passwordErr, setPasswordErr] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  // const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const userLogin = useUserLogin();
  const verifyUser = useVerifyUserMutation();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChange = (e: any) => {
    setvalues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const [spin, setSpin] = useState(false);

  const onSubmitForm = (e: any) => {
    setSpin(true);
    userLogin
      .mutateAsync({ verificationCode: otpValue, email: email })
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/dashboard", {
            state: {
              userId: res?.data?.data?.id,
              token: res?.data?.token,
            },
          });
          localStorage.removeItem("accessToken");
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("user_id", res.data.data.id);
        }
      })
      .catch((e) => {
        setSpin(false);
      });
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    const validate = validation(values);
    setLoading(true);
    setErrors(validate);
    if (!validate.email) {
      verifyUser
        .mutateAsync(values)
        .then((response) => {
          setEmail(response.data.data.email);
          swapScreen();
        })
        .catch((e) => {
          setLoading(false);
          if (e.response?.data) {
            passwordErr = e.response?.data;
            setPasswordErr(passwordErr);
          }
        });
    }
  };

  useEffect(() => {
    if (values.email) {
      setErrors(validation(values));
    } else {
      setErrors({ email: "", password: "" });
    }
  }, [!values.email]);

  const swapScreen = () => {
    setDetailsValid(true);
  };

  const toggleBtn = (e: any) => {
    e.preventDefault();
    setState((prevState) => !prevState);
  };

  // const termsOfUse = ()=>{
  //   window.open(terms)
  // }

  return !detailsValid ? (
    <div className="md:mt-4">
      <form
        onSubmit={handleFormSubmit}
        className="input-wrap pt-4 flex flex-col relative gap-4 w-full"
      >
        <input
          type="Email"
          value={values.email}
          name="email"
          placeholder="Email"
          className="border border-[#E2E9F0] block p-4 w-full  outline-[#0eb683] rounded-md "
          onChange={handleChange}
        />
        {errors.email && (
          <small className="error font-semibold block text-red-700 absolute top-[4.7rem]">
            {errors.email}
          </small>
        )}
        <div className="flex mt-3 relative ">
          <input
            type={State ? "text" : "Password"}
            onChange={handleChange}
            name="password"
            placeholder="Password"
            className=" border  border-[#E2E9F0]  inline-block p-4 w-full  outline-[#0eb683] rounded-md "
          />
          <small className="error font-semibold block text-red-700 absolute xxs:top-16 md:bottom-[-20px]">
            {passwordErr}
          </small>

          <button
            className="outline-[#0eb683] rounded-r-md text-center text-gray-500 absolute right-0 pt-4 pr-5"
            onClick={toggleBtn}
          >
            {State ? <FiEye size={20} /> : <FiEyeOff size={20} />}
          </button>
        </div>
        <Link
          to={"/forgotPassword"}
          className="text-[#0Eb683] text-[12px] self-end py-4 xxs:mt-5"
        >
          Forgot password?
        </Link>

        <button
          type="submit"
          disabled={!!validation(values).email}
          className="bg-[#0eb683] p-3 xxs:mt-3  md:mt-0 w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
        >
          {loading ? (
            <div className=" xxs:ml-[145px]  md:ml-[194px]">
              <ReactLoading
                type="spinningBubbles"
                color="#fff"
                height="27px"
                width="27px"
              />
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </form>
      <div className="text-center">
        <small className="text-white md:text-[#000] text-center md:mt-2 ">
          By clicking Continue, I agree to the website's
          <br />
          <button
            onClick={() => navigate("/terms-of-use", { replace: true })}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Terms of use
          </button>{" "}
          and{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/privacystatement", { replace: true });
            }}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90 "
          >
            Privacy statement
          </button>{" "}
        </small>
      </div>
    </div>
  ) : (
    <div className="otp-box py-6 flex flex-col ">
      <p className="text-center text-white md:text-[#000] py-3">
        Enter the 5-digit verification code sent to your email
      </p>
      <OTPInput
        autoFocus
        length={5}
        className="otpContainer flex justify-between xxs:gap-2 w-full py-3 px-14 xxs:px-8"
        inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] xxs:flex xxs:gap-2 outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
        autoCapitalize={true}
        onChangeOTP={(otp) => setOtpValue(otp.toUpperCase())}
        onPaste={(otp) => setOtpValue(otp.toUpperCase())}
      />
      <p className="text-center text-white md:text-[#000] py-3">
        You can <span style={{ color: "#0EB683" }}>copy</span> and{" "}
        <span style={{ color: "#0EB683" }}>paste</span> the code above
      </p>
      <button
        onClick={onSubmitForm}
        className=" bg-[#0eb683] p-3 mt-5 w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90"
      >
        {spin ? (
          <div className="xxs:ml-[140px] md:ml-[190px]">
            <ReactLoading
              type="spinningBubbles"
              color="#fff"
              height="27px"
              width="27px"
            />
          </div>
        ) : (
          " Login"
        )}
      </button>
      <small className="text-white md:text-[#000] text-center pt-3">
        By clicking login, I agree to the website's
        <br />
        <button
          onClick={() => navigate("/terms-of-use", { replace: true })}
          className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
        >
          Terms of use
        </button>{" "}
        and{" "}
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacystatement", { replace: true });
          }}
          className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
        >
          Privacy statement
        </button>{" "}
      </small>
    </div>
  );
};

export default Login;
