import create from "zustand";

interface ILiquidityPartners {
  showLp: boolean;
  Lp: any[];
  selectedLpId: String;
  activeLp: boolean;

  setShowLp: (shouldShow: boolean) => void;
  setLp: (data: any) => void;
  setSelectedLpId: (id: string) => void;
  setActiveLp: (isActive: boolean) => void;
}

export const useLpStore = create<ILiquidityPartners>((set) => ({
  showLp: false,
  Lp: [],
  selectedLpId: "",
  activeLp: false,

  //setters
  setShowLp: (shouldShow: boolean) => set((state) => ({ showLp: shouldShow })),
  setLp: (data: any) => set((state) => ({ Lp: data })),
  setSelectedLpId: (id: string) => set((state) => ({ selectedLpId: id })),
  setActiveLp: (isActive: boolean) => set((state) => ({ activeLp: isActive })),
}));
