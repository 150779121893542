import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useFeesState } from "../../../store/useFees";
import {
  useDeleteFees,
  useFeeId,
  usePatchFees,
} from "../../../sevices/hooks/admin";
import DeleteFessPopup from "./DeleteFeesPopup";

// export interface Value {
//     amount: string;
//     baseValue?: number;
//     currency?: string;
//   };

export interface IPatch {
  type: string;
  description: string;
  value: {
    amount: string;
    baseValue?: string;
    currency?: string;
  };
}

const FeesAccordion = ({
  title,
  Val,
  subtext,
  setActive,
  active,
  id,
}: {
  title: string;
  Val: any;
  subtext: any;
  setActive: (arg: string) => any;
  active: string;
  id: string;
}) => {
  const [age, setAge] = React.useState("");
  const selectedFeeId = useFeesState(
    (state: { selectedFeesId: any }) => state.selectedFeesId
  );

  const feeId = useFeesState((state: { feeId: any }) => state.feeId);
  const [deleteFee, setDeleteFee] = React.useState(false);
  const patchFees = usePatchFees(feeId);
  const setFeesId = useFeesState((state) => state.setFeeId);
  const [type, setFeeType] = React.useState("");
  const [description, setFeeDescription] = React.useState("");
  // const [feeAmount, setFeeAmount] = React.useState("");
  const [value, setFeeAmount] = React.useState({
    amount: "",
    baseValue: "",
    currency: "",
  });
  const getFeeId = useFeeId(id);
  const handleFeeTypeChange = (event: SelectChangeEvent) => {
    setFeeType(event.target.value);
  };

  const handleFeeDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFeeDescription(event.target.value);
  };

  const handleFeeAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFeeAmount({ ...value, amount: event.target.value });
  };

  const initiateDelete = () => {
    setDeleteFee((prev) => !prev);
  };

  const feeData: IPatch = {
    type,
    description,
    //@ts-ignore
    value: {
      ...value,
    },
  };
  const handleSubmit = () => {
    patchFees
      .mutateAsync(feeData)
      .then((res) => {
        setActive("");
      })
      .catch((e) => {});
  };
  // console.log(feeData);
  console.log({ getFeeId }, "test");

  const show = "flex";
  const dontShow = "hidden";
  return (
    <div className=" bg-[#F7FBFB] rounded-md w-full md:max-w-2xl h-fit px-5 py-3 flex flex-col mb-4">
      <div className="flex items-center justify-between mb-2">
        <h1 className="text-[#2B2B2B] font-medium text-xl">{title}</h1>
        <button
          onClick={() => {
            setFeesId(id);
            setActive(title);
            if (active === title) {
              setActive("");
            }
          }}
          className="text-[#2B2B2B] text-xs border border-[#0eb683] bg-[#fff] font-medium px-3 py-0.5 rounded-sm transition duration-500 active:scale-90"
        >
          {active === title ? "X" : "Edit"}
        </button>
      </div>
      {active !== title && (
        <div className="flex flex-col gap-1">
          <h3 className="text-[#676767] text-xs font-normal capitalize">
            {subtext}
          </h3>
          <p className="text-[#071827] text-sm font-medium">{Val}</p>
        </div>
      )}

      <div
        className={
          (active === title ? show : dontShow) +
          "   transition-all duration-700 ease-in-out"
        }
      >
        <FormControl sx={{ my: 1, minWidth: 456 }} size="small">
          <InputLabel id="demo-select">Fees Type</InputLabel>
          <Select
            labelId="demo-select"
            id="demo-select"
            label="Fixed Amount"
            className=" bg-[#FFFFFF] "
            value={type}
            onChange={handleFeeTypeChange}
            defaultValue={getFeeId?.data?.data?.type}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"percentage"}> percentage</MenuItem>
            <MenuItem value={"fixed"}>fixed</MenuItem>
            <MenuItem value={"proportional"}>proportional</MenuItem>
          </Select>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { my: 1, minWidth: 456 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Enter Fees Description"
              variant="outlined"
              defaultValue={""}
              value={description}
              onChange={handleFeeDescriptionChange}
              className="border border-[#0eb683] bg-[#FFFFFF]"
              size="small"
            />
          </Box>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { my: 1, minWidth: 456 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Enter the amount you are charging"
              variant="outlined"
              value={value.amount}
              onChange={handleFeeAmountChange}
              className="border border-[#0eb683] bg-[#FFFFFF]"
              size="small"
            />
          </Box>
          <div>
            <button
              onClick={() => setDeleteFee(true)}
              className=" border border-[#fb2626] bg-[#fb2626] px-5 py-2 rounded-md transition duration-500 active:scale-90 text-[#fff] font-medium text-base"
            >
              Delete
            </button>
            <button
              onClick={handleSubmit}
              className="text-[#fff] border border-[#0eb683] bg-[#0eb683] px-7 py-2 rounded-md transition duration-500 active:scale-90 ml-6 font-medium text-base"
            >
              Update
            </button>
          </div>
        </FormControl>
      </div>
      {deleteFee && (
        <DeleteFessPopup
          id={feeId}
          isOpen={deleteFee}
          onClose={initiateDelete}
        />
      )}
    </div>
  );
};

export default FeesAccordion;
