import create from "zustand";

interface IUsers {
  showUserDetails: boolean;
  users: any[];
  selectedUsersId: string;

  setShowUserDetails: (shouldShow: boolean) => void;
  setUsers: (data: any) => void;
  setSelectedUsersId: (id: string) => void;
}

export const useUserStore = create<IUsers>((set) => ({
  showUserDetails: false,
  users: [],
  selectedUsersId: "",

  //setters
  setShowUserDetails: (shouldShow: boolean) =>
    set((state) => ({ showUserDetails: shouldShow })),
  setUsers: (data: any) => set((state) => ({ users: data })),
  setSelectedUsersId: (id: string) => set((state) => ({ selectedUsersId: id })),
}));

