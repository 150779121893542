import React, { Children } from "react";
import { IoMdClose } from "react-icons/io";
import AppSelectInput from "../../base-components/AppSelectInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
interface ModalProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
}
const AddLpModal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  subtitle,
}) => {
  const [age, setAge] = React.useState("");
  const overLayRef = React.useRef(null);
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overLayRef.current) {
      onClose();
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  return (
    <>
      {isOpen ? (
        <div className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50">
          <div
            ref={overLayRef}
            className="modal_overlay absolute top-0 right-0 flex justify-end w-full h-full cursor-pointer bg-[#13111173]"
            onClick={handleOverLayClick}
          >
            <div className="modal_box relative bg-[#fff] h-full w-[430px] right-0 rounded-l-lg">
              <div
                onClick={onClose}
                className="close absolute top-1 right-1 flex items-center justify-center rounded-full
                hover:bg-slate-200 p-3 hover:rounded-full hover:text-center "
              >
                <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                  <IoMdClose size={29} className="" />
                </span>
              </div>
              <div className="title pt-20 px-5">
                <h1 className="text-2xl font-semibold text-[#071827]">
                  {" "}
                  {title}
                </h1>
                <p className="text-base font-medium text-[#5C6F7F]">
                  {subtitle}
                </p>
              </div>
              <div className="modal_content mx-5 relative h-full">
                <FormControl sx={{ my: 1, minWidth: 20 }} size="small" className=" ">

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { my: 1, minWidth: 400 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Account Name"
                      variant="outlined"
                      onChange={() => {}}
                      className="border border-[#0eb683] bg-[#FFFFFF] "
                      size="small"
                    />
                  </Box>
                  {/* <InputLabel id="demo-select">Fixed Amount</InputLabel> */}
                  <Select
                    labelId="demo-select"
                    id="demo-select"
                    value={age}
                    label="Fixed Amount"
                    className="border border-[#0eb683] active:border-[#0eb683] bg-[#FFFFFF] "
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { my: 1, minWidth: 400 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Account Number"
                      variant="outlined"
                      onChange={() => {}}
                      className="border border-[#0eb683] bg-[#FFFFFF] "
                      size="small"
                    />
                  </Box>

                </FormControl>
                <div className="w-full  absolute bottom-52">
                    <div className="w-full  ">

                      <button className="text-[#fff] bg-[#0eb683]  w-full py-3 rounded-md  active:scale-90 transition-all">
                        Save
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddLpModal;

