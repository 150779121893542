/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";

// import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import hamburger from "../../assets/misc/hamburger-button.svg";
import BGImg from "../../assets/images/landing/bg.png";
import Logo from "../../assets/misc/logo-on-light.svg";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const LandingPageHeader = () => {
  // Call to action text
  const [ctaText] = useState([
    { text: "Delivering ### cross-border payments", keyword: "trusted" },
    {
      // text: "Swap your USDC & USDT Receive local currency at the best rates ###",
      // keyword: "instantly",
      text: "Swap local fiat ### receive foreign ###",
      keyword: "settlement",
    },
  ]);

  // State to track the current CTA
  const [currentCtaIndex, changeCtaIndex] = useState(0);

  // Function to move to next CTA
  const next = () => {
    changeCtaIndex(currentCtaIndex + 1);
    if (currentCtaIndex > 0) changeCtaIndex(0);
  };

  // Function to render CTA based on the current CTA index
  const renderCtaText = (index: number) => {
    let cta = ctaText[index];

    let splittedText = cta.text.split("###");
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    };

    return (
      <motion.div
        initial={{ y: "50%" }}
        animate={{ y: "0%" }}
        className="max-w-[630px] w-full relative "
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="h-80 flex items-end pb-10 "
        >
          <motion.h1 className="text-4xl md:text-5xl font-bold absolute ">
            {splittedText[0]}{" "}
            <span className="text-[#0EB683]">{cta.keyword}</span>
            <br /> {splittedText[1]}
          </motion.h1>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="indicators flex gap-4 my-2 absolute bottom-1"
        >
          {ctaText.map((_, currentIndex) => (
            <motion.div
              key={currentIndex}
              initial="hidden"
              animate="visible"
              variants={variants}
              className={`indicator w-20 h-1 rounded-xl ${
                index === currentIndex ? "bg-[#0EB683]" : "bg-white"
              }  `}
            ></motion.div>
          ))}
        </motion.div>
      </motion.div>
    );
  };

  // Effect for Timing the CTA Sequence
  useEffect(() => {
    let timerId = setInterval(next, 5000);
    return () => clearInterval(timerId);
  }, [currentCtaIndex]);

  // linear-gradient(to bottom, #b5cfc7dd, transparent),
  return (
    <header
      className="h-screen p-5 px-[8%]  "
      style={{
        backgroundImage: ` url('${BGImg}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <nav className="flex items-center">
        <a href="/" className="transition-all active:scale-90">
          <img src={Logo} alt="" className="w-44 h-14" />
        </a>

        <div className="nav-options ml-auto hidden md:flex items-center gap-10">
                <Link to="/login" className='font-semibold'>Login</Link>
                <Link to={'/request-access'} className='bg-[#0EB683] text-white p-3 px-5 rounded-lg active:scale-90 transition-all duration-500 hover:opacity-80 '>
                    Request Access
                </Link>
            </div>
      </nav>
      <div className="">{renderCtaText(currentCtaIndex)}</div>
      <motion.p
        initial={{ x: "50%", opacity: 0 }}
        animate={{ x: "0%", opacity: 1 }}
        transition={{ delay: 1 }}
        className="text-lg my-2"
      >
        Trusted Transfers securely delivers cross-border payments{" "}
        <br className="hidden md:inline" /> between verified businesses in
        Africa <br className="hidden md:inline" /> and credible global suppliers
      </motion.p>
      <motion.div
        initial={{ y: "50%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        transition={{ delay: 1 }}
        className="cta flex gap-3 md:gap-5 mt-5 "
      >
        {/* <Link
          to={"/dashboard"}
          className="bg-[#0EB683] text-white p-3 px-5 rounded-lg active:scale-90 transition-all duration-500 hover:opacity-80 pb-"
        >
          Dashboard
        </Link> */}
        <Link to={'/login'} className='bg-white border border-[#0EB683] text-[#0EB683]  p-3 px-5 rounded-lg active:scale-90 transition-all duration-500 hover:opacity-80 '>
                Log in
            </Link>
      </motion.div>
    </header>
  );
};

export default LandingPageHeader;
