import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { FiBell, FiLogOut, FiSearch, FiSettings } from "react-icons/fi";
import { MdOutlineDateRange } from "react-icons/md";
import { Link } from "react-router-dom";
import { useUsers } from "../../sevices/hooks/users";
// import { useOutletContext } from "react-router-dom";

const Nav = () => {
  const [imagedata, setImageData] = useState("");
  const [showProfileDrop, setShowProfileDrop] = useState(false);
  // const [userDetails] = useOutletContext<any>();

  //function to extract images
  const handleImage = (evt: any) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (arg: any) => {
      setImageData(arg.target.result);
    });
    reader.readAsDataURL(evt.target.files[0]);
  };

  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  ;

  //submit function
  // const handleSubmit = (evt: any) => {
  //   evt.preventDefault()
  //   if(!imagedata) {
  //     return
  //     let newImage ={
  //       picture{
  //         medium:
  //       }
  //     }
  //   }
  // }

  return (
    <nav className="sticky top-0 left-0 right-0 bg-white p-2 px-[4%] w-full text-[#2E4457] border-b items-center justify-end pr-5 hidden md:block">
      <div className="flex items-center  gap-2 justify-end relative">
        <div className="div flex gap-3 cursor-pointer border-r-2 border-l-gray-300 pr-3">
          <div className="search-area flex items-center p-2 rounded-full gap-2  bg-[#F7FBFB]">
            <FiSearch size={20} />
            {/* <input type="text" placeholder='Search...' className='bg-transparent outline-none hidden md:inline-block' /> */}
          </div>
          <div className="date_area flex items-center bg-[#F7FBFB] px-3 rounded-xl gap-1">
            <MdOutlineDateRange />
            <span className="font-medium">{new Date().toDateString()}</span>
          </div>
          <div className="flex items-center p-2 bg-[#F7FBFB] rounded-full mr-3 ">
            <FiBell size={20} className="  " />
          </div>
        </div>
        <div className="border border-gray-300 h-9 w-9 rounded-full flex  justify-center mr-4 ml-3 ">
          <figure className=" bg-gray-100 h-6 w-6 rounded-full self-center relative flex">
            <div
              className="w-full h-full rounded-full text-gray-300 flex items-center justify-center cursor-pointer absolute top-0 left-0 transition-all active:scale-95"
              onClick={() => setShowProfileDrop(!showProfileDrop)}
            >
              <FaUserAlt size={17} />
            </div>
          </figure>
        </div>
        {showProfileDrop && (
          <div className="bg-white w-60 h-fit border absolute top-10 right-[-1px] mt-1 flex flex-col rounded-xl  pt-1 shadow-md z-50">
            <button onClick={() => setShowProfileDrop(false)} className="self-end hover:bg-slate-200 cursor-pointer rounded-full mt-1 mr-3 ">
              <AiOutlineClose size={24} />
            </button>

            <div className="border-2 border-[#201f1f] h-12 w-12 rounded-full flex  justify-center self-center mb-3">
              <figure className=" bg-[#3a3737] h-10 w-10 rounded-full self-center relative flex">
                <label
                  htmlFor="file_input"
                  className="w-full h-full rounded-full text-gray-300 flex items-center justify-center cursor-pointer absolute top-0 left-0 transition-all active:scale-90"
                >
                  <FaUserAlt size={28} />
                </label>
                {/* <input type="file" name="file" accept="image/*" id="file_input" className="hidden" onChange={handleImage} />
          <img src= {imagedata} alt="" className="w-full rounded-full self-center" /> */}
              </figure>
            </div>
            <div className="self-center">
              <h2 className="font-medium text-[#000] text-xl ">{getUserDetails?.data?.data?.firstName} {getUserDetails?.data?.data?.lastName}</h2>
              <p className="email text-sm text-center pb-3">{getUserDetails?.data?.data?.email}</p>
            </div>
            <button className="border border-[#0eb683] p-2 self-center rounded-md transition-all active:scale-90 text-[#0eb683]">
              Tap to change image
            </button>
            <Link to={'/dashboard/settings'} className="settings font-bold flex items-center pl-8 gap-3 pt-4 ">
              <FiSettings size={20} />
              <h1>Settings</h1>
            </Link>
            <Link
              onClick={() => window.localStorage.removeItem("accessToken")}
              to={"/login"}
              className="font-bold logout flex items- pl-8 gap-3 items-center text-red-500 py-4 cursor-pointer transition-all active-scale-90">
              <FiLogOut size={20} />
              <p>
                Log Out
              </p>
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Nav;
