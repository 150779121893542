import React, { useState } from "react";
import { MdOutlineCheck } from "react-icons/md";
import bgImg from "../../../assets/misc/design-bottom.svg";
import { useLpStore } from "../../../store/useLiquidityPartner";

const LiquidityPartnerCard = () => {
  const [cardState, setCardState] = useState<boolean>(false);
  const setShowLpModal = useLpStore((state) => state.setShowLp);
  const activeLp = useLpStore((state) => state.activeLp);

  const handleState = () => {
    setShowLpModal(true)
    // setCardState((cardState) => !cardState);
  };
console.log(activeLp, "active");

  const activeCard = activeLp
    ? "border-2 border-[#0eb683] shadow-md relative"
    : " h-16 border rounded-md flex items-center justify-between px-5 relative cursor-pointer ";

  return (
    <div
      onClick={()=> setShowLpModal(true)}
      className={` ${activeCard} ${ activeLp ? "bg-gradient-to-r from-[#fff] to-[#e3f8f1]": null }  h-16 border rounded-md flex items-center justify-between px-5 relative cursor-pointer bg-clip-border  active:scale-100`}
    >
      <div>
        <h1 className="text-lg font-bold text-[#030303]">
          Liquidity Partner 1
        </h1>
        <p className="text-[#696677] text-xs">Access Bank- 0123456789</p>
      </div>
      {activeLp ? (
        <div className="rounded-full w-5 h-5 bg-[#0eb683] flex items-center justify-center absolute top-6 right-6 ">
          <MdOutlineCheck size={24} fill="#fff" />{" "}
        </div>
      ) : null}
      {activeLp ? (
        <div className="absolute h-1 w-1 rounded-full bg-[#00D266]  top-1 right-1 shadow-lg animate-ping"></div>
      ) : (
        <div className="absolute h-[6px] w-[5px] rounded-full bg-slate-400  top-1 right-1 "></div>
      )}
    </div>
  );
};

export default LiquidityPartnerCard;
