import React, { useState } from "react";
import LpModal from "./LpModal";
import AddLpModal from "./AddLpModal";
import { TabSelector } from "../widgets/TabSelector";
import { TabPanel, useTabs } from "../widgets/TabWidget";
import LiquidityPartnerCard from "./LiquidityPartnerCard";
import { MdOutlineAdd } from "react-icons/md";
import { useLpStore } from "../../../store/useLiquidityPartner";
import AppTable from "../AppTable";
import { useAdminUsers } from "../../../sevices/hooks/admin";
import moment from "moment";
import { Column } from "react-table";
import { Navigate, useNavigate } from "react-router-dom";
import { CgSpinnerAlt } from "react-icons/cg";

export interface IColumn {
  serialNumber: any;
  createdAt: string;
  fullAccountName: string;
  accountNumber: string;
  action?: string;
  bank?: string;
  userId: string;
}

export type Iaccounts = {
  userId: string;
  serialNumber: any;
  createdAt: string;
  fullAccountName: string;
  accountNumber: string;
  bank?: string;
};

export const data_sets: Iaccounts[] = [
  {
    serialNumber: 1,
    userId: "user1",
    createdAt: "2023-07-01",
    fullAccountName: "John Doe",
    accountNumber: "1234567890",
    bank: `First Bank`,
  },

  {
    serialNumber: 2,
    userId: "user2",
    createdAt: "2023-07-02",
    fullAccountName: "Jane Smith",
    accountNumber: "0987654321",
    bank: `Zenith`,
  },

  {
    serialNumber: 3,
    userId: "user3",
    createdAt: "2023-07-03",
    fullAccountName: "Alex Johnson",
    accountNumber: "9876543210",
    bank: `Zenith`,
  },

  {
    serialNumber: 4,
    userId: "user4",
    createdAt: "2023-07-04",
    fullAccountName: "Emily Davis",
    accountNumber: "0123456789",
    bank: `Zenith`,
  },

  {
    serialNumber: 5,
    userId: "user5",
    createdAt: "2023-07-05",
    fullAccountName: "Michael Brown",
    accountNumber: "1111111111",
    bank: `wema`,
  },

  {
    serialNumber: 6,
    userId: "user6",
    createdAt: "2023-07-06",
    fullAccountName: "Olivia Martinez",
    accountNumber: "2222222222",
    bank: `wema`,
  },

  {
    serialNumber: 7,
    userId: "user7",
    createdAt: "2023-07-07",
    fullAccountName: "William Wilson",
    accountNumber: "3333333333",
    bank: `wema`,
  },]

   interface IUserProfile {
     accountType: string;
     address1: string | null;
     address2: string | null;
     bankStatus: boolean;
     brassSubAccountId: string | null;
     businessId: string;
     businessIncNumber: string | null;
     businessName: string | null;
     businessPhoneNumber: string | null;
     businessType: string | null;
     bvn: string | null;
     city: string | null;
     country: string | null;
     createdAt: string;
     deletedAt: string | null;
     dob: string | null;
     email: string;
     eurBalance: string;
     firstName: string;
     gbpBalance: string;
     gender: string | null;
     id: string;
     idDocPhoto: string | null;
     idType: string | null;
     invitationCode: string;
     isCorePromoter: boolean;
     kycDoc: string | null;
     kycStatus: string;
     lastName: string;
     lbpCustomerId: string | null;
     lbpCustomerStatus: string | null;
     lbpVerificationStatus: string | null;
     ngnBalance: string;
     ngnLBPAccountName: string | null;
     ngnLBPBankCode: string | null;
     ngnLBPBankId: string | null;
     ngnLBPBankName: string | null;
     ngnLBPSubAccountId: string | null;
     ngnLBPSubAccountNumber: string | null;
     ngnLBPSubAccountStatus: string | null;
     ngnLBPVirtualAccountId: string | null;
     phoneNumber: string | null;
     postCode: string | null;
     profileStatus: string | null;
     refereeCode: string;
     selfie: string | null;
     state: string | null;
     updatedAt: string;
     usdBalance: string;
     usdcBalance: string;
     usdtBalance: string;
     userAccountNumber: string;
     userBankCode: string | null;
     userBankId: string;
     userBankName: string | null;
     verificationCode: string;
   }


export const SettingsAccount = ({
  handleDetails,
}: {
  handleDetails: (id: any) => void;
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [lpModal, setLpModal] = useState(false);
  const [subTab, setSubTab] = useTabs(["Fiat", "Stablecoin"]);
  const toggleModal = () => {
    setModal((modal) => !modal);
  };
  const { data, error } = useAdminUsers();

  const showLp = useLpStore((state) => state.showLp);
  const setShowLp = useLpStore((state) => state.setShowLp);

  const showLpModal = () => {
    setLpModal((prev) => !prev);
  };

  const userData = data?.data?.data;

  const dColumns: readonly Column<IUserProfile>[] = [
    {
      Header: "SN",
      // accessor: "index",
      //@ts-ignore
      Cell: ({ value, row }) => {
        const index = row?.index;
        return (index !== undefined ? index + 1 : "").toString();
      },
    },
    {
      Header: "DATE/TIMESTAMP (GMT+1)",
      accessor: "createdAt",
      Cell: ({ value }) => {
        console.log(value, "value");
        const formattedValue = moment(value).format("YYYY-MM-DD");
        return (
          <div className="">
            <span className="flex items-center whitespace-nowrap">
              {formattedValue}
            </span>
          </div>
        );
      },
    },
    {
      Header: "USER ID",
      accessor: "id",
      Cell: ({ value }: { value: string }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <Tooltip message={value}> */}
          <a
            className="truncate hover:underline cursor-pointer text-[#0eb683]"
            style={{ marginRight: "0.5rem" }}
          >
            {value.slice(0, 20)}
          </a>
          {/* </Tooltip> */}
        </div>
      ),
    },
    {
      Header: "FULL ACCOUNT NAME",
      accessor: "ngnLBPAccountName",
      Cell: ({ value }) => (
        <div className="">
          <span className="flex items-center whitespace-nowrap">{value}</span>
        </div>
      ),
    },
    {
      Header: "ACCOUNT NUMBER",
      accessor: `userAccountNumber`,
      Cell: ({ value }) => (
        <div className="">
          <span className="flex items-center whitespace-nowrap">{value}</span>
        </div>
      ),
    },
    {
      Header: "ACTIONS",
      accessor: (row) => {
        console.log({ row });
        return (
          <div
            onClick={() => {
              handleDetails(row?.id);
            }}
            className="whitespace-nowrap truncate hover:underline cursor-pointer text-[#0eb683]"
          >{`view more`}</div>
        );
      },
    },
  ];
  console.log("====================================");
  console.log(userData, "michg");
  console.log("====================================");
  return (
    <div>
      {" "}
      <div className="flex justify-between"></div>
      {userData ? (
        <AppTable
          tableData={userData}
          tabs={[]}
          //@ts-ignore
          dColumns={dColumns}
          showFilter={true}
        />
      ) : (
        <div className="flex flex-col items-center justify-center mt-40">
          <CgSpinnerAlt size={80} className="animate-spin" />
          <p className="mt-4">Fetching data...</p>
        </div>
      )}
    </div>
  );
};

export default SettingsAccount;
