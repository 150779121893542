import React from "react";
import DashboardIcon from "../../assets/iconsComponents/DashboardIcon";
import WalletIcon from "../../assets/iconsComponents/WalletIcon";
import AccountIcon from "../../assets/iconsComponents/AccountIcon";
import TransactionsIcon from "../../assets/iconsComponents/TransactionsIcon";
import SettingsIcon from "../../assets/iconsComponents/SettingsIcon";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import UsersIcon from "../../assets/iconsComponents/UsersIcon";
import RevenueIcon from "../../assets/iconsComponents/RevenueIcon";
import PayoutsIcon from "../../assets/iconsComponents/PayoutsIcon";
import FeesIcon from "../../assets/iconsComponents/FeesIcon";
import RatesIcon from "../../assets/iconsComponents/RatesIcon";
import IndividualUser from "../../assets/iconsComponents/IndividualUser";
import BusinessUserIcon from "../../assets/iconsComponents/BusunessUserIcon";
import { FiCreditCard, FiSend, FiUsers } from "react-icons/fi";
import { HiOutlineCheck } from "react-icons/hi";
import { BsPercent } from "react-icons/bs";
import ComplianceIcon from "../../assets/iconsComponents/ComplianceIcon";

export const DashBoardData = [
  {
    id: 0,
    name: "Dashboard",
    url: "/dashboard",
    icon: <DashboardIcon width={"20px"} height={"20px"} />,
  },
  {
    id: 1,
    name: "Users",
    url: "/dashboard/users/individuals",
    icon: <FiUsers width={"24px"} height={"24px"} />,
    iconClosed: <BiChevronDown />,
    iconOpen: <BiChevronUp />,
    subNav: [
      {
        id: 1,
        name: "Individuals",
        url: "/dashboard/users/individuals",
        // icon: <IndividualUser width={"20px"} height={"20px"} />,
      },
      {
        id: 1,
        name: "Businesses",
        url: "/dashboard/users/businesses",
        // icon: <BusinessUserIcon width={"20px"} height={"20px"} />,
      },
    ],
  },
  {
    id: 2,
    name: "Accounts",
    url: "/dashboard/accounts",
    icon: <AccountIcon width={"20px"} height={"20px"} />,
    iconClosed: <BiChevronDown />,
    iconOpen: <BiChevronUp />,
    subNav: [
      {
        id: 1,
        name: "All",
        url: "/dashboard/accounts",
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 2,
        name: "NGN",
        url: `/dashboard/accounts/ngn`,
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 3,
        name: "USD",
        url: `/dashboard/accounts/usd`,
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 5,
        name: "GBP",
        url: "/dashboard/accounts/gbp",
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 6,
        name: "EUR",
        url: "/dashboard/accounts/eur",
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 7,
        name: "USDC",
        url: "/dashboard/accounts/usdc",
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 8,
        name: "USDT",
        url: "/dashboard/accounts/usdt",
        // icon: <AccountIcon width={"20px"} height={"20px"} />,
      },
    ],
  },
  {
    id: 3,
    name: "Transactions",
    url: "/dashboard/transactions",
    icon: <FiSend width={"20px"} height={"20px"} stroke="#5C6F7F" />,
    iconClosed: <BiChevronDown />,
    iconOpen: <BiChevronUp />,
    subNav: [
      {
        id: 1,
        name: "All",
        url: "/dashboard/transactions",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 2,
        name: "NGN",
        url: "/dashboard/transactions/ngn",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 3,
        name: "USD",
        url: "/dashboard/transactions/usd",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 5,
        name: "GBP",
        url: "/dashboard/transactions/gbp",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 6,
        name: "EUR",
        url: "/dashboard/transactions/eur",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 7,
        name: "USDC",
        url: "/dashboard/transactions/usdc",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 8,
        name: "USDT",
        url: "/dashboard/transactions/usdt",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
    ],
  },
  {
    id: 4,
    name: "Approvals",
    url: "/dashboard/approvals/bank ",
    icon: <HiOutlineCheck width={"23px"} height={"24px"} />,
    iconClosed: <BiChevronDown />,
    iconOpen: <BiChevronUp />,
    subNav: [
      {
        id: 1,
        name: "Bank",
        url: "/dashboard/approvals/bank",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 1,
        name: "Beneficiary",
        url: "/dashboard/approvals/beneficiary",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
    ],
  },
  {
    id: 4,
    name: "Compliance",
    url: "/dashboard/compliance/onboarding ",
    icon: <ComplianceIcon width={"20px"} height={"20px"} />,
    iconClosed: <BiChevronDown />,
    iconOpen: <BiChevronUp />,
    subNav: [
      {
        id: 1,
        name: "Onboarding",
        url: "/dashboard/compliance/onboarding",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
      {
        id: 1,
        name: "Transfers",
        url: "/dashboard/compliance/transfer",
        // icon: <TransactionsIcon width={"20px"} height={"20px"} />,
      },
    ],
  },
  {
    id: 5,
    name: "Revenue",
    url: "/dashboard/revenues",
    icon: <RevenueIcon width={"20px"} height={"20px"} />,
  },
  {
    id: 6,
    name: "Payouts",
    url: "/dashboard/payouts",
    icon: <FiCreditCard width={"20px"} height={"20px"} />,
  },
  {
    id: 7,
    name: "Fees",
    url: "/dashboard/fees",
    icon: <FeesIcon width={"20px"} height={"20px"} />,
  },
  {
    id: 8,
    name: "Balances",
    url: "/dashboard/balances",
    icon: <BsPercent width={"20px"} height={"20px"} />,
  },
  {
    id: 9,
    name: "Rates",
    url: "/dashboard/rates",
    icon: <RatesIcon width={"20px"} height={"20px"} />,
  },
  {
    id: 10,
    name: "Settings",
    url: "/dashboard/settings?tab=Functionality+Management",
    icon: <SettingsIcon width={"28px"} height={"24px"} />,
  },
];
