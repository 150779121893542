import React from 'react'
import WalletIcon from '../../assets/iconsComponents/WalletIcon'

const StablecoinWidget = ({coin}:{coin: string}) => {
  return (
    <div className='flex border h-[150px] w-80 border-[#CBE0D6] items-center justify-center gap-4 bg-[#FFFFFF] rounded-md shadow-md'>
        <div className="icon bg-[#E7FCF5] w-10 h-10  flex items-center justify-center rounded-full"><WalletIcon/></div>
        <div>
            <div className="collections text-[#5C6F7F] text-base font-normal font-sans">{coin} Collections</div>
            <div className="amount text-[#071827] font-bold text-base font- font-sans">340,230,900.00 {coin}</div>
        </div>

    </div>
  )
}

export default StablecoinWidget