import React from 'react'
import SearchComponent from '../../components/dashboard-components/SearchComponent'
import Table from '../../components/dashboard-components/Table'

const Payouts = () => {
  return (
    <>
    <div className="h-100vh w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
        <div className="flex flex-row items-center justify-between  mt-5">
          <h1 className="font-bold text-2xl text-[#071827] md:pb-2">
            Private Users
          </h1>
        </div>
        <SearchComponent placeholder={"Search users"} />
        <div>
          {/* <Table/> */}
          {/* <Table tabs={["All", "NGN", "USD", "GBP", "EUR", "USDC", "USDT"]}
            itemsPerPage={10}
            currentPage={1}
            data={rows} /> */}
        </div>
      </div>
    </>
   
  )
}

export default Payouts