import React from 'react'
import { useParams } from 'react-router-dom'
import EURTx from './EURTx'
import GBPTx from './GBPTx'
import NGNTx from './NGNTx'
import USDCTx from './USDCTx'
import USDTTx from './USDTTx'
import USDTx from './USDTx'

const TransactionView = () => {
    const {currency} = useParams()
  return (
   <>
   {currency === "usd" && <USDTx currency = {currency}/> }
   {currency === "gbp" && <GBPTx currency = {currency}/> }
   {currency === "eur" && <EURTx currency = {currency}/> }
   {currency === "ngn" && <NGNTx currency = {currency}/> }
   {currency === "usdt" && <USDTTx currency = {currency}/> }
   {currency === "usdc" && <USDCTx currency = {currency}/> }
   
   </>
  )
}

export default TransactionView