import React, { useState } from "react";
import FeesAccordion from "../../components/dashboard-components/fees-component/FeesAccordion";
import { useFees, useGetFees } from "../../sevices/hooks/admin";
import FeesModal from "../../components/dashboard-components/fees-component/FeesModal";

const Fees = () => {
  const [active, setActive] = useState("");
  const [createFee, setCreateFee] = useState(false);
  const [values, setValues] = useState({
    key: "",
    name: "",
    value: "",
    type: "",
    description: "",
  });

  const fees = useGetFees();
  const postFees = useFees();
  console.log(fees?.data?.data);
  // console.log({ postFees });
  console.log(values?.key);

  //  const handleFormSubmit = (e: any) => {
  //    e.preventDefault();

  //    const validate = validation(values);
  //    setLoading(true);
  //    setErrors(validate);
  //    if (!validate.email) {
  //      verifyUser
  //        .mutateAsync(values)
  //        .then((response) => {
  //          swapScreen();
  //        })
  //        .catch((e) => {
  //          setLoading(false);
  //          if (e.response?.data) {
  //            passwordErr = e.response?.data;
  //            setPasswordErr(passwordErr);
  //          } else {
  //            setPasswordErr("");
  //          }
  //        });
  //    }
  //  };

  console.log(fees?.status === "loading", "hhhe");

  return (
    <>
      <div className="h-100% w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
        <div className="flex flex-row items-center justify-between  mt-8 ">
          <h1 className="font-bold text-2xl text-[#071827] md:pb-2">Fees</h1>

          <button
            onClick={() => setCreateFee(true)}
            className="bg-[#0eb683] text-[#fff] p-3 rounded-md transition all duration-500 active:scale-90 px-5 tracking-wide select-none"
          >
            Create Fee
          </button>
        </div>

        {createFee ? (
          <FeesModal isOpen={createFee} onClose={setCreateFee} />
        ) : null}
        {fees?.status === "loading" ? (
          // <feesLoader/>
        "loading"
        ) : (
        <div className="mt-8">
          {fees?.data?.data.map((d: any) => {
            return (
              <FeesAccordion
                key={d?.id}
                title={d?.description}
                Val={d?.value?.amount}
                subtext={d?.type}
                id={d?.id}
                active={active}
                setActive={setActive}
              />
            );
          })}
        </div>

        )}
      </div>
    </>
  );
};

export default Fees;
