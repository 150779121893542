import React, { useContext, useState } from "react";



const StepperController = ({
  checkoutSteps,
  currentStep,
  handleClick,
}: {
  checkoutSteps: string[];
  currentStep: number;
  handleClick: (arg: string) => void;
}) => {
  //   const { selectedFiles, selecFiles, seFiles } = useContext(FileContext);
  //   const isOpen = useSignUpState((state) => state.isOpen);
  //   const setIsOpen = useSignUpState((state) => state.setIsOpen);
  // const { seFile, selecFile, selectedFile } = formFiles;
  // const submitDetails = () => {
  //   handleClick("next");
  //   if (currentStep === checkoutSteps?.length) {
  //     onboardVendor
  //       .mutateAsync(userData)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {});
  //   }
  // };

  const submitDetails = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    handleClick("next");
  };

  console.log(checkoutSteps, "checkoutSteps");
  console.log(currentStep, "currentStep");
  return (
    <div className="flex gap-3 w-full my-8 parent-class items-center justify-between">
      <button
        disabled={currentStep === 1}
        onClick={() => {
          handleClick("");
        }}
        className={`bg-[#fff] border border-[#0EB683] text-[#0EB683] px-8 py-2.5 rounded  shadow-lg  duration-100 ease-in-out disabled:text-[#BDE2D7] disabled:border-[#BDE2D7] ${
          currentStep === 1 ? "cursor-not-allowed" : ""
        }`}
      >
        Cancel
      </button>
      <button
        // disabled
        onClick={submitDetails}
        className="bg-[#0EB683]  text-white border border-[#0EB683] px-10 py-2.5 disabled:bg-[#BDE2D7] rounded text-button   shadow-lg hover:bg-[#1aa279] duration-100 ease-in-out"
      >
        {currentStep === checkoutSteps?.length ? "Add User" : "Continue"}
      </button>
    </div>
  );
};

export default StepperController;

// import React, { useState } from 'react';
