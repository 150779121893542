import  React from 'react'
import { FC } from 'react';
import SvgIconConstituentValues from './types';

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const DashboardIcon = ({
    height = "24px",
    width = "24px",
    stroke = "#5C6F7F",
    ...prop
}: React.SVGProps<SVGAElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9.16667 3H3.33333C2.8731 3 2.5 3.3731 2.5 3.83333V9.66667C2.5 10.1269 2.8731 10.5 3.33333 10.5H9.16667C9.6269 10.5 10 10.1269 10 9.66667V3.83333C10 3.3731 9.6269 3 9.16667 3Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0833 13.8335H13.7499C13.5198 13.8335 13.3333 14.02 13.3333 14.2502V17.5835C13.3333 17.8136 13.5198 18.0002 13.7499 18.0002H17.0833C17.3134 18.0002 17.4999 17.8136 17.4999 17.5835V14.2502C17.4999 14.02 17.3134 13.8335 17.0833 13.8335Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0833 6.3335H13.7499C13.5198 6.3335 13.3333 6.52004 13.3333 6.75016V10.0835C13.3333 10.3136 13.5198 10.5002 13.7499 10.5002H17.0833C17.3134 10.5002 17.4999 10.3136 17.4999 10.0835V6.75016C17.4999 6.52004 17.3134 6.3335 17.0833 6.3335Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.58325 13.8335H6.24992C6.0198 13.8335 5.83325 14.02 5.83325 14.2502V17.5835C5.83325 17.8136 6.0198 18.0002 6.24992 18.0002H9.58325C9.81337 18.0002 9.99992 17.8136 9.99992 17.5835V14.2502C9.99992 14.02 9.81337 13.8335 9.58325 13.8335Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon