import create from "zustand";

interface IUserState {
  userDetails: any;
  setUserDetails: (value: any) => void;
}

interface ISideBarState {
  sideBarOpen: boolean;
  invitationModalOpen: boolean;
  toggleSidebar: (value?: boolean) => void;
  setInvitationModalOpen: (value: boolean) => void;
}

interface IRateState {
  usdRate: number;
  eurRate: number;
  gbpRate: number;
  setUSDRate: (newRate: number) => void;
  setEURRate: (newRate: number) => void;
  setGBPRate: (newRate: number) => void;
}

// interface IAccountsState {
//   ngnAccount: number
//   usdAccount: number
//   setNgnAccount: (newAccount: number) => void
//   setUsdAccount: (newAccount: number) => void
// }

export const useUserState = create<IUserState>((set) => ({
  userDetails: [],
  setUserDetails: (value: any) => set((state) => ({ userDetails: value })),
}));

export const useSidebarState = create<ISideBarState>((set) => ({
  sideBarOpen: false,
  invitationModalOpen: false,
  toggleSidebar: (value?: boolean) =>
    set((state) => ({ sideBarOpen: value ?? !state.sideBarOpen })),
  setInvitationModalOpen: (isOpen: boolean) =>
    set((state) => ({ invitationModalOpen: isOpen })),
}));

export const useRatesState = create<IRateState>((set) => ({
  usdRate: 0,
  eurRate: 0,
  gbpRate: 0,
  setUSDRate: (newRate: number) => set((state) => ({ usdRate: newRate })),
  setEURRate: (newRate: number) => set((state) => ({ eurRate: newRate })),
  setGBPRate: (newRate: number) => set((state) => ({ gbpRate: newRate })),
}));

interface Ifnx {
  functionalityId: string;
  function: any;
  setFunctionalityId: (id: string) => void;
  setFunction: (value: any) => void;
}

export const useFnx = create<Ifnx>((set) => ({
  functionalityId: "",
  function: null,
  setFunctionalityId: (id: string) =>
    set((state: any) => ({ functionalityId: id })),
  setFunction: (value: any) => set((state) => ({ function: value })),
}));


// export const useAccountState = create<IAccountsState>(set => ({
//   ngnAccount: 0,
//   usdAccount: 0,
//   setNgnAccount: (newAccount: number) => set(state => ({
//     ngnAccount: newAccount})),
//   setUsdAcount: (newAccount: number) => set(state=> ({
//     usdAccount: newAccount})),
// }))
