import React, { useState } from "react";
import { MdOutlineAdd } from "react-icons/md";
import AddLpModal from "../../components/dashboard-components/settings-component/AddLpModal";
import LpModal from "../../components/dashboard-components/settings-component/LpModal";
import LiquidityPartnerCard from "../../components/dashboard-components/settings-component/LiquidityPartnerCard";
import SettingsData from "../../components/dashboard-components/settings-component/SettingsData";
import ToggleSwitch from "../../components/dashboard-components/ToggleSwitch";
import { TabSelector } from "../../components/dashboard-components/widgets/TabSelector";
import { ImSpinner6 } from "react-icons/im";
import {
  TabPanel,
  useTabs,
} from "../../components/dashboard-components/widgets/TabWidget";
import { useLpStore } from "../../store/useLiquidityPartner";
import FeesModal from "../../components/dashboard-components/fees-component/FeesModal";
import SettingsAccount from "../../components/dashboard-components/settings-component/SettingsAccount";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsWallet from "../../components/dashboard-components/settings-component/SettingsWallet";
import { IoMdAdd } from "react-icons/io";
import AppTable from "../../components/dashboard-components/AppTable";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { Column } from "react-table";
import { IOptionType } from "../../components/dashboard-components/settings-component/stepper/AccessPermission";
import CustomSelect from "../../components/CustomSelect";
import TextArea from "../../components/TextArea";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaCheck, FaRegSmile } from "react-icons/fa";
import {
  useDeleteFunctionality,
  useFunctionality,
  useGetFunctionality,
  useUpdateFunctionality,
} from "../../sevices/hooks/admin";
import _ from "lodash";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { AxiosResponse } from "axios";
import { useFnx } from "../../store";
import { GiBoxUnpacking } from "react-icons/gi";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useTabs([
    "Functionality Management",
    "Accounts",
    "Wallets",
    "Role Management",
    "Liquidity Partners",
    "Compliance",
  ]);
  const [modal, setModal] = useState(false);
  const [lpModal, setLpModal] = useState(false);
  const [subTab, setSubTab] = useTabs(["Fiat", "Stablecoin"]);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { data: functionality, refetch } = useGetFunctionality();

  console.log(functionality?.data?.data, "functionality");

  const handleDelete = () => {
    setIsDelete((prev) => !prev);
  };
  const handleConfirm = () => {
    setIsConfirm((prev) => !prev);
  };
  const toggleModal = () => {
    setModal((modal) => !modal);
  };
  const navigate = useNavigate();
  //  const history = useHistory();
  //  const location = useLocation();
  const handleTabClick = (tabName: any) => {
    const formattedTabName = tabName?.replace(/\s+/g, "+");
    setSelectedTab(tabName);
    navigate(`/dashboard/settings?tab=${formattedTabName}`);
  };
  const handleAddModal = () => {
    setAddModal((prev) => !prev);
  };
  const showLp = useLpStore((state) => state.showLp);
  const setShowLp = useLpStore((state) => state.setShowLp);

  const showLpModal = () => {
    setLpModal((prev) => !prev);
  };

  const dColumns: readonly Column<any>[] = [
    {
      Header: "SN",
      accessor: "sn",
      //@ts-ignore
      Cell: ({ value, row }) => {
        const index = row?.index;
        return (index !== undefined ? index + 1 : "").toString();
      },
    },
    {
      Header: "NAME",
      accessor: (row) => `  `,
      //@ts-ignore
      Cell: (value) => {
        console.log(value?.row.original, "kk");
        const firstName = value?.row.original.firstName;
        const lastName = value?.row.original.lastName;
        return (
          <div>
            <span
              onClick={() => {
                handleUserRole(value?.row?.original.id);
              }}
              className="text-[14px] leading-[21px] text-[#0eb683] hover:underline cursor-pointer active:scale-90 transition-all ease-in-out duration-200"
            >
              {firstName} {lastName}
            </span>
          </div>
        );
      },
    },
    {
      Header: "EMAIL",
      accessor: "email",
      Cell: ({ value }: { value: string }) => (
        <div className="text-[#071827] text-[14px] leading-[21px]">{value}</div>
      ),
    },
    {
      Header: "ROLE",
      accessor: "role",
      Cell: ({ value }: { value: string }) => (
        <div className="">
          <span className="text-[#071827] text-[14px] leading-[21px]">
            {value}
          </span>
        </div>
      ),
    },

    {
      Header: "ACTIONS",
      accessor: (row: any) => {
        console.log({ row });
        return (
          <div
            className="flex items-center gap-4"
            //   onClick={() => {
            //     handleDetails(row?.userId);
            //   }}
          >
            <div className=" cursor-pointer text-[#0eb683]">
              <FiEdit size={20} />
            </div>
            <div className=" cursor-pointer text-[#e60606]">
              <RiDeleteBinLine size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab");
  const formattedTabName = tabParam ? tabParam.replace(/\+/g, " ") : "";

  const handleDetails = (id: any) => {
    navigate(`/dashboard/settings/${id}?tab=${formattedTabName}`);
  };
  const handleUserRole = (id: any) => {
    navigate(`/dashboard/settings/views/${id}?tab=${formattedTabName}`);
  };
  const handleAddButtonClick = () => {
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams, "searchParams.toString()");
    console.log(location.pathname, "location.pathname");
    searchParams.set("tab", "Role+Management");
    const decodedURL = decodeURIComponent(
      searchParams.toString().replace(/%2B/g, "+")
    );
    const newURL = `${location.pathname}/add?${decodedURL}`;
    console.log(newURL, "newURL");
    navigate(newURL); // Navigate to the new URL
  };
  return (
    <div className="h-screen w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
      <div className="flex flex-row items-center justify-between  my-8">
        <h1
          style={{ fontFamily: "AirbnbCereal_W_Bd" }}
          className="font-bold text-[30px] text-[#071827] md:pb-2"
        >
          Settings
        </h1>
      </div>
      <nav className=" border-b-2 border-[#E8E9EB] px-5 md:px-0  pb-4 flex gap-8 justify-between md:justify-start mr-8 ">
        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] ${
            formattedTabName === "Functionality Management"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          } ${
            formattedTabName === "Functionality Management"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Functionality Management"}
          onClick={() => handleTabClick("Functionality Management")}
        >
          Functionality Management
        </TabSelector>

        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] ${
            formattedTabName === "Accounts"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          }  ${
            formattedTabName === "Accounts"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Accounts"}
          onClick={() => handleTabClick("Accounts")}
        >
          Accounts
        </TabSelector>
        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] ${
            formattedTabName === "Wallets"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          } ${
            formattedTabName === "Wallets"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Wallets"}
          onClick={() => handleTabClick("Wallets")}
        >
          Wallets
        </TabSelector>
        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] flex items-center justify-center gap-1 ${
            formattedTabName === "Role Management"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          } ${
            formattedTabName === "Role Management"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Role Management"}
          onClick={() => handleTabClick("Role Management")}
        >
          <span>Role Management</span>
          {/* {ivitee < 0 ? (""):(
          <div className="bg-[#FF9636] h-5 px-3  w-fit  text-[#fff] font-normal flex [16px] leading-[24px]ems-center justify-center rounded-md  top-0 right-[-36px]" >{ivitee}</div>)
          } */}
        </TabSelector>
        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] flex items-center justify-center gap-1 ${
            formattedTabName === "Liquidity Partners"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          } ${
            formattedTabName === "Liquidity Partners"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Liquidity Partners"}
          onClick={() => handleTabClick("Liquidity Partners")}
        >
          Liquidity Partners
        </TabSelector>
        <TabSelector
          className={` cursor-pointer relative bg-transparent  text-[16px] leading-[24px] flex items-center justify-center gap-1 ${
            formattedTabName === "Compliance"
              ? "text-[#071827] font-semibold"
              : "text-[#5C6F7F] font-normal"
          } ${
            formattedTabName === "Compliance"
              ? "text-[#0eb683] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={formattedTabName === "Compliance"}
          onClick={() => handleTabClick("Compliance")}
        >
          Compliance
        </TabSelector>
      </nav>
      <div>
        <TabPanel
          hidden={formattedTabName !== "Functionality Management"}
          className="mt-5"
        >
          {functionality?.data?.data?.length >= 1 ? (
            <>
              <div className="w-full flex items-end  justify-end">
                <button
                  onClick={handleAddModal}
                  className="flex items-center justify-center p-3 rounded-md bg-[#0eb683] text-[#fff]  active:scale-90 cursor-pointer border border-[#0eb683] transition-all duration-300 ease-in hover:bg-[#24a57e]"
                >
                  <IoMdAdd size={23} />
                  <span className="ml-1">Add Functionality</span>
                </button>
              </div>

              <div className=" bg-[#F7FBFB] rounded-xl w-full md:max-w-2xl h-fit px-7 py-5 flex flex-col gap-8">
                <div className="flex items-center justify-between mb-2">
                  <h1 className="text-[#071827] font-normal text-xl">
                    Functionalities
                  </h1>
                  <div className="flex items-center ">
                    <span className="text-[#5C6F7F] text-[16px] leading-[30px] self-end">
                      Toggle all
                    </span>
                    <ToggleSwitch
                      value={switchToggle}
                      onChange={() => setSwitchToggle(!switchToggle)}
                      label={""}
                      isLoading={false}
                    />
                  </div>
                </div>
                {functionality?.data?.data?.map((fnx: any, index: any) => {
                  console.log(fnx, "ii");
                  return (
                    <div key={index}>
                      <SettingsData
                        fnx={fnx}
                        id={fnx?.id}
                        DeleteModal={setDeleteModalOpen}
                        description={`${_.startCase(
                          fnx?.transactionType
                        )} ${_.startCase(fnx?.currency)}`}
                        setShowEdit={setShowEdit}
                        showEdit
                        subtext={_.startCase(fnx?.description)}
                        status={fnx?.status}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center mt-40">
              <div className="text-4xl text-gray-400 mb-4">
                <GiBoxUnpacking size={80} />
              </div>
              <p className="text-lg text-gray-600 mb-2">
                No functionality has been added yet.
              </p>
              <button
                onClick={handleAddModal}
                className="bg-[#0eb683] text-white px-4 py-2 rounded"
              >
                Add Functionality
              </button>
            </div>
          )}
        </TabPanel>
        <TabPanel
          hidden={formattedTabName !== "Liquidity Partners"}
          className="mt-5"
        >
          <div className="flex justify-between">
            <div>
              <h1 className=" text-xl font-semibold text-[#2B2B2B]">
                Liquidity Partner
              </h1>
              <p className="text-[#676767] text-sm ">
                Manage liquidity partners, click to expand and select partner of
                choice
              </p>
            </div>
          </div>
          <nav className=" border-b-2 border-[#E8E9EB] px-5 md:px-0 mt-5  pb-1 flex gap-6 justify-between md:justify-start mr-8">
            <TabSelector
              className={` cursor-pointer relative bg-transparent ${
                subTab === "Fiat" ? "text-[#0eb683]" : "text-[#5C6F7F]"
              } font-bold text-md ${
                subTab === "Fiat"
                  ? "text-[#0eb683] before:block before:w-full before:h-[5px] before:bg-[#0eb683] before:absolute before:bottom-[-8px] before:transition-all"
                  : ""
              } `}
              isActive={subTab === "Fiat"}
              onClick={() => setSubTab("Fiat")}
            >
              Fiat
            </TabSelector>
            <TabSelector
              className={` cursor-pointer relative bg-transparent ${
                subTab === "Stablecoin" ? "text-[#0eb683]" : "text-[#5C6F7F]"
              } font-bold text-md ${
                subTab === "Stablecoin"
                  ? "text-[#0eb683] before:block before:w-full before:h-[5px] before:bg-[#0eb683] before:absolute before:bottom-[-8px] before:transition-all"
                  : ""
              } `}
              isActive={subTab === "Stablecoin"}
              onClick={() => setSubTab("Stablecoin")}
            >
              Stablecoin
            </TabSelector>
          </nav>
          <div className="mx-10">
            <TabPanel hidden={subTab !== "Fiat"} className="mt-5">
              <div>
                <div className="flex items-end justify-end">
                  <button
                    onClick={toggleModal}
                    className="flex items-center bg-[#0eb683] text-[#fff] px-10 rounded-md transition-all active:scale-90 cursor-pointer  py-3 shadow-lg active:bg-[#8fc0ad]"
                  >
                    <span>
                      <MdOutlineAdd size={23} fill="#fff" />
                    </span>
                    <div className="button text-base text-[#fff] ">
                      {" "}
                      Add Liquidity account
                    </div>
                  </button>
                </div>
                <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] gap-3 ">
                  <LiquidityPartnerCard />
                </div>
              </div>
              <AddLpModal
                title={"Add fiat account"}
                subtitle={"Provide our liquidity partner bank details"}
                isOpen={modal}
                onClose={toggleModal}
              />

              {showLp ? (
                <LpModal
                  title={"Liquidity Partner Details"}
                  subtitle={"Honeycoin"}
                  isOpen={showLp}
                  onClose={setShowLp}
                />
              ) : null}
            </TabPanel>
            <TabPanel hidden={subTab !== "Stablecoin"} className="mt-5">
              <div>
                <div className="flex items-end justify-end">
                  <button className="flex items-center bg-[#0eb683] text-[#fff] px-10 rounded-md transition-all active:scale-90 cursor-pointer  py-3 shadow-lg">
                    <span>
                      <MdOutlineAdd size={23} fill="#fff" />
                    </span>
                    <div className="button text-base text-[#fff] ">
                      {" "}
                      Add Stablecoin Liquidity account
                    </div>
                  </button>
                </div>
                <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] gap-3 ">
                  {/* <LiquidityPartnerCard toggle={function (): void {
                    throw new Error("Function not implemented.");
                  } } isOpen={false} /> */}
                </div>
              </div>
            </TabPanel>
          </div>
        </TabPanel>
        <TabPanel hidden={formattedTabName !== "Accounts"} className="mt-5">
          <SettingsAccount handleDetails={handleDetails} />
        </TabPanel>
        <TabPanel hidden={formattedTabName !== "Wallets"} className="mt-5">
          <SettingsWallet />
        </TabPanel>
        <TabPanel
          hidden={formattedTabName !== "Role Management"}
          className="mt-5"
        >
          <div className="flex flex-row items-center justify-between gap-5 my-8">
            <div>
              <h1 className="text-[20px] leading-[30px] text-[#2B2B2B] font-medium">
                TTFCX Team
              </h1>
              <p className="text-[#676767]  text-[14px] leading-[18px] mr-3 ">
                {`Manage users and access levels`}
              </p>
            </div>
            <div className=" flex items-center justify-center gap-3">
              <div className="flex gap-2">
                <button
                  onClick={handleAddButtonClick}
                  className="flex items-center justify-center p-3 rounded-md bg-[#0eb683] text-[#fff]  active:scale-90 cursor-pointer border border-[#0eb683] transition-all duration-300 ease-in hover:bg-[#24a57e]"
                >
                  <IoMdAdd size={23} />
                  <span className="ml-1">Add Team Member</span>
                </button>
              </div>
            </div>
          </div>
          <div>
            <AppTable
              tableData={teamMembers}
              tabs={[]}
              //   @ts-ignore
              dColumns={dColumns}
              showFilter={true}
            />
          </div>
        </TabPanel>
      </div>
      <SideModal
        isOpen={addModal}
        onClose={handleAddModal}
        refetch={refetch}
        onConfirm={handleConfirm}
      />
      <ModifyModal
        isOpen={showEdit}
        onClose={() => setShowEdit(false)}
        refetch={refetch}
        onConfirm={handleConfirm}
      />
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDelete}
        />
      )}
      {isConfirm && (
        <ConfirmationModal
          isOpen={isConfirm}
          onClose={() => setIsConfirm(false)}
          onConfirm={handleDelete}
          refetch={refetch}
        />
      )}
      {isDelete && (
        <DeleteConfirmationModal
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          onConfirm={handleDelete}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default Settings;

export type TeamMember = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  id: string;
  sn: number;
};

export const teamMembers: TeamMember[] = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "johndoe@example.com",
    phoneNumber: "123-456-7890",
    role: "Admin",
    id: "1",
    sn: 1,
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "janesmith@example.com",
    phoneNumber: "987-654-3210",
    role: "Super Admin",
    id: "2",
    sn: 2,
  },
  {
    firstName: "Michael",
    lastName: "Johnson",
    email: "michaeljohnson@example.com",
    phoneNumber: "555-123-4567",
    role: "Customer Support",
    id: "3",
    sn: 3,
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emilydavis@example.com",
    phoneNumber: "444-555-7890",
    role: "Compliance",
    id: "4",
    sn: 4,
  },
  {
    firstName: "David",
    lastName: "Wilson",
    email: "davidwilson@example.com",
    phoneNumber: "987-654-3210",
    role: "Super Admin",
    id: "5",
    sn: 5,
  },
  {
    firstName: "Sarah",
    lastName: "Taylor",
    email: "sarahtaylor@example.com",
    phoneNumber: "123-456-7890",
    role: "Compliance",
    id: "6",
    sn: 6,
  },
  {
    firstName: "Alex",
    lastName: "Anderson",
    email: "alexanderson@example.com",
    phoneNumber: "555-555-5555",
    role: "Customer Support",
    id: "7",
    sn: 7,
  },
  {
    firstName: "Olivia",
    lastName: "Martinez",
    email: "oliviamartinez@example.com",
    phoneNumber: "222-333-4444",
    role: "Admin",
    id: "8",
    sn: 8,
  },
  {
    firstName: "William",
    lastName: "Brown",
    email: "williambrown@example.com",
    phoneNumber: "999-888-7777",
    role: "Customer Support",
    id: "9",
    sn: 9,
  },
  {
    firstName: "Sophia",
    lastName: "Garcia",
    email: "sophiagarcia@example.com",
    phoneNumber: "444-222-1111",
    role: "Quality Assurance",
    id: "10",
    sn: 10,
  },
  {
    firstName: "James",
    lastName: "Rodriguez",
    email: "jamesrodriguez@example.com",
    phoneNumber: "777-999-5555",
    role: "Compliance",
    id: "11",
    sn: 11,
  },
  {
    firstName: "Emma",
    lastName: "Lopez",
    email: "emmalopez@example.com",
    phoneNumber: "222-333-4444",
    role: "Admin",
    id: "12",
    sn: 12,
  },
  {
    firstName: "Daniel",
    lastName: "Hernandez",
    email: "danielhernandez@example.com",
    phoneNumber: "555-444-3333",
    role: "Super Admin",
    id: "13",
    sn: 13,
  },
  {
    firstName: "Ava",
    lastName: "Gonzalez",
    email: "avagonzalez@example.com",
    phoneNumber: "111-222-3333",
    role: "Admin",
    id: "14",
    sn: 14,
  },
  {
    firstName: "Benjamin",
    lastName: "Rivera",
    email: "benjaminrivera@example.com",
    phoneNumber: "999-888-7777",
    role: "Customer Support",
    id: "15",
    sn: 15,
  },
];

interface SideModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
}

const SideModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  refetch,
  onConfirm,
}) => {
  const [functionality, setFunctionality] = useState<IOptionType>(null);
  const [currency, setCurrency] = useState<IOptionType>(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const addFunctionality = useFunctionality();

  const isButtonDisabled = !functionality || !currency || !description;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    addFunctionality
      .mutateAsync({
        transactionType: functionality?.slug as string,
        currency: currency?.slug as string,
        description: description,
        status: true,
      })
      .then((res) => {
        refetch && refetch();
        onClose();
        onConfirm && onConfirm();
        setLoading(false);
        console.log(res);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black opacity-50 transition-opacity z-50 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={onClose}
      ></div>
      <div
        className={`fixed z-50 inset-y-0 right-0 w-1/4 bg-white overflow-y-auto transition-transform transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex justify-between items-center p-4 text-[#fff]">
          <h2 className="text-2xl font-bold"></h2>
          <button className="text-slate-500" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <h1 className="text-[#071827] text-[24px] leading-[32px]">
            Add functionality
          </h1>
          <p className="text-[#5C6F7F] text-[14px] leading-[24px]">
            Provide details for new functionality
          </p>
        </div>
        <div className="p-4 flex flex-col h-[80%]">
          <form className="mb-4 flex flex-col flex-grow">
            <div className="relative mb-3 ">
              <CustomSelect
                selectedOption={functionality}
                setSelectOption={setFunctionality}
                placeholder={"Select"}
                options={functionalityOption || []}
              />
              <span className="text-red-600 absolute left-2 top-2">*</span>
            </div>

            <div className="relative mb-3 ">
              <CustomSelect
                selectedOption={currency}
                setSelectOption={setCurrency}
                placeholder={"Select"}
                options={currencyOption || []}
              />
              <span className="text-red-600 absolute left-2 top-2">*</span>
            </div>

            <div className="relative mb-3 ">
              <TextArea
                label="Description"
                name="description"
                dvalue={description}
                rows={2}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </div>

            <div className="flex-grow"></div>

            <div className="">
              <button
                disabled={isButtonDisabled}
                onClick={(e) => handleSubmit(e)}
                className="w-full disabled:bg-[#a8d1c6] bg-[#0eb683] text-white py-2 rounded flex items-center justify-center"
              >
                {loading ? (
                  <span>
                    <ImSpinner6 size={22} className="animate-spin" />
                  </span>
                ) : (
                  " Add functionality"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const functionalityOption = [
  {
    id: 1,
    label: "Buy",
    slug: "buy",
  },
  {
    id: 2,
    label: "Sell",
    slug: "sell",
  },
  {
    id: 3,
    label: "Transfer",
    slug: "transfer",
  },
  {
    id: 4,
    label: "Swap",
    slug: "swap",
  },
  {
    id: 5,
    label: "Withdraw",
    slug: "withdraw",
  },
  {
    id: 6,
    label: "Fund",
    slug: "fund",
  },
  {
    id: 7,
    label: "Receive",
    slug: "receive",
  },
];
export const currencyOption = [
  {
    id: 1,
    label: "USD",
    slug: "usd",
  },
  {
    id: 2,
    label: "EURO",
    slug: "eur",
  },
  {
    id: 3,
    label: "GBP",
    slug: "gbp",
  },
  {
    id: 4,
    label: "Swap",
    slug: "swap",
  },
  {
    id: 5,
    label: "CNY",
    slug: "cny",
  },
];

const DeleteModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  refetch,
}) => {
  const { functionalityId } = useFnx();
  const _delete = useDeleteFunctionality(functionalityId);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    _delete
      .mutateAsync({})
      .then((res) => {
        refetch && refetch();
        onConfirm && onConfirm();
        setLoading(false);
        onClose();
      })
      .catch((e) => {});
  };
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div className="bg-white p-8 rounded-md shadow-lg z-10">
        <p className="text-[22px] text-center leading-[32px] text-[#071827] font-medium mb-4">
          Delete functionality
        </p>

        <p className="text-[14px] leading-[24px] text-[#071827] mt-2">
          Are you sure you want to delete this functionality?
        </p>

        <div className="bg-[#bef2d64c] flex gap-3 p-1 rounded items-center my-4 ">
          <div className="text-[#0eb683]">
            <IoInformationCircleOutline size={20} />
          </div>
          <div>
            <p className="text-[12px] leading-[20px] text-[#5C6F7F]">
              Proceeding to delete means the functionality <br />
              will be entirely removed from the system
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center gap-3">
          <button
            className="bg-[#fff] border border-[#0eb683] text-[#0eb683] px-8 py-2.5 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#E01A00] text-white px-8 py-2.5 rounded mr-2 border border-[#E01A00]"
            onClick={handleDelete}
          >
            {loading ? (
              <span>
                <ImSpinner6 size={22} className="animate-spin" />
              </span>
            ) : (
              "Yes, Delete"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
const ConfirmationModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  refetch,
}) => {
  const handleClose = () => {
    refetch && refetch();
    onClose();
  };
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div className="bg-white p-8 rounded-lg shadow-lg z-10">
        <p className="text-[22px] text-center leading-[32px] text-[#071827] font-medium mb-4">
          Functionality added
        </p>

        <div className="flex gap-3 p-3 rounded-md items-center my-4 bg-[#F2FAF7]">
          <div className="text-[#0eb683]">
            <FaCheck />
          </div>
          <div>
            <p className="text-[12px] leading-[20px] text-[#5C6F7F]">
              The new functionality has been successfully added
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center ">
          <button
            className="text-[#fff] border border-[#0eb683] bg-[#0eb683] px-8 py-2 rounded-lg"
            onClick={handleClose}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
const DeleteConfirmationModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  refetch,
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div className="bg-white p-8 rounded-lg shadow-lg z-10">
        <p className="text-[22px] text-center leading-[32px] text-[#071827] font-medium mb-4">
          Functionality Deleted
        </p>

        <div className="flex gap-3 p-3 rounded-md items-center my-4 bg-[#F2FAF7]">
          <div className="text-[#b60e0e]">
            <RiDeleteBin6Line />
          </div>
          <div>
            <p className="text-[12px] leading-[20px] text-[#5C6F7F]">
              Functionality has been successfully deleted
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center ">
          <button
            className="text-[#fff] border border-[#0eb683] bg-[#0eb683] px-8 py-2 rounded-lg"
            onClick={handleClose}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

const ModifyModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  refetch,
  onConfirm,
}) => {
  const [functionality, setFunctionality] = useState<IOptionType>(null);
  const [currency, setCurrency] = useState<IOptionType>(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { function: data, functionalityId } = useFnx();
  const updateFnx = useUpdateFunctionality(functionalityId);

  const isButtonDisabled = !functionality || !currency || !description;
  console.log(data, "data");

  const modifiedData = {
    ...data,
    currency: {
      id: data?.currency,
      label: data?.currency.toUpperCase(),
      slug: data?.currency.toLowerCase(),
    },
    transactionType: {
      id: data?.transactionType,
      label: data?.transactionType.toUpperCase(),
      slug: data?.transactionType.toLowerCase().replace(/\s+/g, "_"), // Replace spaces with underscores
    },
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    updateFnx
      .mutateAsync({
        transactionType: functionality?.slug as string,
        currency: currency?.slug as string,
        description: description,
        status: true,
      })
      .then((res) => {
        refetch && refetch();
        onClose();
        onConfirm && onConfirm();
        setLoading(false);
        console.log(res);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black opacity-50 transition-opacity z-50 ${
          isOpen ? "visible" : "invisible"
        }`}
        onClick={onClose}
      ></div>
      <div
        className={`fixed z-50 inset-y-0 right-0 w-1/4 bg-white overflow-y-auto transition-transform transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex justify-between items-center p-4 text-[#fff]">
          <h2 className="text-2xl font-bold"></h2>
          <button className="text-slate-500" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <h1 className="text-[#071827] text-[24px] leading-[32px]">
            Edit functionality
          </h1>
          <p className="text-[#5C6F7F] text-[14px] leading-[24px]">
            Update and save changes to an existing functionality
          </p>
        </div>
        <div className="p-4 flex flex-col h-[80%]">
          <form className="mb-4 flex flex-col flex-grow">
            <div className="relative mb-3 ">
              <CustomSelect
                selectedOption={functionality}
                setSelectOption={setFunctionality}
                placeholder="Select"
                defaultValue={{
                  id: 0,
                  label: modifiedData?.transactionType?.label || "",
                  slug: modifiedData?.transactionType?.slug || "",
                }}
                options={functionalityOption || []}
              />
              <span className="text-red-600 absolute left-2 top-2">*</span>
            </div>

            <div className="relative mb-3 ">
              <CustomSelect
                selectedOption={currency}
                setSelectOption={setCurrency}
                placeholder="Select"
                defaultValue={{
                  id: 1,
                  label: modifiedData?.currency?.label || "",
                  slug: modifiedData?.currency?.slug || "",
                }}
                options={currencyOption || []}
              />
              <span className="text-red-600 absolute left-2 top-2">*</span>
            </div>

            <div className="relative mb-3 ">
              <TextArea
                label="Description"
                name="description"
                // dvalue={modifiedData?.description}

                rows={2}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </div>

            <div className="flex-grow"></div>

            <div className="">
              <button
                disabled={isButtonDisabled}
                onClick={(e) => handleSubmit(e)}
                className="w-full disabled:bg-[#a8d1c6] bg-[#0eb683] text-white py-2 rounded flex items-center justify-center"
              >
                {loading ? (
                  <span>
                    <ImSpinner6 size={22} className="animate-spin" />
                  </span>
                ) : (
                  " Save Change"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
