import AuthLayout from "./layouts/AuthLayout";
import LandingPage from "./pages/landing/LandingPage";
import DashBoardLayout from "./layouts/DashBoardLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import NotFound from "./pages/NotFound";
import Transactions from "./pages/dashboard/transactionsSubmenu/Transactions";
import BankApprovals from "./pages/dashboard/approvalsSubmenu/BankApproval";
import Revenues from "./pages/dashboard/Revenues";
import Payouts from "./pages/dashboard/Payouts";
import { Route, Routes } from "react-router-dom";
import Fees from "./pages/dashboard/Fees";
import Balances from "./pages/dashboard/Balances";
import Rates from "./pages/dashboard/Rates";
import Settings from "./pages/dashboard/Settings";
import Individual from "./pages/dashboard/submenu/Individual";
import Businesses from "./pages/dashboard/submenu/Businesses";
import Accounts from "./pages/dashboard/accountsSubmenu/Accounts";
import AccountView from "./pages/dashboard/accountsSubmenu/AccountView";
import TransactionView from "./pages/dashboard/transactionsSubmenu/TransactionView";
import BeneficiaryApprovals from "./pages/dashboard/approvalsSubmenu/BeneficiaryApproval";
import AccountDetails from "./components/dashboard-components/settings-component/AccountDetails";
import RoleLayout from "./components/dashboard-components/settings-component/stepper/RoleLayout";
import RoleUser from "./components/dashboard-components/settings-component/RoleUser";
import OnboardingDetails from "./pages/dashboard/Compliance/OnboardingDetails";
import Onboarding from "./pages/dashboard/Compliance/Onboarding";
import Transfers from "./pages/dashboard/Compliance/Transfers";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<AuthLayout isLogin={true} />} />
        <Route
          path="/request-access"
          element={<AuthLayout isLogin={false} />}
        />
        <Route path="/dashboard" element={<DashBoardLayout />}>
          <Route index element={<DashboardPage />} />
          <Route
            path={`/dashboard/users/individuals`}
            element={<Individual />}
          />
          <Route
            path={`/dashboard/users/businesses`}
            element={<Businesses />}
          />

          <Route path="/dashboard/accounts" element={<Accounts />} />
          <Route path="/dashboard/transactions" element={<Transactions />} />
          <Route
            path="/dashboard/transactions/:currency"
            element={<TransactionView />}
          />
          <Route
            path={`/dashboard/accounts/:currency`}
            element={<AccountView />}
          />
          <Route path="/dashboard/transactions" element={<Transactions />} />
          <Route path="/dashboard/approvals/bank" element={<BankApprovals />} />
          <Route
            path="/dashboard/compliance/onboarding/:id"
            element={<OnboardingDetails />}
          />
          <Route
            path="/dashboard/compliance/onboarding"
            element={<Onboarding />}
          />
          <Route
            path="/dashboard/compliance/transfer"
            element={<Transfers />}
          />
          <Route
            path="/dashboard/approvals/beneficiary"
            element={<BeneficiaryApprovals />}
          />

          <Route path="/dashboard/revenues" element={<Revenues />} />
          <Route path="/dashboard/payouts" element={<Payouts />} />
          <Route path="/dashboard/fees" element={<Fees />} />
          <Route path="/dashboard/balances" element={<Balances />} />
          <Route path="/dashboard/rates" element={<Rates />} />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route
            path="/dashboard/settings/:id"
            element={<AccountDetails />}
          />

          <Route path="/dashboard/settings/add" element={<RoleLayout />} />
          <Route path="/dashboard/settings/views/:id" element={<RoleUser />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
