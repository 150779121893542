import React, { useRef, useState } from "react";
import { AiOutlineFieldTime, AiOutlineMail } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { GoCalendar, GoVerified, GoUnverified } from "react-icons/go";
import { useUserStore } from "../../../store/useUser";
import { useAdminUsersId, useFees } from "../../../sevices/hooks/admin";
import { Field, ErrorMessage, Formik, Form } from "formik";
import * as Yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface UserModalProps {
  isOpen: boolean;
  onClose: (shouldShow: boolean) => void;
}

const FeesModal: React.FC<UserModalProps> = ({ isOpen, onClose }) => {
  const postFees = useFees();
  const usersId = useUserStore((state) => state.selectedUsersId);
  console.log(usersId);

  // const getUsersId = useAdminUsersId("bea90789-0414-464d-83e1-96cfaca0c3ed");
  const overLayRef = React.useRef(null);
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && onClose(false);
  };

  const [currencies, setCurrencies] = useState([
    { id: "NGN", name: "NGN" },
    { id: "USD", name: "USD" },
    { id: "EUR", name: "EUR" },
    { id: "GBP", name: "GBP" },
    { id: "USDC", name: "USDC" },
    { id: "USDT", name: "USDT" },
  ]);

  const feeTypes = [
    { id: "percentage", name: "Percentage" },
    { id: "fixed", name: "Fixed" },
    { id: "proportional", name: "Proportional" },
  ];

  const initialValues = {
    feeName: "",
    feeType: "",
    feeAmount: "",
    feeCurrency: "",
    baseValue: "",
  };

  const validationSchema = Yup.object({
    feeName: Yup.string().required("Fees name is required"),
    feeType: Yup.string().required("Fees type is required"),
    feeAmount: Yup.number().required("Fees amount is required"),
    // feeCurrency: Yup.string().when("feeType", {
    //   is: "fixed",
    //   then: Yup.string().required("Fees currency is required"),
    // }),
    feeCurrency: Yup.string().when("feeType", {
      //@ts-ignore
      is: "fixed",
      then: Yup.string().required("Fees currency is required"),
      otherwise: Yup.string().notRequired(),
    }),

    // baseValue: Yup.number().when("feeType", {
    //   is: "proportional",
    //   then: Yup.number().required("base Value is required"),
    // }),

    baseValue: Yup.number().when("feeType", {
      //@ts-ignore
      is: "proportional",
      then: Yup.number().required("base Value is required"),
      otherwise: Yup.number().notRequired(),
    }),
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setTimeout(() => {
      let feeAmount = values.feeAmount;
      if (values.feeType === "percentage") {
        feeAmount = feeAmount / 100;
      }
      postFees
        .mutateAsync({
          //@ts-ignore
          name: values.feeName,
          type: values.feeType,
          value: {
            amount: feeAmount,
            currency: values.feeCurrency,
            baseValue: values.baseValue,
          },
          key: values.feeName.replace(/\s+/g, "_"),
          description: values.feeName,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
      console.log("Submitting: ", {
        feeName: values.feeName,
        feeType: values.feeType,
        feeAmount: {
          amount: feeAmount,
          feeCurrency: values.feeCurrency,
          baseValue: values.baseValue,
          description: values.feeName,
        },
        key: values.feeName.replace(/\s+/g, "_"),
      });
      setSubmitting(false);
    }, 400);
  };

  return (
    <>
      {isOpen ? (
        <>
          <div className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50">
            <div
              ref={overLayRef}
              className="modal_overlay absolute flex justify-end top-0 right-0 w-full h-full cursor-pointer bg-[#13111173]"
              onClick={handleOverLayClick}
            >
              <div className="modal_box relative bg-[#fff] h-full w-[440px]  rounded-l-lg overflow-auto">
                <div
                  onClick={() => onClose(false)}
                  className="close absolute top-5 right-5 flex items-center justify-center rounded-full
                bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                >
                  <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                    <IoMdClose size={29} className="" />
                  </span>
                </div>

                <h1 className="absolute top-10 left-5 text-xl text-slate-700 font-semibold">
                  Create Fees
                </h1>
                <p className="absolute top-20 left-5 text-sm text-slate-700 font-normal">
                  Setup and create a fee
                </p>

                <div className="mt-28 px-5">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="">
                        <div className="mb-2">
                          <Field
                            className="w-full p-3 border border-[#E2E9F0] rounded focus:outline-none focus:shadow-outline placeholder:text-sm  placeholder:text-[#5C6F7F] text-sm"
                            type="text"
                            id="feeName"
                            name="feeName"
                            placeholder="Fees Name"
                          />
                          <ErrorMessage
                            className="text-red-500 font-extralight"
                            name="feeName"
                            component="div"
                          />
                        </div>

                        <div className="mb-2">
                          <Field
                            as="select"
                            className="w-full p-3 border border-[#E2E9F0] rounded text-sm"
                            id="feeType"
                            name="feeType"
                          >
                            <option value="" className="text-[#5C6F7F] text-sm">
                              Select Fees Type
                            </option>
                            {feeTypes.map((type) => (
                              <option
                                key={type.id}
                                value={type.id}
                                className="text-[#5C6F7F] text-sm"
                              >
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            className="text-red-500 font-extralight"
                            name="feeType"
                            component="div"
                          />
                        </div>

                        <div className="mb-2">
                          <Field
                            className="w-full p-3 border border-[#E2E9F0] rounded placeholder:text-sm  placeholder:text-[#5C6F7F] text-sm"
                            type="number"
                            id="feeAmount"
                            name="feeAmount"
                            placeholder="Fees Amount"
                          />
                          <ErrorMessage
                            className="text-red-500 font-extralight"
                            name="feeAmount"
                            component="div"
                          />
                        </div>
                        <div className="mb-2">
                          <Field
                            as="select"
                            className="w-full p-3 border border-[#E2E9F0] rounded text-sm"
                            id="feeCurrency"
                            name="feeCurrency"
                            // disabled={
                            //   feeTypes.find(
                            //     (type) => type.id === "percentage"
                            //   ) !== undefined
                            // }
                          >
                            <option value="" className="text-[#5C6F7F] text-sm">
                              Select Currency
                            </option>
                            {currencies.map((currency) => (
                              <option key={currency.id} value={currency.id}>
                                {currency.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            className="text-red-500 font-extralight"
                            name="feeCurrency"
                            component="div"
                          />
                        </div>
                        <div className="mb-2">
                          <Field
                            className="w-full p-3 border border-[#E2E9F0] rounded placeholder:text-sm  placeholder:text-[#5C6F7F] text-sm"
                            type="number"
                            id="baseValue"
                            name="baseValue"
                            placeholder="Enter Base Value"
                          />
                          <ErrorMessage
                            className="text-red-500 font-extralight"
                            name="baseValue"
                            component="div"
                          />
                        </div>

                        <button
                          className="bg-[#0eb683] hover:bg-[#fff] hover:border border-[#0eb683] hover:text-[#0eb683] text-white font-bold py-2 px-4 rounded w-full mt-20"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </>
      ) : null}
    </>
  );
};

export default FeesModal;
