import { log } from "console";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  gap: 3;
  box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  -webkit-box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  -moz-box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  padding: 10px 15px;
  cursor: pointer;
  height: 150px;
  border-radius: 8px;
`;
export interface Iprops {
  color: string;
  currency: string;
  symbol: string;
  border?: string;
  redirect: string
}

const Widgets = ({ color, currency, symbol, border, redirect }: Iprops) => {
  const navigate = useNavigate()
  let data;
  return (
    <Container
      onClick={()=> navigate(redirect, {replace: true})}
      className="cursor-pointer "
      style={{ backgroundColor: color, border: border }}
    >
      <div className="flex items-center justify-between">
        <div className="text-[#071827] text-base  font-normal font-sans">
          {currency}
        </div>
        <div className="text-xl font-bold  font-sans">{symbol}</div>
      </div>
      <div className="text-[#071827] font-bold font-sans text-xl">
        {symbol} 350,0000,00
      </div>
    </Container>
  );
};

export default Widgets;
