import _ from "lodash";

import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  useForm,
  UseFormGetValues,
  //@ts-ignore
} from "react-hook-form";
import Select from "react-select";
import { IOptionType } from "./dashboard-components/settings-component/stepper/AccessPermission";


type CustomSelectProps = {
  selectedOption: IOptionType | null;
  setSelectOption: (slug: React.SetStateAction<any>) => void;
  options: any;
  isDisabled?: boolean;
  name?: string;
  control?: Control<any, any>;
  getValues?: UseFormGetValues<any>;
  placeholder?: string;
  errors?: any;
  defaultValue?: IOptionType;
};

const CustomSelect = (props: CustomSelectProps) => {
  const {
    selectedOption,
    setSelectOption,
    options,
    isDisabled,
    name,
    getValues,
    control,
    placeholder,
    errors,
    defaultValue,
  } = props;

  //   const [defaultVal, setDefaultVal] = useState<SelectOptionType>(null);

  const handleChange = (option: any) => {
    setSelectOption(option);
  };

  var alteredOptions = options.map((option: any) => ({
    label: option.label ? option.label : option.name,
    slug: option.slug ? option.slug : option.name,
    // description: option.description ? option.description : "",
  }));

  var actualOption = alteredOptions.find(
    (option: IOptionType) =>
      option?.slug === selectedOption?.slug ||
      (option?.slug === selectedOption?.slug && selectedOption?.slug.toString())
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: errors && errors[_.camelCase(name)] && "#FDA29B",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "6px",
      backgroundColor: "#F2FAF7",
      fontSize: "13px",
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      display: "hidden",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
    }),

    option: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };
  

  return (
    <div className="w-full">
      {control ? (
        <Controller
          control={control}
          name={_.camelCase(name)}
          defaultValue={
            getValues &&
            alteredOptions.find(
              (option: IOptionType) =>
                option?.label === getValues(_.camelCase(name))
            )
          }
          //@ts-ignore
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              styles={customStyles}
              value={actualOption}
              defaultValue={
                getValues &&
                alteredOptions.find(
                  (option: IOptionType) =>
                    option?.label === getValues(_.camelCase(name))
                )
              }
              options={alteredOptions}
              placeholder={placeholder}
              isClearable={false}
              onChange={(option) => onChange(option.label)}
              isDisabled={isDisabled}
            />
          )}
        />
      ) : (
        <Select
          styles={customStyles}
          value={actualOption}
          options={alteredOptions}
          isClearable={false}
          placeholder={placeholder}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default CustomSelect;
