import React, { useEffect, useState } from "react";
// import OTPInput from "../../components/widgets/OTPInput";
import { useRequestAccess } from "../../sevices/hooks/users";

// import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const RequestAccess = () => {
  const [otpValue, setOtpValue] = useState("");
  const [detailsValid, setDetailsValid] = useState(false);
  const requestAccess = useRequestAccess();
  const [fields, setFields] = useState({
    email: "",
    referralCode: "",
    firstName: "",
    lastName: "",
  });
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate()
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  // const [detailsValid, setDetailsValid] = useState(false);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    requestAccess
      .mutateAsync(fields)
      .then((res) => {
        setRequestSuccess(true);
        // setValidationModal("validated");
      })
      .catch((e) => {
        // console.log(e, "error");
        setRequestFailed(true);
      });
  };

  const validateField = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (
      regEx.test(fields.email) &&
      fields.firstName !== "" &&
      fields.lastName !== ""
    ) {
      setDisable(false);
    } else if (
      (!regEx.test(fields.email) && fields.email === "") ||
      (fields.email === "" && fields.firstName === "" && fields.lastName)
    ) {
      setDisable(true);
    }
  };

  // console.log(requestAccess.error);

  const onFieldChange = ({ name, value }: { value: any; name: string }) => {
    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    onFieldChange({ name: "referralCode", value: otpValue });
    // if (
    //   fields.email === "" &&
    //   fields.firstName === "" &&
    //   fields.lastName === ""
    // ) {
    //   setDisable(true);
    // }else{
    //   setDisable(false)
    // }
  }, []);

  // console.log(fields);

  return !detailsValid ? (
    <div className=" ">
      
      <div className="input-field xs:py-4 xxs:py-0 ">
        <div className="flex flex-col md:flex-row items-center gap-3 pt-3 md:mb-5">
          <input
            onChange={(e) => {
              onFieldChange({ name: "firstName", value: e.target.value });
              validateField()
            }}
            type="text"
            name="firstName"
            placeholder="First Name"
            className="border py-4 pl-3 w-full rounded-md outline-[#0eb683]"
          />
          <input
            onChange={(e) =>{
              onFieldChange({ name: "lastName", value: e.target.value })
              validateField()
           } }
            type="text"
            name="LastName"
            placeholder="Last Name"
            className="border p-3 py-4 inline rounded-md w-full outline-[#0eb683] "
          />
        </div>
        <input
          onChange={(e) =>{
            onFieldChange({ name: "email", value: e.target.value })
            validateField()
          }}
          type="email"
          name="email"
          placeholder="Email"
          className="border p-4 rounded-md w-full outline-[#0eb683] "
        />
      </div>

      <div className="flex flex-col justify-center items-center mt-16">
        <button
          disabled={disable}
          onClick={() => {
            setDetailsValid(true);
          }}
          type="submit"
          className=" bg-[#0eb683] p-3 xs:mt-5   w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
        >
          Continue
        </button>

        <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9 ">
          By clicking Continue, I agree to the website's
          <br />
          <button 
           onClick={()=>navigate("/terms-of-use", {replace: true})}
          className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90">
            Terms of use
          </button>{" "}
          and{" "}
          <button 
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacystatement", {replace: true})
          }}
          className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90">
            Privacy statement
          </button>{" "}
        </small>
      </div>

      
    </div>
  ) : (
    <>
      <form className="refrence  " onSubmit={onSubmitForm}>
        <div className="otp-box xs:py-4 xxs:py-2 flex flex-col gap-5">
          <p className="text-white md:text-[#000] text-center">
            Enter your Referrer's Referral Code
          </p>
          {/* <OTPInput
            autoFocus
            length={5}
            className="otpContainer flex gap-[8px] justify-center w-full xs:py-5 xxs:py-2 m-auto"
            inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border-2 border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
            autoCapitalize={true}
            onChangeOTP={(otp: string) => setOtpValue(otp.toUpperCase())}
            onPaste={(otp: string) => setOtpValue(otp.toUpperCase())}
            // onChange={(e)=>{
            //   onFieldChange({name: "referralCode", value: e.target.value})
            // }}
          /> */}
          <button
            onClick={onSubmitForm}
            type="submit"
            className=" bg-[#0eb683] p-3 xs:mt-5 xxs:mt-2  w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90"
          >
            Request Access
          </button>
        </div>
      </form>
      <div className="flex justify-center items-center mt-3">
        <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9">
          By clicking Request Access, I agree to the website's
          <br />
          <button
          onClick={()=>navigate("/terms-of-use", {replace: true})}
           className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90">
            Terms of use
          </button>{" "}
          and{" "}
          <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacystatement", {replace: true})
          }}
           className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90">
            privacy statement
          </button>{" "}
        </small>
      </div>

      <>
        <AnimatePresence>
          {requestSuccess && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm 
           bg-[#13111137] fixed w-full bottom-32 left-0 right-0 top-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex  items-center justify-center  "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  flex-col gap-8 bg-white   h-full mt-7 w-60 rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"10px"} height={"10px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Access granted
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      Your request for access was successful.
                      <br />
                      Please check your email
                    </p>

                    <button
                      onClick={() => {
                        setRequestSuccess(false);
                        setDetailsValid(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Done
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {requestFailed && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm 
           bg-[#13111137] w-full absolute top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex  items-center justify-center  "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  justify-center flex-col gap-8 bg-white  h-full absolute top-1 bottom-1 w-80 rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      {/* <FailedIcon width={"100px"} height={"100px"} /> */}
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Invalid referral code
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      We couldn't verify your referral code. <br />
                      You have 2 more retry attempts
                    </p>

                    <button
                      onClick={() => {
                        setRequestFailed(false);
                        // setRequestSuccess(true)
                        setDetailsValid(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Retry
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </>
    </>
  );
};

export default RequestAccess;
