import React, { useEffect, useMemo, useState } from "react";

import SearchComponent from "../../../components/dashboard-components/SearchComponent";
import { useAdminUsers } from "../../../sevices/hooks/admin";
import Table from "../../../components/dashboard-components/Table";



const Individual = () => {
  const [rows, setRows] = useState<any>([]);
  const AllUsers = useAdminUsers();

 console.log({AllUsers});

  const [loading, setLoading] = useState(false);

  const output = useMemo(() => {
    const data = AllUsers?.data?.data?.data;


    return data?.map(
      (t: {
        id: string;
        email: any;
        accountType: string;
        createdAt: string;
        phoneNumber: string;
        firstName: string;
        accountNumber: string;
        status: string;
        lastName: number;
        verificationCode: any;
        subAccountId:any,
        refereeCode:any
      }) => {
        const {
          id ,
          email,
          accountType,
          createdAt,
          phoneNumber,
          firstName,
          accountNumber,
          status,
          lastName,
          verificationCode,
          subAccountId,
          refereeCode,
        } = t;
        return{
          id,
          date: createdAt ,
          email,
          firstName,
          lastName,
          phoneNumber,
          status,
          actions:"hello"
        }
      }
    ).sort((a:any, b:any)=> {

      if(a.date >= b.date){
        return 1
      }
      if(a.date <= b.date){
        return -1
      }
      return 0
      });


  }, [AllUsers?.data?.data?.data]);

  useEffect(() => {
   if(output === undefined){
    setRows([])
   }else{
    setRows(output)
   }

  }, [output])


  return (
    <>
      <div className="h-100vh w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
        <div className="flex flex-row items-center justify-between  mt-5">
          <h1 className="font-bold text-2xl text-[#071827] md:pb-2">
            Private Users
          </h1>
        </div>
        <SearchComponent placeholder={"Search users"} />
        <div>

          <Table tabs={["All", "NGN", "USD", "GBP", "EUR", "USDC", "USDT"]}
            itemsPerPage={10}
            currentPage={1}
            data={rows} />
        </div>
      </div>
    </>
  );
};

export default Individual;
