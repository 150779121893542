import React from 'react'
import { useParams } from 'react-router-dom'
import EUR from './EUR'
import NGN from './NGN'
import GBP from './GBP'
import USD from './USD'
import USDC from './USDC'
import USDT from './USDT'

const AccountView = () => {

    const {currency} = useParams()

  return (
    <>
    {currency === "usd" && <USD currency = {currency}/>}
    {currency === "ngn" && <NGN currency = {currency}/>}
    {currency === "eur" && <EUR currency = {currency}/>}
    {currency === "gbp" && <GBP currency = {currency}/>}
    {currency === "usdt" && <USDT currency = {currency}/>}
    {currency === "usdc" && <USDC currency = {currency}/>}
   
    </>
  )
}

export default AccountView