import axios from "axios";

export const BASEURL =
  process.env.REACT_APP_BASE_URL || "https://dev-backend.ttfcx.com";

export const api = {
  users: {
    getUser: (userId: string) => `/users/${userId}`,
    verify: "/users/login/verify",
    requestAccess: "/users/request-access",
    invitationVerify: "/users/invitations/verify",
    individualKyc: "/users/individual/kyc",
    users: "/users",
    businessKyc: "/users/business/kyc",
    userLogin: "/users/login",
    recoverPassword: "/users/recover-password",
    resetPassword: "/users/reset-password",
    usersInvitations: "/users/invitations",
    generateBeneficiaryForm: "/users/forms/beneficiary/generate",
    invitees: (userId: string) => `/users/${userId}/invitees`,
    otpRequest: (userId: string) => `/users/${userId}/request-otp`,
    signUpVerify: "/users/signup/verify",
    updateBank: "/users/update-bank",
    checkEmail: "/users/check-email",
    usersBusinessDetails: (businessId?: string) =>
      `/users/business/${businessId}`,
    rejection: "/users/business/rejection",
    rejectionReasons: "/users/rejection/reasons",
  },
  metadata: {
    banks: "/metadata/banks",
    resolveBankName: "/metadata/account/resolve-name",
    rates: "/metadata/rates",
  },
  wallet: {
    AccountNgn: "/wallet/accounts/ngn",
    walletAccount: (currency: string) => `/wallet/accounts/${currency}`,
    AccountUsd: "/wallet/accounts/usd",
    AccActivity: "/wallet/accounts/activity",
    BuyUsd: "/wallet/buy/usd",
    Beneficiaries: "/wallet/beneficiaries",
    buyCurrency: (currencyCode: string) => `/wallet/buy/${currencyCode}`,
    beneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/beneficiaries/${beneficiaryId}`,
    transfersUsd: "/wallet/transfer/usd",
    accountWithdrawal: "/wallet/accounts/withdrawal",
    transactionReference: (reference: string) =>
      `/wallet/transactions/${reference}`,
    createNgnBeneficiary: "/wallet/ngn/beneficiaries",
    ngnBeneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/ngn/beneficiaries/${beneficiaryId}`,
  },
  flag: {},
  tags: {
    rates: "/metadata/rates",
    ratesEur: "/metadata/rates/eur",
    gbpRates: "/metadata/rates/gbp",
    swiftCountries: "/metadata/countries/swift",
    ibanCountries: "/metadata/countries/iban",
    abaCountries: "/metadata/countries/aba",
    states: "/metadata/states",
    abaDistricts: "/metadata/districts/aba",
  },
  externals: {
    externalBeneficiary: "/external/beneficiaries",
  },
  admin: {
    adminUsers: "/admin/users",
    adminUser: (id?: string) => `/admin/users/${id}`,
    adminFees: "/admin/fees",
    functionality: "/functionalityManagement",
    allFunctionality: "/functionalityManagement/general",
    modifyFunctionality: (id: string) => `/functionalityManagement/${id}`,
    adminRates: (currency: string) => `/admin/rates/${currency}`,
    adminTx: (userId: string) => `/admin/${userId}/transactions`,
    adminNgn: (userId: string) => `/admin/${userId}/wallet/ngn`,
    adminUsd: (userId: string) => `/admin/${userId}/wallet/usd`,
    adminGbp: (userId: string) => `/admin/${userId}/wallet/gbp`,
    adminEur: (userId: string) => `/admin/${userId}/wallet/eur`,
    adminUsdt: (userId: string) => `/admin/${userId}/wallet/usdt`,
    adminUsdc: (userId: string) => `/admin/${userId}/wallet/usdc`,
    getUser: (userId: string) => `/admin/users/${userId}`,
    patchFees: (Id: string) => `/admin/fees/${Id}`,
    deleteFees: (Id: string) => `/admin/fees/${Id}`,
    feeById: (Id: string) => `/admin/fees/${Id}`,
    businessKycStatus: (businessId?: string) =>
      `/admin/business/kyc/status/${businessId}`,
    businessContactKycStatus: (businessIndividualId?: string) =>
      `/admin/business/contact/kyc/status/${businessIndividualId}`,
    businessCorporateKycStatus: (businessCorporateId?: string) =>
      `/admin/business/corporate/kyc/status/${businessCorporateId}`,
    teamMember: "/admin/team-member",
    addRoleToMember: "/admin/team-member/role",
    getRole: `admin/roles`,
    deleteRole: (id: string) => `/admin/role/${id}`,
    addPermissionToRole: (id: string) => `/admin/role-permission/${id}`,
    userTransactions: (id:string) => `/admin/${id}/transactions`
  },
};

export const makePostRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.post(`${BASEURL}${url}`, data, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

// export const makePostRequest = async (data: any, url: string) => {
//   const temp = await fetch(`${BASEURL}${url}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   });
//   if (temp.headers.get("Content-Type")?.indexOf("json") !== -1) {
//     const result = await temp.json();
//     return result;
//   }
//   return Promise.reject( await temp.text());
// };

export const makePostRequestWithCustomHeaders = async (
  data: any,
  url: string,
  headers?: any
) => {
  const temp = await fetch(`${BASEURL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });

  if (!temp.ok) {
    throw new Error(await temp.text());
  }

  const result = await temp.json();
  return result;
};

export const makePatchRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.patch(`${BASEURL}${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

export const makeGetRequest = async <T = any>(
  url: string,
  includeAuthHeaders: boolean = true
) => {
  const temp = await axios.get<T>(`${BASEURL}${url}`, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
  return temp;
};

export const makeDeleteRequest = async (
  url: string,
  includeAuthHeaders: boolean = true
) => {
  const temp = await axios.delete(`${BASEURL}${url}`, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
  return temp;
};
