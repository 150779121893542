import React from 'react'
import { FC } from 'react';
import SvgIconConstituentValues from './types';
export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const RevenueIcon = ({
    height ="24px",
    width ="24px",
    stroke="#5C6F7F",
    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.66675 5.83333C1.66675 5.3731 2.03985 5 2.50008 5H17.5001C17.9603 5 18.3334 5.3731 18.3334 5.83333V14.1667C18.3334 14.6269 17.9603 15 17.5001 15H2.50008C2.03985 15 1.66675 14.6269 1.66675 14.1667V5.83333Z"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99992 11.6668C10.9204 11.6668 11.6666 10.9206 11.6666 10.0002C11.6666 9.07969 10.9204 8.3335 9.99992 8.3335C9.07944 8.3335 8.33325 9.07969 8.33325 10.0002C8.33325 10.9206 9.07944 11.6668 9.99992 11.6668Z"
        stroke="#5C6F7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.01342 10.0134L5 10"
        stroke="#5C6F7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0134 10.0134L15 10"
        stroke="#5C6F7F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default RevenueIcon