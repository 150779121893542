import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}


const RatesIcon = ({
    height="16px",
    width="16px"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.5 10H5L7.5 17.5L13.3333 2.5L15 10H17.5"
        stroke="#5C6F7F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default RatesIcon