import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons/lib";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { DashBoardData } from "./DashBoardData";

const SideBarLink = styled(NavLink)`
  display: flex;
  color: #676767;
  align-items: center;
  justify-items: space-between;
  padding: 20px;
  height: 60px;

  &:hover {
    color: #0eb683;
    cursor: pointer;
  }
  &:active {
    color: #0eb683;
    font-weight: bold;
  }
`;
const SideBarLabel = styled.span`
  margin-left: 20px;
  color: #676767;
  font-size: 16px;
  /* font-family: ""; */
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const DropDownLink = styled(Link)`
  color: #676767;
  height: 60px;
  padding-left: 3rem;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  &:hover {
    color: #0eb683;
    cursor: pointer;
  }
  &:active {
    color: #0eb683;
    font-weight: bold;
  }
`;

const Submenu = ({ item }: { item: any }) => {
  const location = useLocation();
  const [subnav, setSubnav] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [menuColor, setMenuColor] = useState("text-[#676767]");

  const activeLink = `text-[#0eb683] h-16 pl-1 pb-5 flex items-center hover:cursor-pointer font-bold`;
  const normal = `text-[#676767] h-16 pl-1 pb-5 flex items-center hover:cursor-pointer `;

  const active = "text-[#0eb683] font-bold";
  const notActive = `text-[#676767]`;

  const showSubnav = () => setSubnav(!subnav);

  // useEffect((evt) => {
  //   location?.pathname.includes(e.target.innerHTML.toLowerCase());

  // }, [location, e])

  return (
    <>
      <IconContext.Provider value={{ size: "23" }}>
        <SideBarLink
          to={item.url}
          className={({ isActive }) => (isActive ? active : notActive)}
          onClick={item.subNav && showSubnav}
        >
          <div className="text-[#676767]">{item.icon}</div>
          <SideBarLabel onClick={(e: any) => {}}>{item.name}</SideBarLabel>
          <div className={` ml-auto`}>
            {item.subNav && subnav
              ? item.iconOpen
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </SideBarLink>
        {subnav &&
          item.subNav.map((item: any, index: any) => {
            return (
              <NavLink
                className={({ isActive }) => (isActive ? activeLink : normal)}
                to={item.url}
                key={index.id}
              >
                <p className="ml-14 ">{item.icon}</p>
                <SideBarLabel>{item.name}</SideBarLabel>
              </NavLink>
            );
          })}
      </IconContext.Provider>
    </>
  );
};

export default Submenu;
