import React, {useState} from 'react'
import {BiCaretDown, BiCaretUp} from "react-icons/bi"

const Calender = () => {
    const [caret, setCaret] = useState(false)
  return (
    <div>
        <div onClick={()=> setCaret(!caret)} className="flex items-center justify-center container rounded-md border p-3 gap-4 cursor-pointer  border-[#0eb683] bg-[#fff] h-10 w-28 text-[#91919F] text-sm font-light">Today  { caret ? <BiCaretUp fill="#000"/> : <BiCaretDown fill="#000"/>}</div>
    </div>
  )
}

export default Calender