import React from "react";
import { AiOutlineStop } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import {
  MdOutlineAdd,
  MdOutlineDisabledVisible,
  MdOutlinePersonAddDisabled,
  MdOutlineRefresh,
  MdOutlineRemove,
} from "react-icons/md";
import AppTable from "../AppTable";
import moment from "moment";
import { Column } from "react-table";
import { RiDownloadCloud2Fill } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Iaccounts, data_sets } from "./SettingsAccount";
import { useAdminUser, useUsetTrnx as useUserTrnx } from "../../../sevices/hooks/admin";

export type Transaction = {
  currency: string;
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;
};

export interface IColumn {
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;
  currency: string;
}

export const dColumns: readonly Column<any>[] = [
  {
    Header: "SN",
    accessor: "serialNumber",
    //@ts-ignore
    Cell: ({ value, row }) => {
      const index = row?.index;
      return (index !== undefined ? index + 1 : "").toString();
    },
  },
  {
    Header: "TIMESTAMP (GMT+1)",
    accessor: "createdAt",
    //@ts-ignore
    Cell: (value) => {
      //   const myDate = new Date(value);
      //   const formatDate = moment(myDate);
      //   const Cdate = formatDate.format("lll");
      return value.toString();
    },
  },
  {
    Header: "TRANSACTION REF",
    accessor: "transactionRef",
    Cell: ({ value }: { value: string }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <Tooltip message={value}> */}
        <a
          className="truncate hover:underline cursor-pointer text-[#0eb683]"
          style={{ marginRight: "0.5rem" }}
        >
          {value.slice(0, 20)}
        </a>
        {/* </Tooltip> */}
      </div>
    ),
  },
  {
    Header: "TRANSACTION TYPE",
    accessor: (row) =>
      ` ${
        typeof row?.currency === "string"
          ? row?.currency.toUpperCase()
          : row?.currency
      } ${row?.type.charAt(0).toUpperCase()}${row?.type.slice(1)} `,
    Cell: ({ value }: { value: string }) => (
      <div className="">
        <span className="flex items-center whitespace-nowrap">{value}</span>
      </div>
    ),
  },
  {
    Header: "AMOUNT",
    accessor: (row) => {
      let currencySymbol = "";
      const formatter = new Intl.NumberFormat();
      const amount = formatter.format(Number(row?.amount));
      switch (row?.currency?.toUpperCase()) {
        case "NGN":
          currencySymbol = "₦ ";
          break;
        case "USD":
          currencySymbol = "$ ";
          break;
        default:
          break;
      }

      return (
        <div className="whitespace-nowrap">{`${currencySymbol}${amount}`}</div>
      );
    },
  },

  //   {
  //     Header: "NARRATION",
  //     accessor: "narration",
  //     Cell: ({ value }) => (
  //       <div className="flex items-left">
  //         {/* <Tooltip message={value}> */}
  //         <div className="truncate w-40 cursor-pointer">{value}</div>
  //         {/* </Tooltip> */}
  //       </div>
  //     ),
  //   },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => {
      const colorMap: { [key: string]: { bg: string; color: string } } = {
        processing: { bg: "#e5e5e5", color: "#5C6F7F" },
        pending: { bg: "#fff2eb", color: "#FF7F36" },
        successful: { bg: "#e2fef0", color: "#0EB683" },
        cancelled: { bg: "#e2fef0", color: "#f51505" },
        failed: { bg: "#f51505", color: "#e2fef0" },
      };

      const status =
        value?.toLowerCase().includes("success") ||
        value?.toLowerCase().includes("completed")
          ? "successful"
          : value?.toLowerCase();
      const { bg, color } = colorMap[status] || {};
      const stats = (status: string) => {
        return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
          return p1 + p2.toUpperCase();
        });
      };

      return (
        <span
          className="inline-block px-3 py-1 rounded-full text-sm font-bold"
          style={{ backgroundColor: bg, color }}
        >
          {stats(status)}
        </span>
      );
    },
  },
];

const AccountDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const account = useAdminUser(id as string);
  const trnx = useUserTrnx(id as string);

  console.log(account, "  const account");
  return (
    <div>
      {" "}
      <div className="bg-[#fff] h-100vh  p-5 md:pr-5 md:pl-8">
        <div className=" mt-5">
          <div className="flex items-center gap-4">
            <div
              //   draggable
              onClick={() => navigate("/dashboard/settings?tab=Accounts")}
              className="rounded-full h-8 w-8 text-[#071827] bg-[#E5F2EA] flex items-center justify-center hover:bg-slate-300 transition-colors duration-300 cursor-pointer"
            >
              <FiChevronLeft size={23} />
            </div>
            <h1 className="text-[#071827] text-[14px] leading-[21px] font-normal">
              Accounts
            </h1>
          </div>
          <div className="flex flex-row items-center justify-between gap-5 my-8">
            <p className="text-[#2b2b2b]  text-[32px] leading-[140%] mr-3 font-semibold">
              {/* {account?.fullAccountName} */}
            </p>
            <div className=" flex items-center justify-center gap-3">
              <div className="flex gap-2">
                <button className="flex items-center justify-center p-3 rounded-md bg-[#fff] text-[#e60606]  active:scale-90 cursor-pointer border border-[#e60606] transition-all duration-300 ease-in hover:bg-[#f9f4f4]">
                  {/* <AiOutlineStop size={20} color="red" /> */}
                  <span className="ml-1">stop withdrawals</span>
                </button>
                <button className="flex items-center justify-center p-3 rounded-md bg-[#fff] text-[#e60606]  active:scale-90 cursor-pointer border border-[#e60606] transition-all duration-300 ease-in hover:bg-[#f9f4f4]">
                  {/* <MdOutlineDisabledVisible size={20} /> */}
                  <span className="ml-1">stop deposits</span>
                </button>
                <button className="flex items-center justify-center p-3 rounded-md bg-[#e60606] text-[#fff]  active:scale-90 cursor-pointer border border-[#e60606] transition-all duration-300 ease-in hover:bg-[#e60606ed]">
                  {/* <MdOutlinePersonAddDisabled size={20} /> */}
                  <span className="ml-1">disable account</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-5">
            <h1 className="text-[20] leading-normal text-[#071827] font-medium">
              {" "}
              Account Details
            </h1>
          </div>
          <div className="flex justify-between gap-5">
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                User ID
              </h3>
              <p className="text-[#0EB683] text-[16px] leading-normal font-medium underline">
                {/* {account?.userId} */}
              </p>
            </div>
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Bank
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {/* {account?.bank} */}
              </p>
            </div>
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Account Number
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {/* {account?.accountNumber} */}
              </p>
            </div>
            <div className=" ">
              <h3 className="text-[16px] leading-normal text-[#5C6F7F] font-normal mb-3">
                Account Creation Date
              </h3>
              <p className="text-[#071827] text-[16px] leading-normal font-medium">
                {/* {account?.createdAt} */}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between gap-5 my-8">
            <p className="text-[#071827]  text-[20px] leading-[30px] mr-3 font-medium">
              Recent Activity
            </p>

            <button className="flex items-center justify-center p-3 rounded-md bg-[#fff] text-[#0eb683]  active:scale-90 cursor-pointer border border-[#0eb683] transition-all duration-300 ease-in hover:bg-[#9ceec9]">
              <RiDownloadCloud2Fill size={20} />
              <span className="ml-1">Download Report</span>
            </button>
          </div>

          <div>
            <AppTable
              tableData={[]}
              tabs={[]}
              //   @ts-ignore
              dColumns={dColumns}
              showFilter={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
