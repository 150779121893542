import React, { useState } from "react";
import euroFlag from "../../../assets/flags/euro.png";
import usFlag from "../../../assets/flags/usd.png";
import gbpFlag from "../../../assets/flags/pound.png";
import usdt from "../../../assets/coins/usdt.png";
import usdc from "../../../assets/coins/usdc.png";
import ToggleSwitch from "../ToggleSwitch";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useAdminRate } from "../../../sevices/hooks/admin";
import ReactLoading from "react-loading";
const RateTable = () => {
  const usdRate = useAdminRate("usd");
  const eurRate = useAdminRate("eur");
  const gbpRate = useAdminRate("gbp");
  const usdcRate = useAdminRate("usdc");
  const usdtRate = useAdminRate("usdt");


  return (
    <>
      <div className="App hidden transition-transform duration-500 w-full h-full md:flex  ">
        <table className="  bg-[#FEFEFF] w-full overflow-x-auto ">
          <thead className="">
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between  pr-10 ">
              <th className=" rounded-t-3xl flex items-center  p-4 ml-3 ">
                <h1 className="text-[#2B2B2B] font-semibold ">Currency</h1>
                <RiArrowDropDownLine />
              </th>
              <th className="flex items-center">
                <span className="pl-12 text-[#2B2B2B] font-semibold ">
                  Buying
                </span>
                <RiArrowDropDownLine />
              </th>
              <th className="flex items-center ">
                <span className="pl-20 text-[#2B2B2B] font-semibold ">
                  Selling
                </span>
                <RiArrowDropDownLine />
              </th>
              <th className="flex items-center">
                <span className="text-[#2B2B2B] font-semibold  ">
                  Change (24h)
                </span>
                <RiArrowDropDownLine />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between pr-24">
              <td className="text-center py-4">
                <div className="flex items-center gap-4 ml-5 p-2 ">
                  <img src={usFlag} alt="" className="w-10" />
                  <p className="text-[#5C6F7F] text-lg font-bold">USD</p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="text-[#5C6F7F]">
                    {usdRate?.data?.data?.rawRates?.sellRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdRate?.data?.data?.rawRates?.sellRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="ml-10 text-[#5C6F7F]">
                    {usdRate?.data?.data?.rawRates?.buyRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdRate?.data?.data?.rawRates?.buyRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <small className="text-[#00D215] text-xs  ">+52.9%</small>
                </div>
              </td>
            </tr>
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between pr-24  ">
              <td className="text-center">
                <div className="flex items-center gap-4 ml-5 p-2 py-4">
                  <img src={gbpFlag} alt="" className="w-10" />
                  <p className="text-[#5C6F7F] text-lg font-bold">GBP</p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="text-[#5C6F7F]">
                    {gbpRate?.data?.data?.rawRates?.sellRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      gbpRate?.data?.data?.rawRates?.sellRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="ml-10 text-[#5C6F7F]">
                    {gbpRate?.data?.data?.rawRates?.buyRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      gbpRate?.data?.data?.rawRates?.buyRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center ">
                {" "}
                <div className="flex">
                  <small className="text-[#00D215] text-xs  ">+52.9%</small>
                </div>
              </td>
            </tr>
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between pr-24  ">
              <td className="text-center">
                <div className="flex items-center gap-4 ml-5 p-2 py-4">
                  <img src={euroFlag} alt="" className="w-10" />
                  <p className="text-[#5C6F7F] text-lg font-bold">EUR</p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="text-[#5C6F7F]">
                    {eurRate?.data?.data?.rawRates?.sellRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      eurRate?.data?.data?.rawRates?.sellRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="ml-10 text-[#5C6F7F]">
                    {eurRate?.data?.data?.rawRates?.buyRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      eurRate?.data?.data?.rawRates?.buyRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center ">
                {" "}
                <div className="flex">
                  <small className="text-[#00D215] text-xs  ">+52.9%</small>
                </div>
              </td>
            </tr>
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between pr-24  ">
              <td className="text-center">
                <div className="flex items-center gap-4 ml-5 p-2 py-4">
                  <img src={usdt} alt="" className="w-10" />
                  <p className="text-[#5C6F7F] text-lg font-bold">USDT</p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="text-[#5C6F7F] ">
                    {usdtRate?.data?.data?.rawRates?.sellRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdtRate?.data?.data?.rawRates?.sellRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center ">
                <div className="flex">
                  <p className=" ml-10 text-[#5C6F7F]">
                    {usdtRate?.data?.data?.rawRates?.buyRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdtRate?.data?.data?.rawRates?.buyRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center ">
                {" "}
                <div className="flex">
                  <small className="text-[#00D215] text-xs  ">+52.9%</small>
                </div>
              </td>
            </tr>
            <tr className="border-b border-slate-50 flex flex-row items-center justify-between pr-24  ">
              <td className="text-center py-4 ">
                <div className="flex items-center gap-4 ml-5 p-2 py-4">
                  <img src={usdc} alt="" className="w-10" />
                  <p className="text-[#5C6F7F] text-lg font-bold">USDC</p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex ">
                  <p className="text-[#5C6F7F]  ">
                    {usdcRate?.data?.data?.rawRates?.sellRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdcRate?.data?.data?.rawRates?.sellRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center">
                <div className="flex">
                  <p className="ml-10 text-[#5C6F7F] ">
                    {usdcRate?.data?.data?.rawRates?.buyRate === undefined ? (
                      <ReactLoading
                        type="bubbles"
                        color="#0eb683"
                        height="20px"
                        width="20px"
                      />
                    ) : (
                      usdcRate?.data?.data?.rawRates?.buyRate
                    )}
                  </p>
                </div>
              </td>
              <td className="text-center ">
                {" "}
                <div className="flex">
                  <small className="text-[#00D215] text-xs  ">+52.9%</small>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RateTable;
