import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineStop } from "react-icons/ai";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { useUserStore } from "../../store/useUser";
import { RiDeleteBinLine, RiMarkPenLine } from "react-icons/ri";
import { useTabs } from "./widgets/TabWidget";
import UsersModal from "./users/UsersModal";
import SuspendUserModal from "./users/SuspendUserModal";

function chunkArray<T>(array: T[], size: number) {
  let result = [];
  for (let i = 0; i < array?.length; i += size) {
    let chunk = array?.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}
const Table = ({
  tabs,
  itemsPerPage,
  currentPage,
  data,
}: {
  tabs: string[];
  itemsPerPage: number;
  currentPage: number;
  data: any[];
}) => {
  const [suspendUser, setSuspendUser] = useState(false);
  const [activateUser, setActivateUser] = useState("")
  const [selectedTab, setSelectedTab] = useTabs(tabs);
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  const [tableHeaders] = useState([
    "SN",
    "User ID",
    "Date/Time Stamp",
    "Full Name",
    "Phone Number",
    "Email Address",
    "Referred by",
    "Commission Account Bank",
    "Commission Account Name",
    "Commission Account Number",
    "Actions",
  ]);
  const [tableData, setTableData] = useState(data);
  useEffect(() => setTableData(data), [data]);
  const setUserDetailsModal = useUserStore((state) => state.setShowUserDetails);
  const showUserDetailsModal = useUserStore((state) => state.showUserDetails);
  const setUserId = useUserStore((state)=> state.setSelectedUsersId)
  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const suspend = () => {
    setSuspendUser((prev)=> !prev);
  };
  const activate = ()=>{
    setActivateUser( activateUser === "" ? "activate ": "")
  }

  console.log(tableData);

  return (
    <>
      <div className="bg-white shadow-md rounded my-1 hidden md:block overflow-x-auto">
        <table className="min-w-max w-full table-auto ">
          <thead className=" ">
            <tr className="border-y border-slate-50  uppercase text-sm leading-normal w-full">
              {tableHeaders.map((thData, index) => (
                <th className=" py-3 px-4 text-left text-[#5C6F7F]">
                  {truncateString(thData, 10)}
                </th>
              ))}
            </tr>
          </thead>
          {data.length ? (
            chunkArray(tableData, itemsPerPage)[currentPageIndex - 1]?.map(
              (data, index) => {
                const {
                  id,
                  date,
                  email,
                  firstName,
                  lastName,
                  phoneNumber,
                  status,
                  actions,
                } = data;

                const sn =
                  currentPageIndex === 1
                    ? index + currentPageIndex
                    : index + 11;

                return (
                  <>
                    <tbody className="text-gray-600 text-sm font-light">
                      <tr className="border-b border-slate-50 hover:bg-gray-100">
                        <td className="py-3 px-4 text-left whitespace-nowrap ">
                          <div className="flex items-left">
                            <div className="mr-2 font-bold">{sn} </div>
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            setUserDetailsModal(true);
                            setUserId(id)
                          }}
                          className="py-3 px-4 text-left text-[#0eb683] underline transition-all ease-in-out active:scale-90 cursor-pointer"
                        >
                          {truncateString(id, 10)}
                        </td>
                        <td className="py-3 pl-4 text-left text-[#071827]">
                          <div className="">
                            <div className="">
                              {moment(date).utc().format("D/M/YYYY  h:mm a")}
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-4 text-left">
                          <div
                            // onClick={() => {
                            //   setBeneficiaryRef(
                            //     reference
                            //   );
                            //   setShowBeneficiaryReferenceModal(true);
                            // }}
                            className=" whitespace-nowrap text-sm "
                          >
                            {firstName} {lastName}
                          </div>
                        </td>
                        <td className="py-3  text-left text-[#071827]">
                          <div className="pl-4">{phoneNumber}</div>
                        </td>
                        <td className="py-3  text-left ">
                          <div className="text-[#071827]">
                            {truncateString(email, 15)}
                          </div>
                        </td>
                        <td className="py-3 px-4 text-left">
                          <span className="text-[#071827]">hello</span>
                        </td>
                        <td className="py-3 pr-4 text-center">uu</td>
                        <td className="py-3 pr-4 text-center">uu</td>
                        <td className="py-3 pr-4 text-center">uu</td>
                        <td className="py-3  text-center pl-6  flex items-center gap-2">
                          <span
                          onClick={activate}
                           className="hover:bg-slate-400 hover:rounded-full hover:p-1 cursor-pointer transition-all active:scale-90">
                            <RiMarkPenLine />
                          </span>
                          <span
                            onClick={suspend}
                            className="hover:bg-slate-400 hover:rounded-full hover:p-1 cursor-pointer transition-all active:scale-90"
                          >
                            {" "}
                            <AiOutlineStop />
                          </span>
                          <span className="hover:bg-slate-400 hover:rounded-full hover:p-1 cursor-pointer transition-all active:scale-90">
                            <RiDeleteBinLine />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              }
            )
          ) : (
            <div className="p-5 flex items-center justify-center w-full mx-auto h-full">
              <p>Please Wait ...</p>
            </div>
          )}
        </table>
        <footer className="hidden md:flex justify-end p-3">
          <div className="bottom-nav flex gap-2">
            <button
              className={
                (currentPageIndex === 1 ? "no-items" : "") +
                " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
              }
              onClick={() =>
                currentPageIndex !== 1
                  ? setCurrentPageIndex(
                      currentPageIndex > 2 ? currentPageIndex - 2 : 1
                    )
                  : null
              }
            >
              <FiChevronsLeft />
            </button>
            <button
              className={
                (currentPageIndex === 1 ? "no-items" : "") +
                " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
              }
              onClick={() =>
                currentPageIndex !== 1
                  ? setCurrentPageIndex(currentPageIndex - 1)
                  : null
              }
            >
              <FiChevronLeft />
            </button>
            <div className="pagination flex gap-2 items-center">
              {chunkArray(tableData, itemsPerPage).map((_, index) => {
                return (
                  <button
                    key={index}
                    className={
                      currentPageIndex === index + 1
                        ? "active-page-index bg-[#0eb683] text-white p-1 px-3 flex-1 rounded-md"
                        : "flex-1 p-1 px-3 hover:bg-slate-100 rounded-md"
                    }
                    onClick={() => setCurrentPageIndex(index + 1)}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
            <button
              className={
                (currentPageIndex === chunkArray(tableData, itemsPerPage).length
                  ? "no-items"
                  : "") +
                " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
              }
              onClick={() =>
                currentPageIndex !== chunkArray(tableData, itemsPerPage).length
                  ? setCurrentPageIndex(currentPageIndex + 1)
                  : null
              }
            >
              <FiChevronRight />
            </button>
            <button
              className={
                (currentPageIndex === chunkArray(tableData, itemsPerPage).length
                  ? "no-items"
                  : "") +
                " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
              }
              onClick={() =>
                currentPageIndex !== chunkArray(tableData, itemsPerPage).length
                  ? setCurrentPageIndex(
                      currentPageIndex + 2 >=
                        chunkArray(tableData, itemsPerPage).length
                        ? chunkArray(tableData, itemsPerPage).length - 1
                        : currentPageIndex + 2
                    )
                  : null
              }
            >
              <FiChevronsRight />
            </button>
          </div>
        </footer>
        {showUserDetailsModal && (
          <UsersModal
            isOpen={showUserDetailsModal}
            onClose={setUserDetailsModal}
          />
        )}
      </div>
      {suspendUser && <SuspendUserModal isOpen={suspendUser} onClose={ suspend} />}
      {activateUser && <SuspendUserModal isOpen={activateUser} onClose={ activate} />}
    </>
  );
};
export default Table;


