import React, { useEffect, useState } from "react";
import RoleStepper from "./RoleStepper";
import AccessPermission from "./AccessPermission";
import UserDetails from "./UserDetails";
import { FiChevronLeft } from "react-icons/fi";
import StepperController from "./StepperController";
import SuccessModal from "../../widgets/SuccessModal";
import { useNavigate } from "react-router-dom";

export const roleStepper = ["User Details", "Assign Access Permissions"];

const RoleLayout = () => {
  // const { state: userData, setState: setUserData } = useAppState();
  const [finalData, setFinalData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const checkoutSteps = roleStepper;
  const [suspendUser, setSuspendUser] = useState(false);
const navigate = useNavigate()
  const displayStep = (sellersStep: any) => {
    switch (sellersStep) {
      case 1:
        return <UserDetails />;
      case 2:
        return <AccessPermission />;

      default:
    }
  };

  const handleClick = (direction: string) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= checkoutSteps?.length && setCurrentStep(newStep);
  };

  // useEffect(() => {
  //   const stepProgress = Math.round((currentStep / numSteps) * 100);
  //   setProgress(stepProgress);
  // }, [currentStep, numSteps]);

  // function isFormFilled() {
  //   return console.log(
  //     Object.values(userData).every((value) => value !== ""),
  //     "filled?"
  //   );
  // }

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   console.log(name, value);

  //   // Split the name into nested properties
  //   const [section, field] = name.split(".");

  //   // Update the userData state
  //   setUserData((prevUserData: ISellerInfo) => ({
  //     ...prevUserData,
  //     [section]: {
  //       ...prevUserData[section],
  //       [field]: value,
  //     },
  //   }));
  //   isFormFilled();
  // };

  const handleGetFiles = (files: File[], fieldName: string) => {
    if (files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append(fieldName, file);
      //@ts-ignore
      setUserData((prevUserData: ISellerInfo) => ({
        ...prevUserData,
        businessInformation: {
          ...prevUserData.businessInformation,
          [fieldName]: formData,
        },
      }));

      console.log("File name:", file.name);
      console.log("File data:", file);
    }
  };

  // const updateUserData = (property: string, value: string) => {
  //   setUserData((prevUserData: ISellerInfo) => ({
  //     ...prevUserData,
  //     businessInformation: {
  //       ...prevUserData.businessInformation,
  //       [property]: value || "",
  //     },
  //   }));
  // };

  const suspend = () => {
    setSuspendUser((prev) => !prev);
  };
  return (
    <>
      <div className="bg-[#fff] h-100vh  p-5 px-8">
        <div className=" mt-5">
          <div className="flex items-center gap-4">
            <div
              //   draggable
              onClick={() =>
                navigate("/dashboard/settings?tab=Role+Management")
              }
              className="rounded-full h-8 w-8 text-[#071827] bg-[#E5F2EA] flex items-center justify-center hover:bg-slate-300 transition-colors duration-300 cursor-pointer"
            >
              <FiChevronLeft size={23} />
            </div>
            <h1 className="text-[#071827] text-[14px] leading-[21px] font-normal">
              Role Management
            </h1>
          </div>
          <div>
            <RoleStepper
              checkoutSteps={checkoutSteps}
              currentStep={currentStep}
            />
          </div>

          <div>
            {/* <SellersStepsContext.Provider
            //@ts-ignore
            value={{
              userData,
              setUserData,
              finalData,
              setFinalData,
              checkoutSteps,
              currentStep,
              handleClick,
              handleChange,
            }}
          > */}
            {displayStep(currentStep)}
            {/* </SellersStepsContext.Provider> */}
          </div>

          <div className="">
            <StepperController
              checkoutSteps={checkoutSteps}
              currentStep={currentStep}
              handleClick={handleClick}
            />
          </div>
        </div>
        {suspendUser && <SuccessModal isOpen={suspendUser} onClose={suspend} />}
      </div>
    </>
  );
};

export default RoleLayout;
