import React, { useRef } from "react";
import { AiOutlineFieldTime, AiOutlineMail } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { GoCalendar, GoVerified, GoUnverified } from "react-icons/go";
import { useUserStore } from "../../../store/useUser";
import { useAdminUsersId } from "../../../sevices/hooks/admin";


export interface UserModalProps {
  isOpen: boolean;
  onClose: (shouldShow: boolean) => void;
}

const UsersModal: React.FC<UserModalProps> = ({ isOpen, onClose }) => {

 const usersId = useUserStore((state)=> state.selectedUsersId)
 console.log(usersId);
 
  const getUsersId = useAdminUsersId("bea90789-0414-464d-83e1-96cfaca0c3ed")
  const overLayRef = React.useRef(null);
    const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.target === overLayRef.current && onClose(false);
    };

  return (
    <>
      {isOpen ? (
        <div className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50">
          <div
            ref={overLayRef}
            className="modal_overlay absolute flex justify-end top-0 right-0 w-full h-full cursor-pointer bg-[#13111173]"
            onClick={handleOverLayClick}
          >
            <div className="modal_box relative bg-[#fff] h-full w-[440px]  rounded-l-lg overflow-auto">
              <div
                onClick={() => onClose(false)}
                className="close absolute top-5 right-5 flex items-center justify-center rounded-full
                bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
              >
                <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                  <IoMdClose size={29} className="" />
                </span>
              </div>

              <div className="flex flex-col items-center  justify-center">
                <div className="cursor-pointer active:scale-90 ease-in-out transition-all duration-100">
                  <div className="rounded-full w-28 h-28 border-2 border-[#676767]]  mt-16 flex items-center justify-center">
                    <span className=" text-center rounded-full w-24 h-24 items-center flex justify-center text-white   bg-slate-600">
                      <FaUserAlt size={70} />
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <h1 className="text-center font-bold text-4xl text-[#2B2B2B]">
                    Abu Ayoola
                  </h1>
                  <h3 className="text-center mt-2 font-normal text-lg text-[#676767]">
                    User ID- 4478483
                  </h3>
                </div>
              </div>
              <div className="ml-7    ">

                <div className="flex flex-col gap-1 mt-8  ">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    Phone Number
                  </h1>
                  <h1 className="text-2xl font-normal text-[#2B2B2B ]">
                    +2348030939741
                  </h1>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    {" "}
                    Email Address
                  </h1>
                  <h1 className="text-2xl font-normal text-[#2B2B2B]">
                    Abu.ayoola@gmail.com
                  </h1>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    {" "}
                    Last Login
                  </h1>
                  <h1 className="text-2xl font-normal text-[#2B2B2B]">
                    35 secs ago
                  </h1>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    {" "}
                    Date Joined
                  </h1>
                  <h1 className="text-2xl font-normal text-[#2B2B2B]">
                    12 September 2021
                  </h1>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    Referred By
                  </h1>
                  <h1 className="text-[#0eb683] text-2xl font-normal underline  ">
                    Uche Abubakar
                  </h1>
                </div>
                <div className="flex flex-col gap-1 mt-4 pb-7">
                  <h1 className="text-2xl font-normal text-[#676767]">
                    Status
                  </h1>
                  <div className="flex items-center gap-2" >
                  <h1 className="text-[#2B2B2B  ] text-2xl font-normal   ">
                    Verified
                  </h1>
                  <span className ="text-[#0eb683] text-xl">{"verified"? <GoVerified/> :
                    <GoUnverified/>}</span>
                    
                  

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UsersModal;
