import React from 'react'
import LandingPageHeader from '../../components/base-components/LandingPageHeader'


const LandingPage = () => {
  return (
    <div>
        <LandingPageHeader />
        {/* <LandingPageSection />
        <LandingPageMid/>
         <LandingPageFooter/> */}
    </div>
  )
}

export default LandingPage