import { IPatch } from "../../../components/dashboard-components/fees-component/FeesAccordion";
import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import {
  api,
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
} from "../../api";

interface enumType {
  type: null | undefined;
}

export const useAdminUsers = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminUsers),
    queryKey: ["users"],
  });
};

export const useGetFees = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminFees),
    queryKey: ["fees"],
  });
};

export const usePatchFees = (Id: string) => {
  return useQueryMutation({
    mutationFn: (data: IPatch) =>
      makePatchRequest(data, api.admin.patchFees(Id)),
  });
};
export const useUpdateBRStatus = (Id?: string) => {
  return useQueryMutation({
    mutationFn: (data: any) => makePatchRequest(data, api.admin.adminUser(Id)),
  });
};

export const useFeeId = (Id: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.feeById(Id)),
    queryKey: ["feeId"],
  });
};
export const useAdminUser = (Id: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminUser(Id)),
    queryKey: ["adminUser"],
  });
};

export const useUsetTrnx = (id:string) =>{
  return useQueryAction({
    queryFn:()=> makeGetRequest(api.admin.userTransactions(id)),
    queryKey: ["jgjjgjg"]
  })
}


export const useDeleteFees = (Id: string) => {
  return useQueryMutation({
    mutationFn: () => makeDeleteRequest(api.admin.deleteFees(Id)),
  });
};

export const useFees = () => {
  return useQueryMutation({
    mutationFn: (data: {
      key: any;
      name: any;
      value: {
        amount: any;
        baseValue?: any;
        currency?: any;
      };
      type: any;
      description: any;
    }) => makePostRequest(data, api.admin.adminFees),
  });
};

export const useFunctionality = () => {
  return useQueryMutation({
    mutationFn: (data: {
      transactionType: string;
      currency: string;
      description: string;
      status: boolean;
    }) => makePostRequest(data, api.admin.functionality),
  });
};

export const useDeleteFunctionality = (id: string) => {
  return useQueryMutation({
    mutationFn: () => makeDeleteRequest(api.admin.modifyFunctionality(id)),
  });
};

export const useUpdateFunctionality = (id: string) => {
  return useQueryMutation({
    mutationFn: (data: any) =>
      makePatchRequest(data, api.admin.modifyFunctionality(id)),
  });
};
export const useUpdateFunctionalityGeneral = () => {
  return useQueryMutation({
    mutationFn: (data: any) =>
      makePatchRequest(data, api.admin.allFunctionality),
  });
};

export const useCreateTeamMember = () => {
  return useQueryMutation({
    mutationFn: (data: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    }) => makePostRequest(data, api.admin.teamMember),
  });
};

export const useGetTeamMembers = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.teamMember),
    queryKey: ["xty"],
  });
};
export const useGetFunctionality = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.functionality),
    queryKey: ["xitn"],
  });
};

export const useAdminRate = (currency: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminRates(currency)),
    queryKey: ["adminRate", currency],
  });
};

export const useAdminTx = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminTx(userId)),
    queryKey: ["adminUser"],
  });
};

export const useAdminWalletNgn = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminNgn(userId)),
    queryKey: ["ngn"],
  });
};
export const useAdminWalletUsd = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminUsd(userId)),
    queryKey: ["usd"],
  });
};
export const useAdminWalletEur = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminEur(userId)),
    queryKey: ["eur"],
  });
};
export const useAdminWalletGbp = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminGbp(userId)),
    queryKey: ["gbp"],
  });
};
export const useAdminWalletUsdt = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminUsdt(userId)),
    queryKey: ["Usdt"],
  });
};
export const useAdminWalletUsdc = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.adminUsdc(userId)),
    queryKey: ["Usdc"],
  });
};

export const useBusinessKycStatus = (businessId?: string) => {
  return useQueryMutation({
    mutationFn: (data) =>
      makePatchRequest(data, api.admin.businessKycStatus(businessId)),
  });
};
export const useBusinessContactKycStatus = (businessIndividualId?: string) => {
  return useQueryMutation({
    mutationFn: (data) =>
      makePatchRequest(
        data,
        api.admin.businessContactKycStatus(businessIndividualId)
      ),
  });
};
export const useBusinessCorporateKycStatus = (businessCorporateId?: string) => {
  return useQueryMutation({
    mutationFn: (data) =>
      makePatchRequest(
        data,
        api.admin.businessCorporateKycStatus(businessCorporateId)
      ),
  });
};

export const useAdminUsersId = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.admin.getUser(userId)),
    queryKey: ["userId"],
  });
};
