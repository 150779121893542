import React, { useState } from "react";
import Calender from "../../components/dashboard-components/Calender";
import SearchComponent from "../../components/dashboard-components/SearchComponent";
import StablecoinWidget from "../../components/dashboard-components/StablecoinWidget";
import Widgets from "../../components/dashboard-components/Widgets";

const Balances = () => {
  const [currentTab, setCurrentTab] = useState("fiat");
  return (
    <div className="h-screen w-full bg-[#fff] p-5 md:pr-5 md:pl-8 ">
      <div className="flex flex-row items-center justify-between  mt-5">
        <h1 className="font-bold text-2xl text-[#071827] md:pb-2">Balances</h1>
        <div className="flex items-start  gap-3">
          <div className="toggle w-60 h-12 md:h-10 flex border border-[#0eb683] mb-6 md:mb-4 self-center justify-between rounded-lg cursor-pointer p-1">
            <div
              className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md ${
                currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCurrentTab("fiat");
              }}
            >
              Fiat
            </div>
            <div
              className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md ${
                currentTab === "Stablecoin" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setCurrentTab("Stablecoin");
              }}
            >
              Stablecoin
            </div>
          </div>
          <Calender />
        </div>
      </div>

      {currentTab === "fiat" ? (
        <div>
          <div className="flex gap-3">
            <Widgets
              color="#fff"
              currency="Revenue"
              symbol="₦"
              border={"1px solid #0EB683"}
              redirect={"/dashboard/accounts/usd"}
            />
            <Widgets
              color="#C4FFF4"
              currency="USD Collections"
              symbol="$"
              border={"1px solid #C4FFF4"}
              redirect={"/dashboard/accounts/usd"}
            />
            <Widgets
              color="#FFDECF"
              currency="GBP Collections"
              symbol="£"
              border={"1px solid #FFDECF"}
              redirect={"/dashboard/accounts/usd"}
            />
            <Widgets
              color="#E8EAFF"
              currency="EUR Collections"
              symbol="€"
              border={"1px solid #E8EAFF"}
              redirect={"/dashboard/accounts/usd"}
            />
          </div>
          <div>
            <SearchComponent placeholder={"Search Revenue"} />
          </div>

          <div>
            {/* <DataTable row={7} rows={undefined} columns={undefined} /> */}
          </div>
        </div>
      ) : (
        <div>
          <div className=" flex  gap-3">
            <StablecoinWidget coin={"USDC"} />
            <StablecoinWidget coin={"USDT"} />
          </div>

          <SearchComponent placeholder={"Search Revenue"} />

          <div>
            {/* <DataTable row={11} rows={undefined} columns={undefined} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Balances;
