import React from 'react'
import { MdOutlineRefresh } from 'react-icons/md'
import SearchComponent from '../../../components/dashboard-components/SearchComponent'

const USD = ({currency = "usd"}:{currency:string}) => {
    console.log(currency)
  return (
    <div className="bg-[#fff] h-100vh  p-5 md:pr-5 md:pl-8">
      <div className="flex flex-row items-center justify-between  mt-5">
        <h1 className="font-bold text-2xl text-[#071827] md:pb-2">
          {currency.toUpperCase()} Wallet
        </h1>
        <div className="flex flex-col gap-5">
          <p className="text-[#676767] h-5  text-right text-base mr-3 font-semibold">
            BALANCE
          </p>
          <div className="flex items-center justify-center gap-3">
            <h1 className="text-[#0eb683] font-bold text-2xl ">
              {currency.toUpperCase()} 1,234,567,890.00
            </h1>
            <figure className="flex items-center justify-center p- rounded-full bg-[#0eb683] text-white h-9 w-9 transition-all active:scale-90 cursor-pointer">
              <MdOutlineRefresh size={29} />
            </figure>
          </div>
        </div>
      </div>
      <div>
          <SearchComponent placeholder={"Search users"}/>
      </div>

    </div>
  )
}

export default USD