import React from 'react'
import Calender from '../../../components/dashboard-components/Calender'
import SearchComponent from '../../../components/dashboard-components/SearchComponent'

const EURTx = ({currency = "eur"}:{currency: string}) => {
  return (
    <div className="bg-[#fff] h-100vh  p-5 md:pr-5 md:pl-8 mr-4">
    <div className="flex flex-row items-center justify-between  mt-5">
      <h1 className="font-bold text-3xl text-[#071827] md:pb-2">
        {currency.toUpperCase()} Transactions
      </h1>
      <div>
        <Calender/>
      </div>
    </div>
    <div>
        <SearchComponent placeholder={"Search users"}/>
    </div>

  </div>
  )
}

export default EURTx