import React from "react";
import SearchComponent from "../../../components/dashboard-components/SearchComponent";

const BankApproval = () => {
  return (
    <div className="bg-[#fff] h-100vh  p-5 md:pr-5 md:pl-8">
      <div className="flex flex-row items-center justify-between  mt-5">
        <h1 className="font-bold text-2xl text-[#071827] md:pb-2">
          Bank Approvals
        </h1>
      </div>
      <div>
        <SearchComponent placeholder={"Search users"} />
      </div>
    </div>
  );
};

export default BankApproval;
