import React, { useEffect, useMemo, useState } from "react";
import SearchComponent from "../../../components/dashboard-components/SearchComponent";
import AppTable from "../../../components/dashboard-components/AppTable";
import { Column } from "react-table";
import moment from "moment";
import { useAdminUsers } from "../../../sevices/hooks/admin";
import { IUserData } from "../../../types/Users";
import ApprovalTable from "../../../components/dashboard-components/ApprovalTable";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const navigate = useNavigate();
  const { data: allUsersData } = useAdminUsers();

  const businessAccounts = useMemo(() => {
    if (!allUsersData) return [];

    return (
      allUsersData?.data?.data.filter(
        (user: any) => typeof user.businessId === "string"
      ) || []
    );
  }, [allUsersData]);

  const handleDetails = (id: string | null) => {
    navigate(`/dashboard/compliance/onboarding/${id}`);
  };
  console.log(businessAccounts, "iop");
  const dColumns: readonly Column<IUserData>[] = [
    {
      Header: "SN",
      accessor: "id",
      //@ts-ignore
      Cell: ({ value, row }) => {
        console.log(row, "row");
        const index = row?.index;
        return (
          <p className="text-[14px] leading-[21px] font-normal text-[#071827]">
            {(index !== undefined ? index + 1 : "").toString()}
          </p>
        );
      },
    },
    {
      Header: "DATE/TIMESTAMP (GMT+1)",
      accessor: "createdAt",
      //@ts-ignore
      Cell: (value: any) => {
        console.log(value, "value");

        const formatDate = moment(value);
        const Cdate = formatDate.format("lll");
        return (
          <p className="text-[14px] leading-[21px] font-normal text-[#071827]">
            {Cdate}
          </p>
        );
      },
    },
    {
      Header: "BUSINESS NAME",
      accessor: "businessName",
      Cell: ({ value }: { value: string | null }) => {
        console.log(value, "rowvalue");
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Tooltip message={value}> */}
            <a
              className="text-[14px] leading-[21px] font-normal text-[#071827]"
              style={{ marginRight: "0.5rem" }}
            >
              {value?.slice(0, 20)}
            </a>
            {/* </Tooltip> */}
          </div>
        );
      },
    },
    {
      Header: "CONTACT PERSON",
      accessor: (row) => {
        const { firstName, lastName } = row;
        return `${firstName} ${lastName}`;
      },
      Cell: (row: {
        value:
          | string
          | number
          | boolean
          | React.ReactFragment
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactPortal
          | null
          | undefined;
      }) => (
        <div className="">
          <span className="text-[14px] leading-[21px] font-normal text-[#071827]">
            {row.value}{" "}
            {/* Use row.value to access the full name from the accessor */}
          </span>
        </div>
      ),
    },

    {
      Header: "EMAIL",
      accessor: `email`,
      Cell: ({ value }) => (
        <div className="">
          <span className="text-[14px] leading-[21px] font-normal text-[#071827]">
            {value}
          </span>
        </div>
      ),
    },
    {
      Header: "PHONE",
      accessor: `phoneNumber`,
      Cell: ({ value }) => (
        <div className="">
          <span className="text-[14px] leading-[21px] font-normal text-[#071827]">
            {value}
          </span>
        </div>
      ),
    },
    {
      Header: "ACTIONS",
      accessor: (row) => {
        console.log(row, "jjjjuuju");
        return (
          <div
            onClick={() => {
              // handleDetails(row.id);
              handleDetails(row.businessId);
            }}
            className="whitespace-nowrap truncate hover:underline cursor-pointer text-[#0eb683]"
          >{`view more`}</div>
        );
      },
    },
  ];

  if (!businessAccounts.length) {
    return (
      <div className="h-screen w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
        <div className="flex flex-row items-center justify-between  my-8">
          <h1
            style={{ fontFamily: "AirbnbCereal_W_Bd" }}
            className="font-bold text-[30px] text-[#071827] md:pb-2"
          >
            Customer Onboarding
          </h1>
        </div>
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="h-screen w-full bg-[#FEFEFF] p-5 md:pr-5 md:pl-8 ">
      <div className="flex flex-row items-center justify-between  my-8">
        <h1
          style={{ fontFamily: "AirbnbCereal_W_Bd" }}
          className="font-bold text-[30px] text-[#071827] md:pb-2"
        >
          Customer Onboarding
        </h1>
      </div>
      <div className="">
        <ApprovalTable
          tableData={businessAccounts}
          tabs={[]}
          //@ts-ignore
          dColumns={dColumns}
          placeholder="Search"
          showFilter={true}
        />
      </div>
    </div>
  );
};

export default Onboarding;

interface DataSet {
  id: string;
  date: Date;
  business_name: string;
  contact_person: string;
  email: string;
  phone_number: string;
  status: "pending" | "approved" | "rejected";
}

// Generate 15 data sets
const dataSetCount = 15;
const dataSets: DataSet[] = [];

//use this function to create a simple application or software that generates dummy data set quickly and peculiar to people
//utilize chartGPT in the journey to filter category of dataset and call several endpoints

for (let i = 1; i <= dataSetCount; i++) {
  const dataSet: DataSet = {
    id: `dataSet-${i}`,
    date: new Date(),
    business_name: `Business ${i}`,
    contact_person: `Contact Person ${i}`,
    email: `contact${i}@example.com`,
    phone_number: `123456789${i}`,
    status: getRandomStatus(),
  };

  dataSets.push(dataSet);
}

function getRandomStatus(): DataSet["status"] {
  const statuses: DataSet["status"][] = ["pending", "approved", "rejected"];
  const randomIndex = Math.floor(Math.random() * statuses.length);
  return statuses[randomIndex];
}
